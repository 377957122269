import { KeyboardBackspace } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

export const Error404 = () => {
    return (
        <>
            <section className='pb-5'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 text-center">
                            <img src="./images/error404.svg" alt="" />
                            <h3 className='fw-bold text-theme mt-3 mb-0'>Something Went Wrong !</h3>
                            <p className='my-4'>The page you requested does not exist.</p>
                            <div className="buttons-group text-center">
                                <Link to='/' className='bg-primary text-white py-3 px-4 border-0 rounded mybtn'><KeyboardBackspace /> Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
