
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";


const LocalauthUser = JSON.parse(localStorage.getItem('authUser'))

export const addToCartApi = createAsyncThunk(
    'addToCartApi',
    async (product, {getState }) => {
        try {
            const authData = getState().authData.user;
            const authData2 = getState().authData;
            const pyaloadData = {
                Token : authData && authData.token ? authData.token : (LocalauthUser && LocalauthUser.token ? LocalauthUser.token : ''),
                LoginUserCode: authData && authData.loginUserCode ? authData.loginUserCode : (LocalauthUser && LocalauthUser.loginUserCode ? LocalauthUser.loginUserCode : ''),
                Source: product.Source ? product.Source : '',
                ProductCode: product.productCode,
                Qty: product.itemQuantity ? product.itemQuantity.toString() : '1',
                requiredOtherProducts: product.requiredOtherProducts ? product.requiredOtherProducts : [],
                CartToken: authData2.uniqueUserId ? authData2.uniqueUserId : localStorage.getItem('uniqueUserId')
            }
            const response = await ApiCall(`/api/Web/AddToCart`, pyaloadData)
            return response; 
        } catch (error) {
            throw new Error('Item has not been added in cart');
        }
    }
);

export const addToCartAfterloginSlice = createSlice({
    name: 'addToCartAfterloginSlice',
    initialState: {
        message: {},
        loading: false,  
        error: null
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(addToCartApi.pending, (state) => {
                state.loading = true;
                state.error = null; 
            })
            .addCase(addToCartApi.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload;
            })
            .addCase(addToCartApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});


export default addToCartAfterloginSlice.reducer;
