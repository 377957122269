import React from 'react'
import { Link } from 'react-router-dom'

export const ReturnandRefundPolicy = () => {
  return (
    <>
            <section className='pt-3 pb-3 pb-md-5' style={{ backgroundColor: '#f8f8f8' }}>
                <div className="container">
                    <nav className="breadcrumb align-items-center">
                        <Link className="breadcrumb-item" to='/'>Home</Link>
                        <span>/</span>
                        <Link className="breadcrumb-item active" to='/return-policy'>Return and Refund Policy</Link>
                    </nav>
                    <h3 className='text-center mb-4'>Return and Refund Policy</h3>

                    <div className="content_return">
                    <p>Returns, Refund and Replacement is the scheme provided by various sellers listed on ‘<b>Indocraftworld’s</b>’ website available at www.Indocraftworld.com or the mobile application under the brand name “ <b>Indocraftworld</b> ” (collectively “Platform”), in relation to specific products.  Returns, refund and replacement policy gives you an option to return, replace or exchange items purchased on the Platform, for any reason within the specified return/exchange period, as detailed on the product details page. However, the return/exchange shall be eligible for products that are in good condition, as may be determined by  <b>Indocraftworld</b> . </p>

                    <h6 className="fw-bold">What can I return?</h6>
                    <p>You may request returns for most items you buy from sellers and suppliers listed on the Platform that are within the return window. However, products that are explicitly identified as ‘not returnable’ on the product detail page cannot be returned.  All returns are subject to other Sections of this policy.</p>
                    <p>However, if you receive a damaged/defective/wrong product from any seller listed on the Platform, you will still be allowed return/replacement even if the product is categorized as  ‘non-returnable’ in the product description.</p>
                    <p>In case you have purchased a product with which a free product is delivered, and you raise a request for return the main product, you will have to return the free product as well. Further, a product which forms a part of a package of other products, you must return all products that form part of the package to process the refund.</p>
                    <p>In circumstances where you return an extra or a different product, <b>Indocraftworld</b> will not be accountable for misplacement or replacement of such product and will not be responsible for its delivery back to you,</p>

                    <h6 className="fw-bold">What are the return options?</h6>
                    <p>Products on Indocraftworld have two return options available: (a) Wrong/Defect item return option, and (b) all return option. If a product does not have the above-mentioned options for return, all return options will be applicable to the product.</p>

                    <h6 className="fw-bold">What is the Wrong/Defect item return option and all return options?</h6>
                    <p><b>Indocraftworld</b> provides an ‘all return option’ to its users, wherein users will be able to enjoy certain privileges on a specific product for payment of a premium on the product cost. Features of an all return option are as follows:</p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>Subject to seller’s return/replacement policy provided on the product description, the user will be allowed to return/refund an already purchased product, if the said product is eligible for return/replacement. </li>
                        <li>The user will be eligible to return the specific product for reasons other than the product being wrong or damaged.</li>
                        <li>The all return option is available only for specific products.</li>
                        <li>For users not availing an all return option, shall be eligible for a ‘Wrong/Defect item return option’ . </li>
                    </ul>
                    <p>If you opt for the Wrong/Defect item return option:</p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>Subject to seller’s return/replacement policy, the user will be allowed to return/refund an already purchased product, if the said product is eligible for return/replacement. </li>
                        <li>The user will be eligible for return/refund of the product if the product received is defective, damaged, wrong or incomplete due to seller’s fault or if the user has received the wrong product.</li>
                    </ul>

                    <h6 className="fw-bold mt-3">Will my return be free of cost?</h6>
                    <p>If you have opted for an all return option, you may return the product free of cost no matter what the reason.</p>
                    <p>If you have opted for the basic return option, you may return the product free of cost if fault with the product lies with the supplier. The following are the faults which lies with the supplier:</p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>Return Reason Category</li>
                        <li>Actual Return Reason</li>
                        <li>Wrong Product</li>
                        <li>Wrong Size Delivered</li>
                        <li>Same product in different colour</li>
                        <li>Completely different product from the product shown</li>
                        <li>Defective Product</li>
                        <li>Product is dirty or with stains</li>
                        <li>Product is broken</li>
                        <li>Product is torn</li>
                        <li>Received incomplete product</li>
                        <li>Part of the product is missing</li>
                        <li>Less quantity than ordered</li>
                    </ul>
                    <p className='mt-3'>The following faults will need to be verified before arranging return free of cost:</p>

                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>The quality is not merchantable</li>
                        <li>Product has low performance</li>
                        <li>Late Delivery</li>
                    </ul>

                    <h6 className="fw-bold mt-3">How to return items?</h6>
                    <p>You can return the products purchased on the Platform within the specified return/exchange period provided on the product description page. <br /> To return a product, please follow the steps mentioned below:</p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>After the ‘return request’ has been raised, Return ID will be generated by <b>Indocraftworld</b>.</li>
                        <li>In case you purchase multiple products in a single order, return requests for individual items can also be raised. </li>
                        <li>Once a return request is raised, <b>Indocraftworld</b> shall analyse the request and accordingly process the request internally. All return / exchange claims shall be subject to <b>Indocraftworld</b>’s discretion. </li>
                        <li>If you have scheduled a pick – up for returning the product, please keep the items ready to be picked up by the delivery partners. </li>
                        <li>Please keep the product in the original manufacturer’s packaging, all tags should be intact and warranty card, if any, should also be placed with the product.</li>
                        <li>At the time of pick up, our delivery partner may conduct a quality check on the product to ensure the product meets the criteria specified under return guidelines. If the product fails such a quality check, the product shall not be returned, and no refund shall be made against such product.</li>
                    </ul>

                    <p className='mt-3'>Please note that the product will be picked up from the same address where delivery was made. In case pick up is to be made from a different address, the same should be notified to Indocraftworld by writing to legalsupport@Indocraftworld.com. Such request may be accepted at Indocraftworld’s sole discretion and subject to the new address being in serviceable area. </p>
                    <p>Further, on receipt of returned product, the product shall undergo a quality check. If the product returned in an acceptable condition, as determined by Indocraftworld at its sole discretion, the refund shall be initiated, otherwise the product will be re-shipped to you, at your cost, and no refund/exchange shall be initiated. </p>
                    </div>

                    <h6 className="fw-bold">
                    What are the return guidelines?
                    </h6>

                    <p>Prior to creating a return or exchange request on the platform, you shall ensure that, the product: <br />
                    must be in its original condition with all the packaging including brand/manufacturer’s box/packaging, tags, warranty cards and other accessories intact; <br />
                    must not be damaged in your possession; <br />
                    must be unused, unwashed, unsoiled, without any stains and with non-tampered quality check seals/return tags/warranty seals (wherever applicable). <br />
                    must not be used or altered; <br />
                    must be the same product that was delivered to you; and <br />
                    must be returned within the return window specified against a product on the product detail page.</p>

                    <p>Further, IMEI/ name/ image/ brand/ serial number/ article number/ bar code of the returned product should match the records of Indocraftworld. Any additional conditions provided in the product details against each product shall be applicable as well.
                    Further:</p>

                    <h6 className='fw-bold'>Your address and the item that you wish to return must be eligible for return.</h6>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>If the return is not eligible for pickup, a return option will not be available.</li>
                        <li>Once the return is received, you will be provided with a refund or exchange, as requested.</li>
                        <li>If you intend to return an electronic device that stores any personal information, you must remove/deleted all such personal information from the device prior to returning. Indocraftworld shall not be liable in any manner whatsoever for any use of such information. Such electronic device should be formatted, and screen lock should be disabled. </li>
                        <li>No return, replacement or exchange shall be accepted if warranty card, if any, is missing while return, replacement or exchange of product.</li>
                    </ul>

                    <p>The products available on the Platform are usually returnable within 7 days of delivery. However, specific details in relation to each products are mentioned in the product description.</p>

                    <h6 className="fw-bold">How will I get my refund?</h6>
                    <p>Refund will be credited to the same source of payment from which payment was received, after deducting the cost of return (where applicable), once returned is received.  </p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                        <li>If you desire to return a product, then you shall be entitled to receive only the amount actually paid by you for the purchase of the product.    </li>
                        <li>In the event any product is returned, then any offer, promotion, discount applied to such product shall be forfeited.</li>
                        <li>If you have made the payment through your bank account, the refund will be credited to the same bank account from which payment was received after deducting the cost of return (where applicable), once the return is received.</li>
                        <li>If you have made the payment as cash on delivery, you will be asked to provide us with your bank account details and the refund amount will be credited to the bank account details provided by you. Indocraftworld shall not be liable to you in case incorrect bank account details have been provided by you.</li>
                        <li>In case of any discrepancies regarding receipt of refund amount, Indocraftworld may request for additional information such as bank statement or any other relevant document.</li>
                    </ul>

                    <h6 className="fw-bold mt-3">
                    How to replace/exchange items?
                    </h6>
                    <p>You can replace/exchange the products purchased on the Platform within the specified return/exchange period, except non-returnable products. To replace/exchange a product, please follow the steps mentioned below:</p>
                    <ul style={{listStyle:'disc',paddingLeft:'15px'}}>
                    <li>Create a ‘replacement request’ or ‘exchange request’ under the ‘My Orders’ section on the Platform and follow the screens that are promoted.</li>
                    <li>In case you purchase multiple products in a single order, replacement/exchange requests for individual items can also be raised.</li>
                    <li>After a replacement/exchange request has been raised, a Replacement/Exchange Id will be generated.</li>
                    <li>Keep the items ready for replacement/exchange pick up. Please keep the product in the original manufacturer’s packaging, all tags should be intact, and warranty card, if any, should also be placed with the product.</li>
                    <li>Once the original product is handed over to the delivery agent, the replaced/exchanged product will be delivered to you separately.</li>
                    <li>At the time of pick up, the delivery agent may conduct a quality check on the product to ensure the product meets the criteria specified under return guidelines. If the product fails such quality check, the product shall not be replaced/exchanged, and no refund shall be made against such product.</li>
                    <li>The product will be picked up from the same address where delivery was made. In case pick up is to be made from a different address, the same should be notified to Indocraftworld by writing to legalsupport@Indocraftworld.com. Such request may be accepted or rejected at Indocraftworld’s sole discretion and subject to the new address being in serviceable area.</li>
                    <li>Further, on receipt of returned product, the product shall undergo a quality check. If the product is in an acceptable condition, as determined by Indocraftworld at its sole discretion, the refund shall be initiated, otherwise, the product will be re-shipped to you, at your cost, and no refund shall be initiated.</li>
                    </ul>

                    <p className='mt-4'>Miscellaneous </p>

                    <p>Please note that all the customer claims and requests for return/refund shall be subject to Indocraftworld’s satisfaction.</p>

                    <p>Indocraftworld reserves the right, at its sole discretion, to amend, change, modify, add or remove any portion of this policy at any time without any prior written notice to you. It is your responsibility to review this policy periodically for any updates/ changes.</p>

                    <p>Indocraftworld also reserves the right, at its sole discretion, to cancel any refund for reasons including but not limited to: (a) fraud or malpractice committed by the user or (b) for any other reason that Indocraftworld may deem fit.</p>

                    <p>For any further queries regarding return, replacement, exchange, or refund, please reach out to customer support at <a className='text-primary' href="mailto:legalsupport@Indocraftworld.com">legalsupport@Indocraftworld.com</a>.</p>
                </div>

            </section>
    </>
  )
}
