import React from 'react'

export const LoginSvg = () => {
  return (
    <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="251.7px"
                height="220px"
                className='login_svg'
                viewBox="0 0 251.7 220"
                style={{
                overflow: "visible",
                enableBackground: "new 0 0 251.7 220",
                }}
                xmlSpace="preserve"
            >
                <defs />
                <g>
                <path
                    className="st0"
                    d="M0,143.5c0,28.8,9.7,55.3,25.9,76.5h199.8c16.3-21.2,25.9-47.7,25.9-76.5c0-2,0-3.9-0.1-5.9 c-3.1-66.8-58.2-120-125.7-120c-67.5,0-122.6,53.2-125.7,120C0,139.6,0,141.5,0,143.5z"
                />
                <g>
                    <path
                    className="st1"
                    d="M216.6,99.5c6.8,5.8,0,18.3-14.3,24.8l-7.9-1.8c0,0-5.3-3.9-0.5-11.4c4.7-7.4,7.8-4.4,7.8-4.4L216.6,99.5z"
                    />
                    <path
                    className="st1"
                    d="M193.7,111.1c4.9-8.4-0.3-15.6,3.5-16.6c3.8-1,5.6,12.7,5.6,12.7l-3.5,4.2L193.7,111.1z"
                    />
                    <g>
                    <g>
                        <path
                        className="st2"
                        d="M218.9,82.9c1.2,0.6,1.8,2.1,1.2,3.3l-15.7,33.3c-0.6,1.2-2.1,1.8-3.3,1.2c-1.2-0.6-1.8-2.1-1.2-3.3 l15.7-33.3C216.2,82.8,217.7,82.3,218.9,82.9z"
                        />
                    </g>
                    </g>
                    <path
                    className="st1"
                    d="M204.8,114.1l2.1-0.7c-1-0.1-1.9-0.8-2.3-1.8c-0.5-1.4,0.3-2.9,1.6-3.4l4.2-1.5c-1.4,0.5-2.9-0.3-3.4-1.6 c-0.5-1.4,0.3-2.9,1.6-3.4l8.4-2.9c1.4-0.5,2.9,0.3,3.4,1.6c0.5,1.4-0.3,2.9-1.6,3.4l-4.2,1.5c1.4-0.5,2.9,0.3,3.4,1.6 c0.5,1.4-0.3,2.9-1.6,3.4l-3.8,1.3c0.8,0.1,1.5,0.6,1.8,1.4c0.4,1.1-0.2,2.4-1.3,2.8l-6.9,2.4c-1.1,0.4-2.4-0.2-2.8-1.3 C203.1,115.7,203.7,114.5,204.8,114.1z"
                    />
                    <line className="st3" x1={215.3} y1={105} x2={209.7} y2={107} />
                    <line className="st3" x1={212.1} y1={111.6} x2={206.4} y2={113.6} />
                </g>
                <path
                    className="st4"
                    d="M137.4,71c8.1,22.3,5.9,93.9-58.8,93.7c-64.8-0.3-47.5-59.7-15.7-57.4c19,1.4,16.6-21.7,16.6-21.7L137.4,71z"
                />
                <g>
                    <path
                    className="st4"
                    d="M188.8,130.6c0.6-7.7,3.1-10,11.3-9.7c8.2,0.3,10.6,3.9,9.7,11.7C208.8,140.4,188.8,130.6,188.8,130.6z"
                    />
                    <path
                    className="st5"
                    d="M215.7,166.3c0,0,7.2-36.1-12.8-40.4c-13-2.7-19.9-5.4-20,19.9C182.7,200.1,215.7,166.3,215.7,166.3z"
                    />
                    <g>
                    <g>
                        <path
                        className="st1"
                        d="M98.4,124.5l0.1,16.2l10.2,7.9l13.5,0.5l11.8-15.6l-4.7-11.1l-1.6-0.4c-1.3-0.3-2.4-1-3.1-2 c-0.4-0.5-0.7-1.1-0.8-1.7l-2-6.9l-1.8-6.3h-18.4l1.2,14.7c0.2,1.8-0.9,3.5-2.6,4L98.4,124.5z"
                        />
                    </g>
                    <g>
                        <path
                        className="st6"
                        d="M102.7,116.1c0,0,11.3,7.5,22,4c-0.4-0.5-0.7-1.1-0.8-1.7l-2-6.9L102.7,116.1z"
                        />
                    </g>
                    <g>
                        <path
                        className="st5"
                        d="M37.9,172.7l23.6,16.2l14.8-12.5c0.6,12.9,10.9,43.5,10.9,43.5h77.8c-7.8-37.4-8-44.9-8-44.9l8.5,8.7 l20.6-24.5c0,0-19.1-22.3-32.8-28.6c-6.1-2.8-11.5-4.7-15.6-5.9c-5.2-1.5-8.3-2-8.3-2S131,136,119.1,138 c-11.9,2-20.7-13.5-20.7-13.5s-3.5,0.8-8.2,2.2c-8.4,2.4-20.7,6.3-24,9.7C61.1,141.7,37.9,172.7,37.9,172.7z"
                        />
                    </g>
                    <g>
                        <path
                        className="st7"
                        d="M115.1,205.6l3.4-1.5L108,180.6c-0.4-0.9-1.5-1.3-2.4-0.9l0,0c-0.9,0.4-1.3,1.5-0.9,2.4L115.1,205.6z"
                        />
                    </g>
                    <g>
                        <path
                        className="st1"
                        d="M100,202.7l0,1.3l0.5,15.9c0,0,8.5,0.1,15.8-0.6c4.5-0.4,8.5-1.1,9.8-2.3c3.4-3,0.1-5.6,0.1-5.6 c4.6-3.8-0.3-6.8-0.3-6.8s2.3-5-1.6-6.6c-2-0.8-5.4-0.8-9.8,0c-0.7,0.1-1.4,0.3-2.2,0.5c0,0,0,0,0,0c-2.4,0.6-5.2,1.4-8.1,2.5 C102.9,201.5,101.5,202.1,100,202.7z"
                        />
                    </g>
                    <g className="st8">
                        <path
                        className="st9"
                        d="M100,202.7l0,1.3l1.6,5.6c0,0,3.7-8.7,10.7-11.1c-2.4,0.6-5.2,1.4-8.1,2.5 C102.9,201.5,101.5,202.1,100,202.7z"
                        />
                    </g>
                    <g>
                        <path
                        className="st7"
                        d="M76.3,176.4c0.6,12.9,10.9,43.5,10.9,43.5h17c-9.3-9.9-23.4-47.2-23.4-47.2L76.3,176.4z"
                        />
                    </g>
                    <g>
                        <path
                        className="st4"
                        d="M96.7,198.6c9-2.4,9.4,21.2,9.4,21.2H93.9L96.7,198.6z"
                        />
                    </g>
                    <g>
                        <path
                        className="st10"
                        d="M152.8,197.5c-8.4-0.4-10.9,10.2-9.5,22.4h8.4L152.8,197.5z"
                        />
                    </g>
                    <g>
                        <path
                        className="st2"
                        d="M90.3,126.7c22.2,32.6,46.1,13.8,47.5-2.2c-5.2-1.5-8.3-2-8.3-2S131,136,119.1,138 c-11.9,2-20.7-13.5-20.7-13.5S95,125.4,90.3,126.7L90.3,126.7z"
                        />
                    </g>
                    <g>
                        <path
                        className="st5"
                        d="M35.9,219.8h62.2c0,0,2.2-16.7-3-24.8c-5.2-8-31.7,0-31.7,0s6.1-4.4-1.8-6.2l-23.6-16.2 c-1.2,1.5-2.2,3-3.2,4.5c-5.4,8.2-7.3,15.4-7.5,21.4v0C26.9,212.3,35.9,219.8,35.9,219.8z"
                        />
                    </g>
                    <g>
                        <path
                        className="st7"
                        d="M80.7,159.6l0.5-0.1c0-0.2-3.6-19-11.4-25.6l-0.3,0.4C77.1,140.7,80.6,159.4,80.7,159.6z"
                        />
                    </g>
                    <g>
                        <path
                        className="st7"
                        d="M156.9,175.7l7-13.8l-0.4-0.2l-6.3,12.5c-1.2-10.4,0.7-25.3,0.7-25.5l-0.5-0.1c0,0.2-2.1,15.9-0.7,26.4 L156.9,175.7z"
                        />
                    </g>
                    <g>
                        <polygon
                        className="st7"
                        points="57.2,200 64,194.9 49.8,194.9 49.8,195.3 62.6,195.3 56.9,199.6  "
                        />
                    </g>
                    <g>
                        <path
                        className="st7"
                        d="M116.5,150.1c4,0,7.5-1.1,10.1-2.3c6.2-3,9.8-7.8,9.8-7.8l-0.4-0.3c0,0-3.5,4.8-9.6,7.7 c-5.6,2.7-14.6,4.3-25.7-3l-0.3,0.4C106.4,148.8,111.9,150.1,116.5,150.1z"
                        />
                    </g>
                    <g>
                        <path
                        className="st2"
                        d="M151.7,219.8c26.5,0,30.2-0.8,39-4.2c7.6-2.9,12.1-7.8,13.9-15.8c1.1-4.9,0.1-10.3-2-15.6 c-3-7.7-8.2-15.1-12-19.8v0c-2.6-3.3-4.6-5.3-4.6-5.3l-20.6,24.5c-3.8,4.5,4.7,6.3,4.7,6.3C143.5,186.2,151.7,219.8,151.7,219.8 z"
                        />
                    </g>
                    <g>
                        <polygon
                        className="st11"
                        points="181.6,192.2 181.7,191.7 171.2,189.9 179.5,187.2 179.4,186.7 169.3,190  "
                        />
                    </g>
                    <g className="st8">
                        <path
                        className="st12"
                        d="M165.6,183.6l25.2-19.2v0c-2.6-3.3-4.6-5.3-4.6-5.3L165.6,183.6z"
                        />
                    </g>
                    <g className="st8">
                        <path
                        className="st12"
                        d="M190.7,215.6c7.6-2.9,12.1-7.8,13.9-15.8c1.1-4.9,0.1-10.3-2-15.6c-5.7,0.6-0.6,9.4-7.5,16.1 C188.3,206.9,188.5,211.5,190.7,215.6z"
                        />
                    </g>
                    <g>
                        <path
                        className="st13"
                        d="M104.1,215.2c-3,0-3.3,4.5-0.3,4.8c0.1,0,0.3,0,0.4,0h114c2.6,0,4.8-2.2,4.8-4.8l0,0l17.8-50.1 c1.4-3.8-1.5-7.9-5.5-7.9h-74c-2.5,0-4.7,1.6-5.6,3.9l-19.1,54.1l0,0H104.1z"
                        />
                    </g>
                    <g>
                        <rect
                        x={136.5}
                        y={214.9}
                        className="st2"
                        width={86.5}
                        height={0.5}
                        />
                    </g>
                    <g>
                        <path
                        className="st9"
                        d="M125.9,204.7l0-0.2c-2.8-0.5-3.7-0.1-3.7-0.1l0.1,0.2C122.3,204.6,123.2,204.3,125.9,204.7z"
                        />
                    </g>
                    <g>
                        <rect x={123.2} y={211.3} className="st9" width={3} height={0.2} />
                    </g>
                    <g>
                        <path
                        className="st2"
                        d="M187.3,185c-1.3,3.9,0.2,7.9,3.4,9c3.2,1.1,6.9-1.2,8.2-5c1.3-3.9-0.2-7.9-3.4-9 C192.3,178.8,188.7,181.1,187.3,185z"
                        />
                    </g>
                    <g>
                        <g>
                        <path
                            className="st4"
                            d="M83.5,96.2c4,6.8,10.3,12,17.3,14.7c4.8,1.9,10,2.5,15,1.7c2.9-0.5,5.7-1.5,8.2-2.8 c0.2-0.1,0.5-0.2,0.7-0.4c0.6-0.3,1.2-0.7,1.8-1.1c7.6-5.2,12.4-14.4,12.7-24.6c0.1-2.5-0.1-5-0.5-7.5 c-0.3-1.8-0.8-3.5-1.3-5.2c-3.2-9.7-10.3-17.1-18.9-20.4c-4.4-1.7-9.2-2.3-14-1.6C91.9,51,83.8,58.3,80.5,68.1 c-0.1,0.3-0.2,0.5-0.2,0.7c-0.2,0.8-0.5,1.6-0.6,2.4l0,0c0,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.4-0.1,0.5 c-0.1,0.4-0.2,0.8-0.2,1.3c0,0,0,0,0,0c-0.1,0.7-0.2,1.4-0.3,2.1c-0.3,3.2-0.1,6.5,0.5,9.9c0.2,1.3,0.5,2.5,0.9,3.8 C81.2,91.7,82.2,94,83.5,96.2z"
                        />
                        </g>
                        <g>
                        <g>
                            <path
                            className="st1"
                            d="M133,86.1c4.4-5.7,9.4-5.6,11,1.6c1.6,7.2-5.7,12.6-11.2,10C127.3,95.2,133,86.1,133,86.1z"
                            />
                        </g>
                        <g>
                            <path
                            className="st6"
                            d="M137.6,86.6c2.7-0.3,3.5,7.7-2.3,7.5l1.4-2.7l-1-2.4L137.6,86.6z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M134.4,95.2c3.4-3.1,1.8-5.7,1.4-6.1c0.3-0.5,2-3.3,3.3-3.1c0.5,0.1,0.9,0.7,1.1,1.9l0.2,0 c-0.2-1.3-0.7-2-1.3-2.1c-1.5-0.3-3.5,3.1-3.6,3.3l0,0.1l0.1,0.1c0.1,0.1,2.1,2.6-1.4,5.9L134.4,95.2z"
                            />
                        </g>
                        </g>
                        <g>
                        <g>
                            <path
                            className="st1"
                            d="M89.4,93.1c-4.9-5.3-9.9-4.7-10.8,2.6c-0.9,7.4,6.9,12,12.1,8.9C96,101.6,89.4,93.1,89.4,93.1z"
                            />
                        </g>
                        <g>
                            <path
                            className="st6"
                            d="M85,94c-2.7-0.1-3,7.9,2.8,7.4l-1.6-2.6l0.8-2.4L85,94z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M88.9,102.3l0.1-0.2c-3.8-2.9-2-5.6-1.9-5.7l0-0.1l0-0.1c-0.1-0.1-2.4-3.3-3.8-2.9 c-0.6,0.2-1,0.9-1.1,2.2l0.2,0c0.1-1.2,0.4-1.9,0.9-2c1.2-0.3,3.2,2.3,3.5,2.8C86.6,96.8,85.2,99.5,88.9,102.3z"
                            />
                        </g>
                        </g>
                        <g>
                        <path
                            className="st1"
                            d="M96.6,110.4L96.6,110.4c0.2,0.3,0.5,0.7,0.7,1l0,0c3.6,4.5,8.6,7.4,15.6,7c9.4-0.6,14.8-5.1,17.6-11.4 c0.5-1.1,0.9-2.2,1.3-3.3c4.6-15.3-2.4-37.1-2.4-37.1l-40.7,6.5C88.7,73.1,87.3,97.4,96.6,110.4z"
                        />
                        </g>
                        <g>
                        <g>
                            <path
                            className="st9"
                            d="M124,99.4c-0.1,0.6-0.6,1-1.2,0.9c-0.6-0.1-1-0.6-0.9-1.2c0.1-0.6,0.6-1,1.2-0.9 C123.7,98.2,124.1,98.8,124,99.4z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M124.7,101.6c0,0.3-0.3,0.5-0.6,0.4c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4 C124.5,101.1,124.7,101.3,124.7,101.6z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M126.1,99.6c-0.1,0.4-0.4,0.7-0.8,0.6c-0.4-0.1-0.7-0.4-0.6-0.8c0.1-0.4,0.4-0.7,0.8-0.6 C125.9,98.8,126.2,99.2,126.1,99.6z"
                            />
                        </g>
                        </g>
                        <g>
                        <g>
                            <path
                            className="st9"
                            d="M101.3,103c0.5-0.4,1.1-0.3,1.5,0.2c0.4,0.5,0.3,1.1-0.2,1.5c-0.5,0.4-1.1,0.3-1.5-0.2 C100.7,104,100.8,103.3,101.3,103z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M102.3,100.9c0.2-0.2,0.6-0.1,0.7,0.1c0.2,0.2,0.1,0.6-0.1,0.7c-0.2,0.2-0.6,0.1-0.7-0.1 C102.1,101.4,102.1,101,102.3,100.9z"
                            />
                        </g>
                        <g>
                            <path
                            className="st9"
                            d="M99.9,101.3c0.3-0.2,0.8-0.2,1,0.1c0.2,0.3,0.2,0.8-0.1,1c-0.3,0.2-0.8,0.2-1-0.1 C99.5,102,99.6,101.6,99.9,101.3z"
                            />
                        </g>
                        </g>
                        <g>
                        <g>
                            <defs>
                            <path
                                id="SVGID_1_"
                                d="M111.2,112.7c0.1,0.7,1.4,1.1,2.9,0.9c1.5-0.2,2.7-0.8,2.7-1.5c-0.1-0.7-1.4-1.1-2.9-0.9 C112.3,111.3,111.1,112,111.2,112.7z"
                            />
                            </defs>
                            <clipPath id="SVGID_2_">
                            <use
                                xlinkHref="#SVGID_1_"
                                style={{
                                overflow: "visible",
                                }}
                            />
                            </clipPath>
                            <polygon
                            className="st14"
                            points="116.7,111 116.8,112.7 116.8,111  "
                            />
                            <polygon
                            className="st14"
                            points="115.7,111 116,113.7 116.8,113.7 116.8,112.7 116.7,111  "
                            />
                            <polygon
                            className="st15"
                            points="114.8,111 115.1,113.7 116,113.7 115.7,111  "
                            />
                            <polygon
                            className="st16"
                            points="113.8,111 114.1,113.7 115.1,113.7 114.8,111  "
                            />
                            <polygon
                            className="st17"
                            points="112.9,111 113.2,113.7 114.1,113.7 113.8,111  "
                            />
                            <polygon
                            className="st18"
                            points="112,111 112.3,113.7 113.2,113.7 112.9,111  "
                            />
                            <polygon
                            className="st19"
                            points="111.1,112 111.3,113.7 112.3,113.7 112,111 111.1,111  "
                            />
                            <polygon
                            className="st19"
                            points="111.3,113.7 111.1,112 111.1,113.7  "
                            />
                        </g>
                        </g>
                        <g>
                        <path
                            className="st4"
                            d="M119.9,78.5c6.6,1.2,10.5-0.2,4.9-1.7C119.2,75.3,116.5,77.9,119.9,78.5z"
                        />
                        </g>
                        <g>
                        <g>
                            <defs>
                            <path
                                id="SVGID_3_"
                                d="M109.5,96.9l2.6-5.6l3.9,4.7c1,1.2-1.1,1.6-1.1,1.6s0.2,1.2-1.8,1.6c-2,0.2-2.2-1.1-2.2-1.1 S108.9,98.3,109.5,96.9z"
                            />
                            </defs>
                            <clipPath id="SVGID_4_">
                            <use
                                xlinkHref="#SVGID_3_"
                                style={{
                                overflow: "visible",
                                }}
                            />
                            </clipPath>
                            <polygon
                            className="st20"
                            points="117,98.8 113.4,99.4 117,99.4  "
                            />
                            <polygon
                            className="st21"
                            points="117,95.6 108.9,96.8 108.9,99.4 113.4,99.4 117,98.8  "
                            />
                            <polygon
                            className="st22"
                            points="117,92.4 108.9,93.6 108.9,96.8 117,95.6  "
                            />
                            <polygon
                            className="st23"
                            points="108.9,93.6 117,92.4 117,91.4 108.9,91.4  "
                            />
                        </g>
                        </g>
                        <g>
                        <path
                            className="st4"
                            d="M133.4,90.1c5.5-6.7-0.4-19.3-0.4-19.3l-3.5-4.3l-1.4-1.8c0,0-0.3,9.9,1.5,16.9 C131.1,87.8,133.4,90.1,133.4,90.1z"
                        />
                        </g>
                        <g>
                        <path
                            className="st4"
                            d="M90.6,96.9c-7.2-4.9-7.1-19.4-7.1-19.4l3.8-4l2.6-2.1c0,0,1.7,9.4,1.8,17.1C91.9,93.9,90.6,96.9,90.6,96.9 z"
                        />
                        </g>
                        <g>
                        <path
                            className="st4"
                            d="M129.1,63.4c0,0,1.1,11.3-21.6,15.9l1.8-5.8c0,0-5.1,11.7-26.3,10.9l5.4-20.8l31.7-5.1L129.1,63.4z"
                        />
                        </g>
                    </g>
                    <g>
                        <path
                        className="st4"
                        d="M108,52.7c0,0,6.9-13.9,2-14.2c-4.9-0.3-3.9,11.9-3.9,11.9s-0.1-7.4-3.4-7.5c-3.4-0.1,1.1,9.1,1.1,9.1 L108,52.7z"
                        />
                    </g>
                    <g>
                        <path
                        className="st2"
                        d="M90.9,92.3c0.6,5.1,5.3,8.7,10.4,8.1c5-0.6,8.6-5.1,8.1-10.1c0.6-1.1,1.4-1.6,2.2-1.8c0,0,0,0,0,0 c1.2-0.2,2.4,0.8,2.9,1.1c0.8,4.9,5.4,8.4,10.3,7.7c5.1-0.6,8.7-5.3,8.1-10.4c-0.6-5.1-5.3-8.7-10.4-8.1 c-4.5,0.6-7.8,4.2-8.1,8.5c-0.8-0.4-1.9-0.8-3-0.7c0,0,0,0,0,0c-0.9,0.1-1.7,0.5-2.4,1.3c-1.4-4-5.5-6.7-9.9-6.1 C93.9,82.5,90.3,87.2,90.9,92.3z M116.2,89.1c-0.5-4.1,2.4-7.9,6.5-8.4c4.1-0.5,7.9,2.4,8.4,6.5c0.5,4.1-2.4,7.9-6.5,8.4 C120.5,96.1,116.7,93.2,116.2,89.1z M92.7,92c-0.5-4.1,2.4-7.9,6.5-8.4c4.1-0.5,7.9,2.4,8.4,6.5c0.5,4.1-2.4,7.9-6.5,8.4 C97,99.1,93.2,96.1,92.7,92z"
                        />
                    </g>
                    <g>
                        <path
                        className="st4"
                        d="M100.3,94.3L100.3,94.3c1.1,0,1.9-0.9,1.9-1.9v-2.1c0-1.1-0.9-1.9-1.9-1.9l0,0c-1.1,0-1.9,0.9-1.9,1.9v2.1 C98.4,93.5,99.3,94.3,100.3,94.3z"
                        />
                    </g>
                    <g>
                        <path
                        className="st4"
                        d="M123.5,91.8L123.5,91.8c1.1,0,1.9-0.9,1.9-1.9v-2.1c0-1.1-0.9-1.9-1.9-1.9l0,0c-1.1,0-1.9,0.9-1.9,1.9v2.1 C121.6,90.9,122.5,91.8,123.5,91.8z"
                        />
                    </g>
                    <g>
                        <g>
                        <g>
                            <path
                            className="st10"
                            d="M106.3,106.2c0.3,0.3,0.5,0.7,0.9,1.1c1.2,1.2,3.2,2.2,6.3,1.9l0,0l0.2,0l0,0c2.9-0.4,4.6-1.7,5.5-3.1 c0.4-0.5,0.6-1,0.8-1.5c0.4-1.1,0.4-1.9,0.4-1.9l-15.2,1.8C105.5,104.5,105.7,105.2,106.3,106.2z"
                            />
                        </g>
                        </g>
                        <g>
                        <path
                            className="st4"
                            d="M106.3,106.2c0.3,0.3,0.5,0.7,0.9,1.1c3.3-1.9,7.4-2.7,12.1-1.2c0.4-0.5,0.6-1,0.8-1.5 c0.4-1.1,0.4-1.9,0.4-1.9l-15.2,1.8C105.5,104.5,105.7,105.2,106.3,106.2z"
                        />
                        </g>
                    </g>
                    </g>
                </g>
                <path
                    className="st2"
                    d="M245.1,7.7V64c0,4.2-3.4,7.7-7.7,7.7h-49.2l-12.9,12.9V71.6h-12.8c-4.2,0-7.7-3.4-7.7-7.7V7.7 c0-4.2,3.4-7.7,7.7-7.7l74.9,0C241.6,0,245.1,3.4,245.1,7.7z"
                />
                <g>
                    <path
                    className="st5"
                    d="M185.9,58.3h34.9l-2.5-34.9h-7.9v-5c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1v5h-7.9L185.9,58.3z  M209.2,23.4h-11.6v-5c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8V23.4z"
                    />
                    <g>
                    <path
                        className="st2"
                        d="M208.1,27.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3S208.1,26.8,208.1,27.5z"
                    />
                    </g>
                    <g>
                    <path
                        className="st2"
                        d="M195.8,27.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3 C196.4,26.3,195.8,26.8,195.8,27.5z"
                    />
                    </g>
                </g>
                </g>
                <g>
                <path
                    className="st2"
                    d="M122,4.8H48.9c-2.5,0-4.5,2-4.5,4.5v12.2c0,2.5,2,4.5,4.5,4.5h31.3l5.2,8.8l5.2-8.8H122c2.5,0,4.5-2,4.5-4.5 V9.3C126.5,6.8,124.5,4.8,122,4.8z"
                />
                <g>
                    <path
                    className="st13"
                    d="M64.6,11.7l1.1,1.9l-4.1,1.7l4.1,1.7l-1.1,2L61,16.4l0.5,4.5h-2.2l0.4-4.5L56.1,19l-1.2-2l4.1-1.7l-4.1-1.7 l1.1-2l3.7,2.6l-0.5-4.5h2.2L61,14.3L64.6,11.7z"
                    />
                </g>
                <g>
                    <path
                    className="st13"
                    d="M81.3,11.7l1.1,1.9l-4.1,1.7l4.1,1.7l-1.1,2l-3.6-2.6l0.5,4.5H76l0.4-4.5L72.9,19l-1.2-2l4.1-1.7l-4.1-1.7 l1.1-2l3.7,2.6L76,9.8h2.2l-0.5,4.5L81.3,11.7z"
                    />
                </g>
                <g>
                    <path
                    className="st13"
                    d="M98.1,11.7l1.1,1.9l-4.1,1.7l4.1,1.7l-1.1,2l-3.6-2.6l0.5,4.5h-2.2l0.4-4.5L89.7,19l-1.2-2l4.1-1.7l-4.1-1.7 l1.1-2l3.7,2.6l-0.5-4.5H95l-0.5,4.5L98.1,11.7z"
                    />
                </g>
                <g>
                    <path
                    className="st13"
                    d="M114.9,11.7l1.1,1.9l-4.1,1.7L116,17l-1.1,2l-3.6-2.6l0.5,4.5h-2.2l0.4-4.5l-3.6,2.6l-1.2-2l4.1-1.7 l-4.1-1.7l1.1-2l3.7,2.6l-0.5-4.5h2.2l-0.5,4.5L114.9,11.7z"
                    />
                </g>
                </g>
            </svg>
    </>
  )
}
