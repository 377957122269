import { Close } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import { Steps } from "antd";
import * as React from 'react';



export const OrderTrack = ({ openTrack, handleClose, OrderTrackingData }) => {
   // console.log(OrderTrackingData.customerOrderHistory, 'on my order')

    let data = OrderTrackingData.customerOrderHistory;
 
    //let currentIndex = data.length - 1;
  //  console.log(data, 'baba')
    //console.log(currentIndex, 'baba')

    //const stepsData = data?.map((dataItem) => ({
    //    title: dataItem.orderStatus,
    //    description: `${dataItem.orderStatusDetails} - ${dataItem.statusDate} ${dataItem.statusTime} ${dataItem.paymentStatus ? dataItem.paymentStatus:''}`,
    //    status: dataItem.orderStatus === "Delivered" ? "finish" : ""
    //}));
    const stepsData = data?.map((dataItem) => ({
        title: dataItem.orderStatus,
        description: (
            <>
                {dataItem.orderStatusDetails} - {dataItem.statusDate} {dataItem.statusTime}
                {dataItem.paymentStatus && (
                    <span style={{ fontSize: '13px', color: 'red', paddingLeft: '8px' }}>{dataItem.paymentStatus}</span>
                )}
            </>
        ),
        status: dataItem.orderStatus === "Delivered" ? "finish" : ""
    }));

   // console.log(stepsData, 'hfiugfhg')

    
    return (
        <>
            <Dialog
                open={openTrack}
                //    onClose={handleClose}
                fullWidth='md'
                aria-labelledby="responsive-dialog-title"
            >
                <div className="tracking">
                    <h6 className="header p-3 bg-primary m-0">
                        Order Tracking: #{OrderTrackingData?.orderNo}
                    </h6>
                    <div className="shipped_details mt-2 d-flex px-5 py-1 bg-light justify-content-between">
                        <div className="">

                            <h6 style={{ marginBottom: "0px" }} className='mt-2'>{OrderTrackingData?.productName}</h6>
                            <p style={{ fontSize:"12px" }} className='m-0'>Seller: {OrderTrackingData?.vendorName}</p>
                        </div>
                    </div>
                    <div className="shipped_details mt-2 d-flex px-5 py-1 bg-light justify-content-between">
                        <div className="">
                            <p className='m-0'>Shipped Via</p>
                            <h6 className='mt-2'>{OrderTrackingData?.courierName ? `${OrderTrackingData?.courierName}` : "Not Updated Yet" }</h6>
                        </div>
                        <div className="">
                            <p className='m-0'>Tracking No.</p>
                            <h6 className='mt-2'>{OrderTrackingData?.trackingNumber ? `${OrderTrackingData?.trackingNumber}` : "Not Confirm Yet"}</h6>
                        </div>
                        <div className="">
                            <p className='m-0'>Status</p>
                            <h6 className='mt-2'>{OrderTrackingData?.currentOrderStatus ? `${OrderTrackingData?.currentOrderStatus}` : "Pending"}</h6>
                        </div>
                        <div className="">
                            <p className='m-0'>Payment Method</p>
                            <h6 className='mt-2'>{OrderTrackingData?.paymentMethod ? `${OrderTrackingData?.paymentMethod}` : ""} </h6>
                        </div>
                    </div>

                    <div className=" px-5"

                        style={{ paddingTop : "20px" }}>
                        {/*<Steps progressDot current={OrderTrackingData.customerOrderHistory.length - 1} direction="vertical" items={stepsData} />*/}
                        <Steps progressDot current={data?.length - 1} direction="vertical" items={stepsData} />


                    </div>




                    {/*<div  className="stepper">*/}
                    {/*    <div class="md-stepper-horizontal orange">*/}
                    {/*        <div class="md-step active">*/}
                    {/*            <div class="md-step-circle"><span>1</span></div>*/}
                    {/*            <div class="md-step-title">Order Placed</div>*/}
                    {/*            <div class="md-step-bar-left"></div>*/}
                    {/*            <div class="md-step-bar-right"></div>*/}
                    {/*        </div>*/}
                    {/*        <div class="md-step active editable">*/}
                    {/*            <div class="md-step-circle"><span>2</span></div>*/}
                    {/*            <div class="md-step-title">In Transit</div>*/}
                    {/*            <div class="md-step-bar-left"></div>*/}
                    {/*            <div class="md-step-bar-right"></div>*/}
                    {/*        </div>*/}
                    {/*        <div class="md-step active done">*/}
                    {/*            <div class="md-step-circle"><span>3</span></div>*/}
                    {/*            <div class="md-step-title">Completed</div>*/}
                    {/*            <div class="md-step-bar-left"></div>*/}
                    {/*            <div class="md-step-bar-right"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                    <div className="close_btn">
                        <IconButton onClick={handleClose}>
                            <Close fontSize='small' style={{color : "#fff"} } />
                        </IconButton>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
