
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'

import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import './ResetPassword.css'
import { SignupSvg } from './SignupSvg'
import { AlertBox, ApiCall, setFocus } from '../../Common/service'
import { toast } from 'react-toastify';

export const ResetPassswordSlide1 = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Token = queryParams.get('Token');
    const UserCode = queryParams.get('UserCode')
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [formData, setFormData] = useState({
        UserCode: UserCode,
        NewPassword: "",
        ConfirmPassword: ""
    });

    const navigate = useNavigate()

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleResetPassword = async () => {
        //setOtpSent(true);
        let data = await ApiCall(`/api/Web/UpdateForgotPassword`, { ...formData, Token })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            navigate('/login')
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message, data.focus);
         
        }
    };

    return (
        <>
           <section style={{ padding: "50px 50px" }} className={'bg-light'}>
                        <div className={"container"}>
                            <div className="login rounded h-100">
                                <div className="row">
                                    <div className="col-md-6 p-0 login-img justify-content-center align-items-center flex-column d-none d-md-flex">
                                        <SignupSvg />
                                        <h6 className='text-white mb-2 mt-4'>We Are Welcome You !</h6>
                                        <h5 className='text-white'>The World Best Craft Products.</h5>
                                    </div>
                                    <div className="col-md-6 p-0 d-flex flex-column justify-content-center">
                                        <div className="login-form">
                                            <h5>Reset Your Password Here</h5>
                                    <div className='my-3' >

                                        <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                            <InputLabel htmlFor="txtPassword">User Code</InputLabel>
                                            <Input
                                                id="txtUserCode"
                                                type='text'
                                                value={UserCode ? UserCode : ""}
                                                //onChange={(e) => setFormData({ ...formData, NewPassword: e.target.value })}
                                                disabled={UserCode}
                                                
                                            />
                                        </FormControl>
                                    </div>
                                            <div className='my-3' >
                                               
                                                <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                                    <InputLabel htmlFor="txtPassword">New Password</InputLabel>
                                                    <Input
                                                        id="txtNewPassword"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={formData.NewPassword}
                                                        onChange={(e) => setFormData({ ...formData, NewPassword: e.target.value })}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                if (formData.password.trim() === '') {
                                                                    e.preventDefault();
                                                                    toast.error('Please Enter New Password');
                                                                } else {
                                                                    setFocus("txtConfirmPassword");
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='my-3' >
                                                
                                                <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                                    <InputLabel htmlFor="txtPassword">Confirm Password</InputLabel>
                                                    <Input
                                                        id="txtConfirmPassword"
                                                        type={showPassword2 ? 'text' : 'password'}
                                                        value={formData.ConfirmPassword}
                                                        onChange={(e) => setFormData({ ...formData, ConfirmPassword: e.target.value })}
                                                        
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword2}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                if (formData.password.trim() === '') {
                                                                    e.preventDefault();
                                                                    toast.error('Please Enter Confirm Password');
                                                                } else {
                                                                    setFocus("btn");
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='mt-3' >
                                                <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' id="btn" onClick={() => handleResetPassword()}> Reset Forgot Password</button>
                                            </div>
                                        </div>
                                        <div className="login-footer text-center">
                                            <p>Already Have an Account? <Link to='/login' className='text-primary'>Login Now</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        </>
    )
}
