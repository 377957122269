

const Loader = () => {
    return (
        <div style={{ height: "70vh", display: "flex", alignItems: "center", justifyContent:"center" }}>
        
        <div className="d-flex align-items-center">
            <div class="dot-loader"></div>
            <div class="dot-loader dot-loader--2"></div>
            <div class="dot-loader dot-loader--3"></div>
            </div>

        </div>

    )
}

export default Loader




