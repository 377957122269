import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layouts/Layout';
import LoginPopup from './components/Login/LoginPopup';
import './custom.css';
import { setLoginPopupModal, setUniqueUserId } from './features/authSlice';
import ProtectedRoutes from './utility/ProtectedRoutes';
import { BlogDetails } from './components/Blogs/BlogDetails';

export const ScrollToTop = () => {
    const location = useLocation();
  
    useEffect(() => {         
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}



const App = () => {
    const { isAuthenticated, loginPopupModal } = useSelector((state) => state.authData);
    const dispatch = useDispatch();

    useEffect(() => {
        let timer;
        if (!loginPopupModal) {
            if (!isAuthenticated) {
                timer = setTimeout(() => {
                    dispatch(setLoginPopupModal(true));
                }, 240000); // 240000 milliseconds = 4 minutes
            }  
        }

        return () => clearTimeout(timer);
    }, [loginPopupModal, dispatch, isAuthenticated]);

    const handleClose = () => {
        dispatch(setLoginPopupModal(false));
    };

    useEffect(() => {
        // Ensure the Google Translate script is loaded and initialized
        const addTranslateScript = () => {
            const googleTranslateScript = document.createElement('script');
            googleTranslateScript.type = 'text/javascript';
            googleTranslateScript.async = true;
            googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(googleTranslateScript);
        };
        
        addTranslateScript();
    }, []);

    return (
        <>
            <GenrateUniqueUserId />
            {
                !isAuthenticated && <LoginPopup isVisible={loginPopupModal} onClose={handleClose} />
            }
            <ToastContainer position="bottom-center" newestOnTop={true} />
            <ScrollToTop />
          
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { protected: isProtected, element, ...rest } = route;
                        return <Route key={index} {...rest} element={
                            isProtected ? (
                                <ProtectedRoutes>{element}</ProtectedRoutes>
                            ) : (
                                element
                            )
                        } />;
                    })}
                    <Route path="/blogdetails" element={<BlogDetails />} />
                </Routes>
            </Layout>
        </>
    );
}

export default App;



export const GenrateUniqueUserId = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { uniqueUserId } = useSelector((state) => state.authData);

    const generateUniqueUserId = () => {
        const newUserId = uuidv4();
        return newUserId;
    };

    if (location.pathname !== "/checkout") {
        localStorage.removeItem("CartItemDetails");
    }

    useEffect(() => {
        const localStorageUserId = localStorage.getItem('uniqueUserId');

        if (!uniqueUserId && !localStorageUserId) {
            const newUserId = generateUniqueUserId();
            dispatch(setUniqueUserId(newUserId));
            localStorage.setItem('uniqueUserId', newUserId);
        } else if (!uniqueUserId && localStorageUserId) {
            dispatch(setUniqueUserId(localStorageUserId));
        } else if (uniqueUserId && !localStorageUserId) {
            localStorage.setItem('uniqueUserId', uniqueUserId);
        }
    }, [location, dispatch, uniqueUserId]);

    return null;
}