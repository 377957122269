import { Close } from '@mui/icons-material';
import { Dialog, IconButton, Pagination, ButtonBase, Zoom, Tooltip } from '@mui/material';
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CustomButton, InputBox } from '../Common/Input';
import { ApiCall, number, setFocus } from '../Common/service';
import { OrderTrack } from "./OrderTrack";
import { ReviewModal } from "./ReviewModal";
import { toast } from 'react-toastify';
import { KeyboardBackspace } from '@mui/icons-material';
import Loader from '../Common/Loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSelector } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';

const Orders = ({ stateValue = true }) => {
    const [openTrack, setOpenTrack] = useState(false);
    const [state, setState] = useState(false)
    const [openReview, setOpenReview] = useState(false);
    const [showReason, setShowReason] = useState(false);
    const [showReturn, setShowReturn] = useState(false)
    const [orderData, setOrderData] = useState({});
    const [reasonForm, setReasonForm] = useState({
        Reason: "",
        BankName: "",
        AccountNo: "",
        IFSCCode: "",
        AccountHolderName: "",
        UPIID: "",
        RefundPaymenMode: '',
    });
    const [ProductCode, setProductCode] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [OrderList, setOrderList] = useState([]);
    const [OrderTrackingData, setOrderTrackingData] = useState([]);
    const [singleOrderDetailsForCancel, setSingleOrderDetailsForCancel] = useState();
    //  const [awbNo, setAwbNo] = useState("")
    const [customerOrderDetailsLoading, setCustomerOrderDetailsLoading] = useState(false)
    const [printInvoiceLoading, setPrintInvoiceLoading] = useState(false)
    const [filterOrder, setFilterOrder] = useState('Last3Months');
    const [filterStatus, setFilterStatus] = useState('All');
    const handleClear = () => {
        setFilterOrder('Last3Months');
        setFilterStatus('All')
    };
    const [dispatch, setDispatch] = useState()
    const handleChange = (event) => {
        setFilterOrder(event.target.value);
    };
    const handleChangeStatus = (event) => {
        setFilterStatus(event.target.value)
    };
    const prevRoute = useLocation()
    const navigate = useNavigate()

    const { user } = useSelector((state) => state.authData)


    const handleTrackOrder = async (orderNo) => {
        setOpenTrack(true);
        let data = await ApiCall(`/api/Web/TrackOrder`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web",
            OrderNo: orderNo ? orderNo : ''
        })
        if (data.status === 'SUCCESS') {
            setOrderTrackingData(data)
        } else if (data.status === 'ERROR') {
            setOrderTrackingData([])
        }
    };
    const base64ToBlob = (base64, type = "application/pdf") => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type: type });
    }

    const downloadBlob = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const PrintInvoice = async (orderNo) => {
        setPrintInvoiceLoading(true)
        let data = await ApiCall(`/api/Print/GetInvoicePrint`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "",
            OrderNo: orderNo ? orderNo : ''
        });

        if (data.status === 'SUCCESS') {
            const base64String = data.documentPdfBase64.split(';base64,').pop();
            const blob = base64ToBlob(base64String);
            setPrintInvoiceLoading(false)
            downloadBlob(blob, 'invoice.pdf');
        } else if (data.status === 'ERROR') {
            setPrintInvoiceLoading(false)
            toast.error("Some erorr occured. Please try after sometime.")
        }
    };

    const [ReviewData, setReviewData] = useState({
        "rating": "5",
        "reviewRemarks": "",
        "reviewTitle": "",
    })
    const [totalOrder, setTotalOrder] = useState({
        totalOrder: '1',
        pageSize: '1'
    })
    const [PageIndex, setPageIndex] = useState(1);
    const countNo = Math.ceil(parseInt(totalOrder?.totalOrder) / parseInt(totalOrder?.pageSize))
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const handlePageChange = (event, newPageIndex) => {
        setPageIndex(newPageIndex);
    };

    const handleReviewOpen = (productCode, orderNo) => {
        setReviewData({
            "rating": '5',
            "reviewRemarks": '',
            "reviewTitle": '',
        })
        setOpenReview(true);
        setProductCode(productCode);
        setOrderNo(orderNo);
    };

    const handleEditReview = async (productCode, orderNo = '') => {
        setProductCode(productCode);
        setOrderNo(orderNo);
        setState(false)
        let data = await ApiCall(`/api/Web/EditReview`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Product: productCode ? productCode : '',
            orderNo
        })
        if (data.status === 'SUCCESS') {
            setOpenReview(true);
            setReviewData({
                "rating": data.rating,
                "reviewRemarks": data.reviewRemarks,
                "reviewTitle": data.reviewTitle,
            })
            GetOrderList()
        } else if (data.status === 'ERROR') {
            setReviewData({
                "rating": '5',
                "reviewRemarks": '',
                "reviewTitle": '',
            })
        }
    };


    const handleClose = () => {
        setOpenTrack(false);
    };
    let token = JSON.parse(localStorage.getItem('authUser'))
    const GetOrderList = async () => {
        setCustomerOrderDetailsLoading(true)
        let data = await ApiCall(`/api/Web/GetCustomerOrderDetails`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            customer: token?.userCode,
            PageIndex: PageIndex ? PageIndex.toString() : '1',
            filterOrder: filterOrder.toString(),
            filterStatus: filterStatus.toString(),
        })
        if (data.status === 'SUCCESS') {
            setOrderList(data.customerOrderList)
            //  setDispatch(data?.customerOrderList?.dispatch)
            setTotalOrder({ totalOrder: data.totalRecord, pageSize: data.pageSize })
            setCustomerOrderDetailsLoading(false)
            setFilterModalOpen(false)
        } else if (data.status === 'ERROR') {
            setOrderList([])
            setCustomerOrderDetailsLoading(false)

        }
    }
    const HandleShowReasonModal = (Type, OrderDetails) => {
        setSingleOrderDetailsForCancel(OrderDetails)
        setShowReason(true)
        setShowReturn(true)
        setOrderData({ ...OrderDetails, Type: Type })
    }
    const HandleOrderAction = async (type) => {
        const request = {
            OrderNo: orderData?.orderNo,
            OrderStatusType: type,
            ProductCode: orderData.productCode,
            Token: user?.token,
            paymentType: singleOrderDetailsForCancel?.paymentType,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/CancelReturnReplace`, { ...request, ...reasonForm })
        if (data.status === 'SUCCESS') {
            await toast.success(data.message)

            await handleClearReasonFormData()
            GetOrderList()
        } else if (data.status === 'ERROR') {
            await toast.error(data.message)
        }

    }
    const EcomAPIshipmentCancellation = async (AwbNo, OrderNo, type) => {
        const request = {
            AwbNo: AwbNo,
            ORDER_NUMBER: OrderNo,
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/ShipmentCancellation`, { ...request })
        if (data.status === 'SUCCESS') {
            HandleOrderAction(type);
           /* await toast.success(data.message)*/
            await handleClearReasonFormData()
            GetOrderList()
        } else if (data.status === 'ERROR') {
            await toast.error(data.message)

        }

    }
    const EcomAPIshipmentReturn = async (AwbNo, OrderNo, type) => {
        const request = {
            AwbNo: AwbNo,
            ORDER_NUMBER: orderData?.orderNo,
            paymentType: singleOrderDetailsForCancel?.paymentType,
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/ReverseManifest`, { ...request })
        if (data.status === 'SUCCESS') {
            HandleOrderAction(type);
            await handleClearReasonFormData()
            GetOrderList()
        } else if (data.status === 'ERROR') {

            await toast.error(data.message)
        }

    }
    const TrackOrderStatus = async (orderNo, awbNo) => {
        const request = {
            awb: awbNo,
            OrderNo: orderNo ? orderNo : '',
            paymentType: singleOrderDetailsForCancel?.paymentType,
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/TrackAWB`, { ...request })
        if (data.status === 'SUCCESS') {
            await toast.success(data.message)
            GetOrderList()
        } else if (data.status === 'ERROR') {
            await toast.error(data.message)
        }

    }
    const handleFilter = () => {
        setFilterModalOpen(true);
    };

    const handleDialogClose = () => {
        setFilterModalOpen(false);
    };
    const handleClearReasonFormData = async () => {
        await setShowReason(false)
        await setReasonForm({
            ...reasonForm,
            Reason: "",
            BankName: "",
            AccountNo: "",
            IFSCCode: "",
            AccountHolderName: "",
            UPIID: "",
            RefundPaymenMode: '',
        })
        await setOrderData({});
    }
    console.log(orderData, "orderData")
    useEffect(() => {
        GetOrderList()
    }, [PageIndex])


    if (customerOrderDetailsLoading || printInvoiceLoading) {
        return <Loader />
    }


    return (
        <>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>

                <button type="button" className="btn btn-outline-dark" onClick={handleFilter}> <i className="fa-solid fa-filter" />Filter</button>


            </div>
            {!token ? <Navigate to={'/login'} state={prevRoute?.pathname} replace /> :

                OrderList && OrderList.length <= 0 ? <>

                    <div style={{ marginBottom: '50px' }} className="container empty_cart">
                        <div className="row justify-content-center">
                            <div className="col-md-5 text-center">
                                <img src="./images/cart_empty.svg" alt="" />
                                <h3 className='fw-bold text-theme mt-3 mb-0'>No Record Found!</h3>
                                <p className='my-4'>You have not placed an order yet. Please add items to your cart and checkout when you are ready.</p>
                                <div className="buttons-group text-center">
                                    <Link to='/' className='bg-primary text-white py-3 px-4 border-0 rounded mybtn'><KeyboardBackspace />Explore Products </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </> :

                    <section className={stateValue ? 'py-5 myprofile' : 'pt-0'} >
                        <div className={stateValue ? 'container-fluid' : ''}>
                            <div className="order_tab">
                                <div className="tab_head py-2 d-flex justify-content-between align-items-center border-bottom">
                                    <h5 className='m-0'><b>My Orders</b></h5>
                                </div>
                                {OrderList?.map((orders, index) => {
                                    const deliveryDate = new Date(`${orders.deliveryDate}`);
                                    const daysToAdd = `${orders.returnableDays}`;
                                    const expirationDate = new Date(deliveryDate.getTime() + parseInt(daysToAdd) * 24 * 60 * 60 * 1000);
                                    const formattedDate = expirationDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
                                    const currentdate = new Date();
                                    return (
                                        <div key={index} className="tab_body pt-3">
                                            <div className="bg-light rounded border-0 p-3">
                                                <div className="ordr_num mb-3 d-flex flex-wrap justify-content-between align-items-center">
                                                    <div>
                                                        <b>
                                                            <Link to={`/OrderDetials?OrderNo=${orders.orderNo}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                Order ID: {orders.orderNo}
                                                            </Link>
                                                        </b>

                                                        <h6 className='m-0 p-0'>Order On {orders.orderDate}</h6>
                                                    </div>
                                                    <Tooltip arrow title={orders.deliveryStatus} TransitionComponent={Zoom} placement="top-start">
                                                        <h6
                                                            className={`m-0 p-0 productName
                                                             ${orders.deliveryStatus === "DELIVERED" ? 'orderStatusDelivered' : ''}
                                                             ${orders.deliveryStatus === "Cancel" ? 'orderStatusCancel' : ''}`}
                                                            style={{ maxWidth: "350px" }}
                                                        >
                                                            {orders.deliveryStatus}
                                                        </h6>
                                                    </Tooltip>
                                                    <div className="text-end">

                                                        <button
                                                            style={{ minWidth: "155px" }}
                                                            className='bg-primary border-0 text-white py-1 px-2 rounded mt-2'
                                                            onClick={() => {
                                                                handleTrackOrder(orders.orderNo);
                                                                TrackOrderStatus(orders.orderNo, orders.awbNo);
                                                            }}
                                                        >
                                                            Track Order Status
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="ordr_info d-flex flex-wrap justify-content-between gap-3">
                                                    <div className='d-flex flex-wrap gap-3'>
                                                        <figure className='rounded'>
                                                            <img
                                                                src={orders.productImageUrl}
                                                                alt=""
                                                                onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }}
                                                            />
                                                        </figure>
                                                        <div>
                                                            <Tooltip arrow title={orders.productName} TransitionComponent={Zoom} placement="top-start">
                                                                <h6 className='productName orderPage_heading' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate(`/${orders.seoFriendly}/${orders.productCode}`);
                                                                }}>
                                                                    {orders.productName}
                                                                </h6>
                                                            </Tooltip>
                                                            <span>{orders.vendorName}</span>
                                                            <div>
                                                                <span>Qty :{orders.quantity}</span>
                                                                <div>
                                                                    <span className="text-success fs-5">₹{orders.sellingPrice}</span>
                                                                </div>
                                                                <div>
                                                                    {orders.discount >= '1.00' && (
                                                                        <>
                                                                            <span className="text-secondary text-decoration-line-through">₹{orders.mrpAmount}</span> &nbsp;
                                                                            <span className='text-danger'>
                                                                                {orders.discountType === "FLAT" && "₹"}{orders.discount}
                                                                                {orders.discountType === "PERCENTAGE" && "%"} Off
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ordr_placed text-end">
                                                        {(orders.isCancel || orders.deliveryStatus === "DISPATCHED" || orders.deliveryStatus === "Undelivered" || orders.deliveryStatus === "Pending" || orders.deliveryStatus === "Placed" || orders.deliveryStatus === "Accepted") && (
                                                            <div>
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    className='bg-danger border-0 text-light py-1 px-2 rounded'
                                                                    onClick={() => HandleShowReasonModal('Cancel', orders)}
                                                                >
                                                                    <i className="fa-solid fa-window-close me-2"></i>Cancel Order
                                                                </button>
                                                            </div>
                                                        )}
                                                        {currentdate < expirationDate && orders.deliveryStatus === "DELIVERED" && orders.isNotReplaceable && (
                                                            <div className="mt-2">
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    className='bg-primary border-0 text-light py-1 px-2 rounded'
                                                                    onClick={() => HandleShowReasonModal('Replace', orders)}
                                                                >
                                                                    Replace Order
                                                                </button>
                                                            </div>
                                                        )}
                                                        {currentdate < expirationDate && orders.deliveryStatus === "DELIVERED" && orders.isNotReturnable && (
                                                            <div className="mt-2">
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    className='bg-primary border-0 text-light py-1 px-2 rounded'
                                                                    onClick={() => HandleShowReasonModal('Return', orders)}
                                                                >
                                                                    Return Order
                                                                </button>
                                                            </div>
                                                        )}
                                                        {(orders.deliveryStatus === "DELIVERED" || orders.deliveryStatus === "Return" || orders.deliveryStatus === "Replace") && (
                                                            <div className="mt-2">
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    onClick={() => PrintInvoice(orders.orderNo)}
                                                                    className='bg-dark border-0 text-light py-1 px-2 rounded'
                                                                >
                                                                    <i className="fa-solid fa-file me-2"></i>Print Invoice
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className="mt-2 mb-1">
                                                            {orders.deliveryStatus === "DELIVERED" && (!orders.reviewStatus ? (
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    className='bg-secondary border-0 text-dark py-1 px-2 rounded'
                                                                    onClick={() => handleReviewOpen(orders.productCode, orders.orderNo)}
                                                                >
                                                                    <i className="fa-solid fa-pencil me-2"></i>Write a Review
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    style={{ minWidth: "155px" }}
                                                                    className='bg-secondary border-0 text-light py-1 px-2 rounded'
                                                                    onClick={() => handleEditReview(orders.productCode, orders.orderNo)}
                                                                >
                                                                    <i className="fa-solid fa-eye me-2"></i>View/Edit Review
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {countNo > 1 && (
                                <Pagination
                                    className='pt-2'
                                    sx={{ float: 'right', paddingBottom: "100px" }}
                                    count={countNo}
                                    variant="outlined"
                                    page={PageIndex}
                                    onChange={handlePageChange}
                                    shape="rounded"
                                    color="primary"
                                />
                            )}
                        </div>
                    </section>

            }

            <OrderTrack openTrack={openTrack} OrderTrackingData={OrderTrackingData} handleClose={handleClose} />
            <Dialog
                open={filterModalOpen}
                fullWidth={true}
                maxWidth='sm'
                aria-labelledby=""
            >
                <div className=" d-flex align-items-center justify-content-between px-2 py-1" style={{ backgroundColor: "#27a58f", color: "white" }}>
                    {/*<h4 className='p-0 m-0'>{(orderData.Type === "Replace" && "Reason for Replace") || (orderData.Type === "Return" && "Reason for Return") || (orderData.Type === "Cancel" && "Reason for Cancel")}</h4>*/}
                    <h5 className='p-0 m-0'>
                        <b>Filter Orders</b>
                    </h5>
                    <IconButton className='text-white' onClick={async () => {
                        await handleDialogClose();
                    }}>
                        <Close />
                    </IconButton>
                </div>
                <div className="p-2">
                    <h5 style={{ paddingLeft: "8px", font: "bold", fontSize: "16px" }} className='m-0 mt-2'>Filter Status</h5>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: 170 }}>
                        <RadioGroup
                            aria-label="status"
                            name="status"
                            value={filterStatus}
                            onChange={handleChangeStatus}
                        >
                            <FormControlLabel value="DISPATCHED" control={<Radio />} label="DISPATCHED" />
                            <FormControlLabel value="Placed" control={<Radio />} label="Placed" />
                            <FormControlLabel value="DELIVERED" control={<Radio />} label="DELIVERED" />
                            <FormControlLabel value="Return" control={<Radio />} label="Return" />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                        </RadioGroup>
                    </FormControl>
                    <hr></hr>
                    <h5 style={{ paddingLeft: "8px" }} className='m-0 mt-2'>Filter Order</h5>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: 170 }}>
                        <RadioGroup
                            aria-label="order"
                            name="order"
                            value={filterOrder}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="Last3Months" control={<Radio />} label="Last 3 months" />
                            <FormControlLabel value={2024} control={<Radio />} label="2024" />
                            <FormControlLabel value={2023} control={<Radio />} label="2023" />
                            <FormControlLabel value="older" control={<Radio />} label="All" />
                        </RadioGroup>
                    </FormControl>
                    <div style={{ display: 'flex', gap: "10px", justifyContent: 'flex-end', padding: '8px' }}>
                        <ButtonBase className='w-fit' onClick={handleClear}><button className='bg-secondary w-100 py-2 px-3 text-white border-0 rounded mybtn' >Reset </button></ButtonBase>
                        <ButtonBase className='w-fit' onClick={() => {
                            GetOrderList()
                            handleDialogClose()
                        }}
                        ><button className='bg-primary w-100 py-2 px-3 text-white border-0 rounded mybtn' >Apply</button></ButtonBase>


                    </div>
                </div>
            </Dialog>

            <Dialog
                open={showReason}
                fullWidth={true}
                maxWidth='md'
                aria-labelledby="">
                <div className='p-3 pt-0'>
                    <div className=" d-flex align-items-center justify-content-between px-2 mt-1">
                        {/*<h4 className='p-0 m-0'>{(orderData.Type === "Replace" && "Reason for Replace") || (orderData.Type === "Return" && "Reason for Return") || (orderData.Type === "Cancel" && "Reason for Cancel")}</h4>*/}
                        <h4 className='p-0 m-0'>{` ${orderData?.Type} Order`}</h4>
                        <IconButton onClick={async () => {
                            await handleClearReasonFormData();
                        }}>
                            <Close />
                        </IconButton>
                    </div>
                    <hr className='mt-0' />

                    <div className='row p-3 pt-0' >
                        <div>
                            <label htmlFor='txtReason'>{`${orderData?.Type} Reason`}</label>
                            <textarea name='Request to Resubmit' className='col-md-12' id="txtReason" rows="5 " value={reasonForm.Reason} onChange={(e) => setReasonForm({ ...reasonForm, Reason: e.target.value })} />
                        </div>


                        {
                            (singleOrderDetailsForCancel?.paymentType === "COD" && singleOrderDetailsForCancel?.deliveryStatus === "DELIVERED") ||
                                (singleOrderDetailsForCancel?.deliveryStatus === "Return" && singleOrderDetailsForCancel?.paymentType === "COD")
                                ?
                                <>
                                    <h6 className='pt-3 mb-1'>Refund Payment Mode</h6>
                                    <div className='mb-2'>
                                        <div className="">
                                            <input
                                                type='checkbox'
                                                className='me-1'
                                                id='txtBanksAccountDetails'
                                                checked={reasonForm.RefundPaymenMode === "Bank"}
                                                onChange={() => setReasonForm({
                                                    ...reasonForm,
                                                    RefundPaymenMode: 'Bank',
                                                    BankName: "",
                                                    AccountNo: "",
                                                    IFSCCode: "",
                                                    AccountHolderName: "",
                                                    UPIID: "",
                                                })}
                                            />
                                            <label> Bank Account Details </label>
                                        </div>

                                        <div className="">
                                            <input
                                                type='checkbox'
                                                className='me-1'
                                                id='txtUPIDetails'
                                                checked={reasonForm.RefundPaymenMode === "UPI"}
                                                onChange={() => setReasonForm({
                                                    ...reasonForm,
                                                    RefundPaymenMode: 'UPI',
                                                    BankName: "",
                                                    AccountNo: "",
                                                    IFSCCode: "",
                                                    AccountHolderName: "",
                                                    UPIID: "",
                                                })}
                                            />
                                            <label>UPI Details</label>
                                        </div>
                                    </div>
                                    {reasonForm.RefundPaymenMode === "Bank" ? (
                                        <>
                                            <InputBox
                                                divclassname="col-md-4 col-sm-6 fields mb-2"
                                                label="Account Holder Name"
                                                placeholder="Account Holder Name"
                                                id="txtAccountHolderName"
                                                maxLength='100'
                                                value={reasonForm.AccountHolderName}
                                                onChange={(e) => setReasonForm({ ...reasonForm, AccountHolderName: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtBankName") };
                                                }}
                                                required
                                            />

                                            <InputBox
                                                divclassname="col-md-4 col-sm-6 fields mb-2"
                                                label="Bank Name"
                                                placeholder="Bank Name"
                                                id="txtBankName"
                                                maxLength='100'
                                                value={reasonForm.BankName}
                                                onChange={(e) => setReasonForm({ ...reasonForm, BankName: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtAccountNo") };
                                                }}
                                                required
                                            />

                                            <InputBox
                                                divclassname="col-md-4 col-sm-6 fields mb-2"
                                                label="Account No"
                                                placeholder="Account No"
                                                id="txtAccountNo"
                                                maxLength='20'
                                                value={reasonForm.AccountNo}
                                                onChange={(e) => setReasonForm({ ...reasonForm, AccountNo: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtIFSCCode") };
                                                }}
                                                required
                                            />
                                            <InputBox
                                                divclassname="col-md-4 col-sm-6 fields mb-2"
                                                label="IFSC Code"
                                                placeholder="IFSC Code"
                                                id="txtIFSCCode"
                                                maxLength='11'
                                                value={reasonForm.IFSCCode}
                                                onChange={(e) => setReasonForm({ ...reasonForm, IFSCCode: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtMobileNo") };
                                                }}
                                                required
                                            />
                                        </>
                                    ) : reasonForm.RefundPaymenMode === "UPI" ? (
                                        <div className='d-flex align-items-end gap-1'>
                                            <InputBox
                                                divclassname="col-md-4 col-sm-6 fields mb-2"
                                                label="UPI ID"
                                                placeholder="UPI ID"
                                                id="txtUPIID"
                                                maxLength='50'
                                                value={reasonForm.UPIID}
                                                onChange={(e) => setReasonForm({ ...reasonForm, UPIID: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtMobileNo") };
                                                }}
                                                required
                                            />
                                            <span className={`text-danger pb-2`} style={{ cursor: 'pointer' }} >Verify</span>
                                        </div>
                                    ) : <></>}
                                </>
                                : <></>
                        }

                    </div>
                    <div className='mb-2 d-md-flex gap-2 float-end'>

                        <CustomButton
                            id='btnSubmitReason'
                            ButtonIcon='fa-check'
                            ButtonText={`${orderData.Type} Order`}
                            ButtonClass='btn-danger btn-sm float-end'

                            onClick={() => {
                                if (orderData.Type === "Return" || orderData.Type === "Replace") {
                                    EcomAPIshipmentReturn(orderData.awbNo, orderData.orderNo, orderData.Type);
                                    //  HandleOrderAction();
                                } else if (orderData.dispatch || orderData.deliveryStatus === "DISPATCHED" || orderData.deliveryStatus === "Undelivered" || orderData.deliveryStatus === "Accepted" || orderData.Type === "Cancel") {
                                    EcomAPIshipmentCancellation(orderData.awbNo, "", orderData.Type);
                                } else {
                                    HandleOrderAction("Cancel");
                                }
                            }}

                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                        <ErrorOutlineIcon style={{ fontSize: "18px" }} />
                        <Link style={{ textDecoration: "underline" }} to={'/cancellation-policy'}> Cancellation Policy</Link>
                    </div>
                </div>

            </Dialog>
            <ReviewModal
                openReview={openReview}
                ProductCode={ProductCode}
                setReviewData={setReviewData}
                setState={setState}
                state={state}
                orderNo={orderNo}
                ReviewData={ReviewData}
                setOpenReview={setOpenReview} />
        </>
    )
}

export default Orders;



