import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Link, NavLink } from 'react-router-dom';

export default function SideDrawer({ open, toggleDrawer, CatagoryList }) {
    return (
        <div>
            <Drawer className='sidebar_mobe' open={open} onClose={toggleDrawer(false)}>
                {/*<div className="border-bottom text-center py-3">*/}
                {/*    <img*/}
                {/*        src="./images/logo.webp"*/}
                {/*        className="mx-auto"*/}
                {/*        alt=""*/}
                {/*        width='150'*/}
                {/*    />*/}
                {/*</div>*/}
                <h5 className='p-3 text-center fw-bold' style={{ backgroundColor:'var(--secondary)' }}>Indocraftworld</h5>
                <NavLink className='single_link' to='/' onClick={toggleDrawer(false)}>Home</NavLink>
                <div className="accordion" id="accordionExample">
                    {CatagoryList?.map((catagory, catagoryIndex) =>
                        <div className="accordion-item" key={`mainDiv_${catagoryIndex}`}>
                            <h2 className="accordion-header" >
                                <button
                                    className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#Catagory_${catagoryIndex}`} aria-expanded="true" aria-controls="collapseOne">
                                    {catagory.categoryName}
                                </button>
                            </h2>
                            <div id={`Catagory_${catagoryIndex}`}  className="accordion-collapse collapse"  data-bs-parent="#accordionExample"   >
                                <ul>
                                    {catagory?.subCategoryList?.map((subCatagory, subCatagoryIndex) => <li className='ps-3' key={`subCatagory_${catagoryIndex}_${subCatagoryIndex}`}><Link to='' onClick={toggleDrawer(false)} className='side_link'>{subCatagory.subCategoryName}</Link></li>)}

                                </ul>
                            </div>
                        </div>
                    )}
                </div>

            </Drawer>
        </div>
    );
}