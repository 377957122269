
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";


const LocalauthUser = JSON.parse(localStorage.getItem('authUser'))


export const buyNowApi = createAsyncThunk(
    'buyNowApi',
    async (product, { getState }) => {
        try {   
            const authData = getState().authData.user;     
            const response = await ApiCall(`/api/Web/BuyProduct`, {
                LoginUserCode: authData.loginUserCode ? authData.loginUserCode : LocalauthUser.loginUserCode,
                ProductCode: product.productCode,
                Qty: product.itemQuantity ? product.itemQuantity.toString() : '1',
                requiredOtherProducts: product.requiredOtherProducts ? product.requiredOtherProducts : [],
                Source: product.Source ? product.Source : "Web",
                Token: authData.token ? authData.token : LocalauthUser.token,
            })
            return response;

        } catch (error) {
            throw new Error('Some Error has been Occured please try after some time');
        }
    }
);




export const buyNowSlice = createSlice({
    name: 'buyNowSlice',
    initialState: {
        message: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //For addToWishListApi
            .addCase(buyNowApi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buyNowApi.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload;
            })
            .addCase(buyNowApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })         
    }
});

export default buyNowSlice.reducer;
