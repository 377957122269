import { KeyboardBackspace } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

export const ComingSoon = ({Heading }) => {
    return (
        <>
            <section className='mt-0 pt-0 mb-2'>
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-md-5 text-center">
                            <img src="./images/coming-soon.svg" alt="" />
                            <h3 className='fw-bold text-theme  mb-0'>{`${Heading} page is Coming Soon` }</h3>
                            <p className='my-3'>The page you requested does not exist. Our eam is working for this page existance.</p>
                            <div className="buttons-group text-center mb-4">
                                <Link to='/' className='bg-primary text-white p-2 border-0 rounded mybtn'><KeyboardBackspace /> Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
