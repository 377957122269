import { Close } from '@mui/icons-material';
import { FormControl, Dialog, InputLabel, IconButton, Select, MenuItem, CircularProgress, ListItemIcon, ListItemText } from '@mui/material';
import React, { useRef, useState } from 'react';
import { AutoCompleteBox, InputBox } from '../Common/Input';
import { FetchAutoCompleteData, number, setFocus } from '../Common/service';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
export default function AddressForm({ openAddressAdd, handleClose, formData, setFormData, GetStatCity, handleAddAddress, tokenData, editAddress }) {
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [countryCodes, setCountryCodes] = useState([]);
    const autoCompleteTimeoutRef = useRef(null);
    const handleCountryCodeChange = (e) => {
        setFormData({ ...formData, countryCode: e.target.value });
    };
    const [loading, setLoading] = useState(true);

    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countries = response.data.map((country) => ({
                name: country.name.common,
                dial_code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
                flag: country.flags.png,
            }));
            setCountryCodes(countries);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching country codes:', error);
            toast.error('Failed to load country codes.');
            setLoading(false);
        }
    };
    const [maxLength, setMaxLength] = useState(15);

    useEffect(() => {
        if (formData.countryCode === '+91') {
            setMaxLength(10);
        } else {
            setMaxLength(15);
        }
    }, [formData.countryCode]);
    const handleMobileNoChange = (event) => {
        const value = event.target.value;
        // Ensure the value contains only numbers
        if (/^\d*$/.test(value)) {
            setFormData({ ...formData, mobileNo: value });
        }
    };
    useEffect(() => {

        fetchCountryCodes()
    }, [])
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(tokenData, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };

    return (
        <>
            <Dialog
                open={openAddressAdd}
                // onClose={handleClose}
                fullWidth='md'
                aria-labelledby="responsive-dialog-title"
            >
                <div className="p-3">
                    {
                        editAddress === 'edit' ? <h6 className="header">
                            Update address
                        </h6> : <h6 className="header">
                            Add a new address
                        </h6>
                    }

                    <p>Please {editAddress === 'edit' ? 'update' : 'fill'} in the information below:</p>
                    <div className="adres_form_body">
                        <InputBox
                            divclassname='mb-2'
                            label="Name"
                            placeholder=""
                            id="txtFirstName"
                            maxLength='50'
                            value={formData.customerName}
                            onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { setFocus("txtMobileNo") };
                            }}
                            required
                        />

                        <div className="d-flex">
                            <FormControl variant="standard" sx={{ minWidth: 120, marginRight: 2, }}>
                                <label className="form-label" > Country Code
                                    <span style={{ color: "red" }}> *</span>
                                </label>
                                <Select
                                    labelId="country-code-label"
                                    id="country-code"
                                    value={formData.countryCode}
                                    onChange={handleCountryCodeChange}
                                >
                                    {loading ? (
                                        <MenuItem value="">
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        countryCodes.map((country) => (
                                            <MenuItem key={country.dial_code} value={country.dial_code}>

                                                <ListItemText primary={` ${country.dial_code}`} />
                                                <ListItemIcon>
                                                    <img src={country.flag} alt={`${country.name} flag`} width="22" height="14" className="mt-2" />
                                                </ListItemIcon>
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>

                            <InputBox
                                divclassname='mb-2 col-lg-9'
                                label="Mobile No."
                                placeholder=""
                                id="txtMobileNo"
                                maxLength={maxLength}
                                value={formData.mobileNo}
                                onChange={handleMobileNoChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setFocus("txtAddress")
                                    };
                                }}
                                required
                            />
                        </div>

                        <InputBox
                            divclassname='mb-2'
                            label="Address"
                            placeholder=""
                            id="txtAddress"
                            maxLength='50'
                            value={formData.address}
                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { setFocus("txtLocalityTown") };
                            }}
                            required
                        />

                        <InputBox
                            divclassname='mb-2'
                            label="Locality/Town"
                            placeholder=""
                            id="txtLocalityTown"
                            maxLength='50'
                            value={formData.locality_Town}
                            onChange={(e) => setFormData({ ...formData, locality_Town: (e.target.value) })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { setFocus("txtPincode") };
                            }}
                            required
                        />
                        <div className="d-flex gap-2">
                            <AutoCompleteBox
                                divclassname='col '
                                label="Pin Code"
                                placeholder=""
                                id="txtPincode"
                                maxLength='50'
                                inputValue={formData.pincode ? formData.pincode : ''}
                                onInputChange={
                                    (event, newInputValue) => {
                                        handleAutoCompleteChange(event, newInputValue, 'pincode', 'GetPinCode', '');
                                        GetStatCity(newInputValue)
                                    }}
                                options={autoCompleteOptions}
                                setOptions={setAutoCompleteOptions}
                                required={true}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        //  GetStatCity(formData.pincode);
                                        setFocus("txtCity");
                                    };
                                }}
                            //    onBlur={() => { GetStatCity(formData.pincode) } }
                            />
                            <div className='col'>
                                <InputBox
                                    divclassname='mb-2'
                                    label="City"
                                    placeholder=""
                                    id="txtCity"
                                    maxLength='50'
                                    value={formData.city}
                                    onChange={(e) => setFormData({ ...formData, city: (e.target.value) })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { setFocus("txtState") };
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <InputBox
                            divclassname='mb-2'
                            label="State"
                            placeholder=""
                            id="txtState"
                            maxLength='50'
                            value={formData.state}
                            onChange={(e) => setFormData({ ...formData, state: (e.target.value) })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { setFocus("txtTypeOfAddresshHome") };
                            }}
                            required
                        />
                        <div className="typeofAdres">
                            <div className='mb-2'>
                                <label>Type of Address</label>
                                <div className='mt-2 d-flex gap-2'>
                                    <div className=''>
                                        <input name='txtTypeOfAddress'
                                            id='txtTypeOfAddresshHome'
                                            type='radio'
                                            className='me-1'
                                            checked={formData.typeOfAddress === "home" ? true : false}
                                            value="home"
                                            onChange={(e) => setFormData({ ...formData, typeOfAddress: e.target.checked ? 'home' : '', isOpenSaturday: false, isOpenSunday: false })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtTypeOfAddressOffice") };
                                            }}
                                        />
                                        <label htmlFor='txtTypeOfAddresshHome'>Home</label>
                                    </div>
                                    <div className=''>
                                        <input name='txtTypeOfAddress'
                                            id='txtTypeOfAddressOffice'
                                            type='radio'
                                            className='me-1'
                                            checked={formData.typeOfAddress === "office" ? true : false}
                                            value="office"
                                            onChange={(e) => setFormData({ ...formData, typeOfAddress: e.target.checked ? 'office' : '', isOpenSaturday: false, isOpenSunday: false })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { formData.typeOfAddress === 'office' ? setFocus("txtIsOpenSaturday") : setFocus("txtIsDefaultAddress") };
                                            }}
                                        />
                                        <label htmlFor='txtTypeOfAddressOffice'>Office</label>
                                    </div>
                                    <div className=''>
                                        <input name='txtTypeOfAddress'
                                            id='txtTypeOfAddressOffice'
                                            type='radio'
                                            className='me-1'
                                            checked={formData.typeOfAddress === "other" ? true : false}
                                            value="other"
                                            onChange={(e) => setFormData({ ...formData, typeOfAddress: e.target.checked ? 'other' : '', isOpenSaturday: false, isOpenSunday: false })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { formData.typeOfAddress === 'other' ? setFocus("txtIsOpenSaturday") : setFocus("txtIsDefaultAddress") };
                                            }}
                                        />
                                        <label htmlFor='txtTypeOfAddressOffice'>Other</label>
                                    </div>
                                </div>
                            </div>

                            {formData.typeOfAddress === 'office' && <div className='mb-2'>
                                <label>Your Office open to Weekends</label>
                                <div className='mt-2 d-flex gap-2'>
                                    <div className=''>
                                        <input type='checkbox' id='txtIsOpenSaturday'
                                            className='me-1 form-check-input' checked={formData.isOpenSaturday}
                                            onChange={(e) => setFormData({ ...formData, isOpenSaturday: e.target.checked })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtIsOpenSunday") };
                                            }}
                                        />
                                        <label htmlFor='txtIsOpenSaturday'>Open Saturday</label>
                                    </div>
                                    <div className=''>
                                        <input type='checkbox'
                                            id='txtIsOpenSunday'
                                            className='me-1 form-check-input'
                                            checked={formData.isOpenSunday}
                                            onChange={(e) => setFormData({ ...formData, isOpenSunday: e.target.checked })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtIsDefaultAddress") };
                                            }}
                                        />
                                        <label htmlFor='txtIsOpenSunday'>Open Sunday</label>
                                    </div>
                                </div>
                            </div>}
                            <div className="my-3 d-flex align-items-center gap-2">
                                <input className='form-check-input' type="checkbox" checked={formData.isDefaultAddress} onChange={(e) => setFormData({ ...formData, isDefaultAddress: e.target.checked })} id="txtIsDefaultAddress" />
                                <label for="txtIsDefaultAddress" className="form-label mb-0">Set as default address</label>
                            </div>
                        </div>
                        <div>
                            <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={handleAddAddress}>{editAddress === 'edit' ? 'Update Address' : 'Add a new address'}</button>
                        </div>
                    </div>
                </div>
                <div className="close_btn">
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </div>
            </Dialog>
        </>
    )
}
