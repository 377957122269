import { Close, Favorite, Fullscreen } from '@mui/icons-material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Fab, Tooltip, Zoom } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addToCartApi } from '../../features/addToCartAfterLogin';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { AddtoWishListApi, GetWishListDataApi, RemoveFromWishListApi } from '../../features/wishListSlice';
import { baseUrl } from '../../index';
import { GetSingleProductDetails } from '../../utility/apiHelper';
import { QuickViewNew } from './QuickViewNew';
import RatingStar from './RatingStar';
import RequiredProductModal from './RequiredProductModal';

const Card = ({ Source, item, index, horizontalView }) => {
    const [showRequiredProductModal, setShowRequiredProductModal] = useState(false)
    const [quickViewNew, setQuickViewNew] = useState(false)
    const [quickViewNewData, setQuickViewNewData] = useState([])
    const [wishListIcon, setWishListIcon] = useState(item.favourite)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isAuthenticated } = useSelector((state) => state.authData)
    const [, toggleQuickView] = useState(false)
    const path = window.location.pathname;
    const wishlistUrl = path.split('/')[path.split('/').length - 1]
    const [isLoading, setIsLoading] = useState(false);

    //const handleAddToCart = (e, item) => {
    //    e.preventDefault()
    //    if (item.requiredOtherProducts?.length) {
    //        setShowRequiredProductModal(true)
    //    } else {
    //        if (isAuthenticated) {
    //            setIsLoading(true);
    //            dispatch(addToCartApi({
    //                productCode: item.productCode, requiredOtherProducts: []
    //            }))
    //                .then(async (response) => {

    //                    toast.success(response.payload.message)
    //                    dispatch(GetOverAllCountAPI())
    //                    setIsLoading(false)
    //                })
    //                .catch((error) => {
    //                    toast.error('Some Error Occured, Please try after sometime');
    //                });
    //        } else {
    //            dispatch(addToCart({item}))
    //            toast.success('Product added in cart')
    //        }
    //    }
    //}

    const handleAddToCart = (e, item) => {

        e.preventDefault()
        if (item.requiredOtherProducts?.length) {
            setShowRequiredProductModal(true)
        } else {
            setIsLoading(true);
            dispatch(addToCartApi({
                productCode: item.productCode, requiredOtherProducts: [], Source: Source, itemQuantity :item.minCartQuantity,
            }))
                .then(async (response) => {
                    toast.success(response.payload.message)
                    dispatch(GetOverAllCountAPI())
                    setIsLoading(false)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        }
    }

    const handleAddWishlist = (e, productCode) => {
        e.preventDefault()
        if (isAuthenticated) {
            dispatch(AddtoWishListApi(productCode))
                .then(async (response) => {
                    toast.success(response.payload)
                    dispatch(GetOverAllCountAPI())
                    setWishListIcon(true)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        } else {
            navigate('/login')
        }
    }

    const handleRemoveFromWishList = (e, productCode) => {
        e.preventDefault()
        if (isAuthenticated) {
            dispatch(RemoveFromWishListApi(productCode))
                .then(async (response) => {
                    toast.success(response.payload)
                    dispatch(GetOverAllCountAPI())
                    setWishListIcon(false)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        } else {
            navigate('/login')
        }
    }

    const handleRemoveFromWishListFromWishList = (e, productCode) => {
        e.preventDefault()
        if (isAuthenticated) {
            dispatch(RemoveFromWishListApi(productCode))
                .then(async (response) => {
                    toast.success(response.payload)
                    dispatch(GetOverAllCountAPI())
                    dispatch(GetWishListDataApi());
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        } else {
            navigate('/login')
        }
    }

    const GetProductDetails = async (productCode) => {
        let response = await GetSingleProductDetails(productCode)
        await setQuickViewNewData(response);
        setQuickViewNew(true)
    }


    if (showRequiredProductModal) {
        return <RequiredProductModal
            Source={Source}
            showRequiredProductModal={showRequiredProductModal}
            setShowRequiredProductModal={setShowRequiredProductModal}
            Product={item}
            toggleQuickView={toggleQuickView}

        />
    }

    if (quickViewNew) {
        return <QuickViewNew open={quickViewNew} toggleQuickView={setQuickViewNew} ProductDetails={quickViewNewData} />
    }


    //if (isLoading) {
    //    return (

    //        <Loader />
    //        //<div className="col-md-4 col-lg-3 col-6">
    //        //    <div className='mt-4'>
    //        //        <Skeleton animation="wave" style={{ height: "430px", width:"275px" }} />
    //        //    </div>
    //        //</div>
    //    )
    //}


    return (

        <div key={index} className={`product_item ${horizontalView ? 'product_item_horizontal row justify-content-between align-items-center' : ''} position-relative border mt-2`}>
            <figure style={{
                display: horizontalView ? 'block' : 'none',
            }} className={`figure m-0 ${horizontalView ? 'col-3' : ''}`}>
                <img src={item.productImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img img-fluid" alt="" />
            </figure>
            <div className={horizontalView ? 'col-6' : ''} onClick={(e) => {
                e.preventDefault();
                window.open(`${baseUrl}${item.searchEnginePageName}/${item.productCode}`, '_blank');
            }} style={{ cursor: "pointer" }}>
                <figure style={{
                    display: horizontalView ? 'none' : 'block',
                }} className='figure m-0'>
                    <img src={item.productImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img img-fluid" alt="" />
                </figure>
                <div className='d-flex justify-content-between'>
                    <figcaption className="figure-caption text-start mb-2 prduct_Category_brandName" >
                        {item.categoryName}
                    </figcaption>
                    <figcaption className="figure-caption text-start mb-2 prduct_Category_brandName" >
                        {item.brandName}
                    </figcaption>
                </div>
                <Link onClick={(e) => {
                    e.preventDefault();
                    window.open(`${baseUrl}${item.searchEnginePageName}/${item.productCode}`, '_blank');
                }} >
                    <Tooltip arrow title={item.productName} TransitionComponent={Zoom} placement="top-start">
                        <h6 className='productName'>{item.productName}</h6>
                    </Tooltip>
                </Link>
                <div className='align-items-baseline d-flex justify-content-between'>
                    <RatingStar productRating={item.productRating} />
                    {item.minCartQuantity && <span className="fsz-30 fw-medium text-capitalize text-warning" style={{ color: '#fab86a', fontSize: "10px" }}>
                        {
                            item.minCartQuantity > 1 ? `Minimum quantity ${item.minCartQuantity}`:''
                        }
                       
                    </span>}
                </div>
               
                <div className="product-item__price-list price-list mb-2">
                    <div className='align-items-baseline d-flex justify-content-between'>
                        <span className="price price--highlight">₹{item.sellingPrice}</span>
                        {item.isDisplayAvailability && <span className="fsz-30 fw-medium text-capitalize text-warning" style={{ color: '#fab86a', fontSize: "10px" }}>
                            Only {item.displayAvailabilityCount} left in stock
                        </span>}
                    </div>
                    <div>
                        {
                            item.discount >= '1.00' &&
                            <>
                                <span className="price price--compare me-2">₹{item.mrp}</span>
                                <span className='prod_discount'>({item.discountType === "FLAT" && "₹"} {item.discount === "0.0" ? '' : item.discount} {item.discountType === "PERCENTAGE" && "%"} Off)</span>
                            </>
                        }
                    </div>
                </div>
            </div>
            {horizontalView && <div className={`buttons-group d-flex d-md-block gap-2 ${horizontalView ? 'col-md-3' : ''}`}> {item.isOutOfStock === false ? <button className='bg-primary text-white py-2 mb-2 w-100 border-0 rounded mybtn' onClick={(e) => handleAddToCart(e, item)} >Add to cart</button>
                : <button className='bg-primary text-white py-2 mb-2 w-100 border-0 rounded disabledBTN'>Out Of Stock</button>}
                <button className={`bg-primary text-white py-2 w-100 mb-2 border-0 rounded mybtn ${horizontalView ? '' : 'd-none'}`} onClick={() => GetProductDetails(item.productCode)}>Quick View</button>
                {!item.isDisableWishlistBtn && wishListIcon ?
                    <button className={`bg-secondary text-white py-2 mb-2 w-100 border-0 rounded mybtn ${horizontalView ? '' : 'd-none'}`} onClick={(e) => handleRemoveFromWishList(e, item.productCode)}>Remove From WishList</button>
                    :
                    < button className={`bg-secondary text-white py-2 mb-2 w-100 border-0 rounded mybtn ${horizontalView ? '' : 'd-none'}`} onClick={(e) => handleAddWishlist(e, item.productCode)}>Add To Wishlist</button>}
            </div>}
            {!horizontalView ? (
                !item.isOutOfStock ? (
                    <button
                        className={`bg-primary text-white py-2 w-100 border-0 rounded mybtn`}
                        onClick={(e) => handleAddToCart(e, item)}
                    >
                        Add to cart
                    </button>
                ) : (
                    <button
                        className='bg-primary text-white py-2 w-100 border-0 rounded disabledBTN'
                    >
                        Out Of Stock
                    </button>
                )
            ) : (
                <>

                </>
            )}

            <div className="product_top_button">
                <ul className='list-unstyled m-0 p-0 d-flex gap-1'>
                    {!item.isDisableWishlistBtn &&
                        <li title='Favourite'>
                            <Fab
                                size="small"
                                sx={{ width: '30px', height: '30px', minHeight: '0' }}
                                color="white"
                                aria-label="add"
                            >
                                {wishListIcon ? (
                                    wishlistUrl === 'wishlist' ? (
                                        <Close
                                            onClick={(e) => handleRemoveFromWishListFromWishList(e, item.productCode)}
                                            sx={{ color: 'var(--primary)', width: '15px', height: '15px' }}
                                        />
                                    ) : (
                                        <Favorite
                                            onClick={(e) => handleRemoveFromWishList(e, item.productCode)}
                                            sx={{ color: 'var(--primary)', width: '15px', height: '15px' }}
                                        />
                                    )
                                ) : (
                                    <FavoriteBorderIcon
                                        onClick={(e) => handleAddWishlist(e, item.productCode)}
                                        sx={{ color: 'var(--primary)', width: '15px', height: '15px' }}
                                    />
                                )}
                            </Fab>
                        </li>
                    }
                    <li title='Quick View'>
                        <Fab size="small" sx={{ width: '30px', height: '30px', minHeight: '0' }} color="white" onClick={() => GetProductDetails(item.productCode)} aria-label="add">
                            <Fullscreen sx={{ color: 'var(--primary)', width: '15px', height: '15px' }} />
                        </Fab>
                    </li>
                </ul>
            </div>
            {item.isMarkAsNew && <div className='position-absolute newlaunch'>New Launch</div>}
        </div>
    );
};

export default Card;