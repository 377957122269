import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loginUser, loginUserWithNumber, setLoginPopupModal, setUser } from '../../features/authSlice'
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice'
import { AlertBox, ApiCall, setFocus } from '../Common/service'
import './login.css'



const LoginPopup = ({ isVisible, onClose }) => {

    const [loginWithOTP, setLoginWithOTP] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/';
    const [isDisableOTPInput, setIsDisableOTPInput] = useState(true);
    const [formData, setFormData] = useState({
        userCode: "",
        password: "",
        isRemember: false,
    });
    const [otpSent, setOtpSent] = useState(false);
    const [otpFormData, setotpFormData] = useState({
        mobile: "",
        otp: "",


    })
    const handleClear = () => {
        setotpFormData({
            mobile: "",
            otp: "",
        });

    }

    const dispatch = useDispatch()
    const { uniqueUserId, isAuthenticated } = useSelector((state) => state.authData)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // Handle Login 
    const HandleLoggedIn = async () => {
        if (
            !/^\d{10}$/.test(formData.userCode.replace(/-/g, '')) &&
            !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.userCode) &&
            formData.userCode === ""

        ) {
            toast.error('Please Enter a  Email / Mobile No');
            return;
        }
        const loginSubEquipment = {
            ...formData,
            CartToken: uniqueUserId ? uniqueUserId : uniqueUserId
        }

        // dispatch(loginUser(loginSubEquipment))
        await dispatch(loginUser(loginSubEquipment))
            .then(async (response) => {
                if (response.payload.status === 'SUCCESS') {
                    dispatch(GetOverAllCountAPI())
                    toast.success(response.payload.message)
                    navigate(from, { replace: true })
                    // navigate('/')
                } else if (response.payload.status === "ERROR") {
                    await localStorage.removeItem('authUser');

                    toast.error(response.payload.message)
                }
            })
            .catch((error) => {
                console.error('Error logging in:', error);
            });
    };

    // Handle OTP Sent
    const HandleOTPSent = async () => {
        //setOtpSent(true);

        let data = await ApiCall(`/api/Web/SendOTP`, { ...otpFormData, Type: "login" })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            await setIsDisableOTPInput(false)
            setOtpSent(true);
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message, data.focus);
            await setIsDisableOTPInput(true)
            await setUser({});
        }
    };
    // Handle OTP LOGIN
    const HandleOTPLoggedIn = async () => {
        const loginSubEquipment = {
            ...otpFormData,
            CartToken: uniqueUserId ? uniqueUserId : uniqueUserId
        }
        await dispatch(loginUserWithNumber(loginSubEquipment))
            .then(async (response) => {
                if (response.payload.status === "SUCCESS") {
                    dispatch(GetOverAllCountAPI())
                    toast.success(response.payload.message)
                    navigate(from, { replace: true })
                    // navigate('/')
                } else if (response.payload.status === 'ERROR') {
                    toast.error(response.payload.message)
                    await localStorage.removeItem('authUser');
                    setotpFormData({ ...otpFormData, otp: "" })
                    toast.error(response.payload.message)
                } else if (response.payload.status === "OTP Exipred Please Re-Send OTP") {
                    setOtpSent(false);
                }
            })
    };


    if (!isVisible) return null;


    return (
        <div className="modal-popup">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>

                <div className={"col-md-12 p-0 d-flex flex-column justify-content-between"}>
                    <div className="login-form-popup">
                        {!loginWithOTP ? (
                            <>
                                <h5>Log In Your Account</h5>
                                <div className='my-3'>
                                    <label className='mb-1'>Email / Mobile No</label>
                                    <input
                                        className='form-control'
                                        sx={{ width: '100%' }}
                                        id="txtUserCode"
                                        label="Email / Mobile No"
                                        type="text"
                                        required
                                        value={formData.userCode}
                                        onChange={(e) => setFormData({ ...formData, userCode: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (formData.userCode.trim() === '') {
                                                    e.preventDefault();
                                                    toast.error('Please Enter Email/Mobile No.');
                                                } else {
                                                    setFocus("txtPassword");
                                                }
                                            }
                                        }}
                                    />
                                    {/*<TextField*/}
                                    {/*    sx={{ width: '100%' }}*/}
                                    {/*    id="txtUserCode"*/}
                                    {/*    label="Email / Mobile No"*/}
                                    {/*    variant="standard"*/}
                                    {/*    type="text"*/}
                                    {/*    required*/}
                                    {/*    value={formData.userCode}*/}
                                    {/*    onChange={(e) => setFormData({ ...formData, userCode: e.target.value })}*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        if (e.key === 'Enter') {*/}
                                    {/*            if (formData.userCode.trim() === '') {*/}
                                    {/*                e.preventDefault();*/}
                                    {/*                toast.error('Please Enter Email/Mobile No.');*/}
                                    {/*            } else {*/}
                                    {/*                setFocus("txtPassword");*/}
                                    {/*            }*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>
                                <div className='mb-3 position-relative'>
                                    <label className='mb-1'>Password</label>
                                    <input
                                        className='form-control'
                                        id="txtPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData.password}
                                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (formData.password.trim() === '') {
                                                    e.preventDefault();
                                                    toast.error('Please Enter Your Password');
                                                } else {
                                                    setFocus("txtIsRemember");
                                                }
                                            }
                                        }}
                                    />
                                    <div className='position-absolute pop_eye'>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                    {/*<FormControl variant="standard" sx={{ width: '100%' }} required={true} >*/}
                                    {/*    <InputLabel htmlFor="txtPassword">Password</InputLabel>*/}
                                    {/*    <Input*/}
                                    {/*        id="txtPassword"*/}
                                    {/*        type={showPassword ? 'text' : 'password'}*/}
                                    {/*        value={formData.password}*/}
                                    {/*        onChange={(e) => setFormData({ ...formData, password: e.target.value })}*/}
                                    {/*        endAdornment={*/}
                                    {/*            <InputAdornment position="end">*/}
                                    {/*                <IconButton*/}
                                    {/*                    aria-label="toggle password visibility"*/}
                                    {/*                    onClick={handleClickShowPassword}*/}
                                    {/*                    onMouseDown={handleMouseDownPassword}*/}
                                    {/*                >*/}
                                    {/*                    {showPassword ? <Visibility /> : <VisibilityOff />}*/}
                                    {/*                </IconButton>*/}
                                    {/*            </InputAdornment>*/}
                                    {/*        }*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') {*/}
                                    {/*                if (formData.password.trim() === '') {*/}
                                    {/*                    e.preventDefault();*/}
                                    {/*                    toast.error('Please Enter Your Password');*/}
                                    {/*                } else {*/}
                                    {/*                    setFocus("txtIsRemember");*/}
                                    {/*                }*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</FormControl>*/}
                                </div>
                                <div className="form-check mt-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={formData.isRemember}
                                        onChange={(e) => setFormData({ ...formData, isRemember: e.target.checked })}
                                        id="txtIsRemember"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { HandleLoggedIn() };
                                        }}
                                    />
                                    <label className="form-check-label" for='txtIsRemember'> Remember me? </label>
                                </div>
                                <div className='mt-3'>
                                    <button onClick={() => HandleLoggedIn()} className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'>Login</button>
                                </div>
                                <div className='mt-3'>
                                    <button onClick={() => setLoginWithOTP(true)} className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'>Login By OTP</button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h5>Log In Your Account By OTP</h5>
                                <div className='my-3'>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="txtMobileNo"
                                        label="Email / Mobile Number"
                                        variant="standard"
                                        type="text"
                                        required
                                        value={otpFormData.mobile}
                                        onChange={(e) => setotpFormData({ ...otpFormData, mobile: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { setFocus("txtPassword") };
                                        }}
                                    />
                                </div>
                                {otpSent && (
                                    <div className='my-3'>
                                        <label>Enter 6 Digit OTP</label>
                                        <MuiOtpInput
                                            value={otpFormData.otp}
                                            length={6}
                                            onChange={(otp) => setotpFormData({ ...otpFormData, otp: otp })}
                                        />
                                        <p className='my-3 d-flex' >
                                            Didn't receive an OTP?
                                            <h6 onClick={HandleOTPSent} style={{ fontWeight: 'bold', cursor: "pointer", marginLeft: "7px" }}>Resend</h6>
                                        </p>
                                    </div>

                                )}
                                <div className='mt-3'>

                                    <button
                                        className={`bg-primary text-white py-2 w-100 border-0 rounded mybtn mt-3 ${otpFormData.mobile.length !== 10 ? "disabledBTN" : "mybtn"}`} disabled={otpFormData.mobile.length !== 10} onClick={() => otpSent ? HandleOTPLoggedIn() : HandleOTPSent()}>
                                        {otpSent ? 'Login' : 'Send OTP'}
                                    </button>
                                    <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn mt-3' onClick={() => {
                                        setLoginWithOTP(false)
                                        handleClear()
                                    }}>
                                        Login By Password
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="login-footer text-center mt-2">
                            <p>Don't Have an Account? <Link to='/signup' onClick={() => dispatch(setLoginPopupModal(false)) } className='text-primary'>Sign up now</Link></p>
                            <p>Forgot Password? <Link to='/reset-password' onClick={() => dispatch(setLoginPopupModal(false))} className='text-primary'>Reset</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPopup;
