import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Box, ButtonBase, Dialog, IconButton, Skeleton, Tab, Tabs, Tooltip, Typography, Zoom } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Add, Close, Facebook, LinkRounded, Mail, Remove, WhatsApp, X } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addToCartApi } from '../../features/addToCartAfterLogin';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { baseUrl } from '../../index';
import { EssentialSlider } from '../Sliders/Sliders';
import RequiredProductModal from './RequiredProductModal';
import { ApiCall } from './service';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Helmet } from 'react-helmet';



export const ProductDetails = () => {
    const navigate = useNavigate()
    let url = window.location.search;
    const queryString = new URLSearchParams(url);
    const ProductToken = queryString.get('ProductToken');
    let { productCode } = useParams();
    const ProductCode = productCode;
    const [loading, setLoading] = useState({
        fullProductDetails: false,
        customerReviews: false,
    });
    const [value, setValue] = useState(1);
    const [magnifyImage, setMagnifyImage] = useState('');
    const [CustomerReviews, setCustomerReviews] = useState([]);
    const [fullproductDetails, setFullProductDetails] = useState({});
    const [productReletedItems, setProductReletedItems] = useState([])
    const [viewListData, setViewlistData] = useState([]);
    const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
    const [fullProductDetailsLoading, setFullProductDetailsLoading] = useState(true)
    const { user } = useSelector((state) => state.authData)
    const RecentProducts = JSON.parse(localStorage.getItem('recentViewedProduct')) || []
    const [itemQuantity, setItemQuantity] = useState(1)
    const [shareOpen, setShareOpen] = useState(false)
    const shareDropDown = useRef(null);

    //for You May Also Like Section
    const GetProductReletedItems = async (ProductCode) => {
        let data = await ApiCall(`/api/Web/GetProductReletedItems`, {
            LoginUserCode: user.userCode ? user.userCode : '',
            ProductCode: ProductCode ? ProductCode : '',
            Token: user.authToken ? user.authToken : ''
        })
        if (data.status === 'SUCCESS') {
            await setProductReletedItems(data.products)

        } else if (data.status === 'ERROR') {
            await setProductReletedItems([])
        }
    }

  

    //for Product Details
    const GetFullProductDetails = async (ProductCode) => {
        setLoading(prev => ({ ...prev, fullProductDetails: true }))
        let data = await ApiCall(`/api/Web/ViewFullProductDetails`, {
            ProductCode: ProductCode ? ProductCode : '',
            ProductToken: ProductToken ? ProductToken : ''
        })
        if (data.status === 'SUCCESS') {
            await setFullProductDetails(data)
           // document.title = data.searchEnginePageName;
            await setFullProductDetailsLoading(false)
            setItemQuantity(data.minimumCartQty)
        } else if (data.status === 'ERROR') {
            await setLoading(prev => ({ ...prev, fullProductDetails: true }))
            await setFullProductDetailsLoading(false)
            await setFullProductDetails([])
        }
    }

    // Get Review List
    const GetReviewsList = async () => {
        setLoading(prev => ({ ...prev, customerReviews: true }))
        let data = await ApiCall(`/api/Web/GetReview`, { Product: ProductCode ? ProductCode : '' })
        if (data.status === 'SUCCESS') {
            await setCustomerReviews(data);
            await setLoading(prev => ({ ...prev, customerReviews: true }));
        } else if (data.status === 'ERROR') {
            await setCustomerReviews([]);
            await setLoading(prev => ({ ...prev, customerReviews: true }));
        }
    }

    // Get Recent Viewed Product List;
    const GetRecentViewProduct = async (ProductCodes) => {
        let data = await ApiCall(`/api/Web/GetRecentViewProduct`, {
            LoginUserCode: user.userCode ? user.userCode : '',
            ProductCode: ProductCodes ? ProductCodes.join(',') : "",
            Token: user.authToken ? user.authToken : ''
        })
        if (data.status === 'SUCCESS') {
            setViewlistData(data.products)
        } else if (data.status === 'ERROR') {
            setViewlistData([])
        }
    }
    const GetTodayDealsProduct = async (ProductCodes) => {
        let data = await ApiCall(`/api/Web/GetTodayDealsProduct`, {
            LoginUserCode: user.userCode ? user.userCode : '',
            ProductCode: ProductCodes ? ProductCodes.join(',') : "",
            Token: user.authToken ? user.authToken : ''
        })
        if (data.status === 'SUCCESS') {
            setViewlistData(data.products)
        } else if (data.status === 'ERROR') {
            setViewlistData([])
        }
    }
    const reviewDivRef = useRef(null);

    const handleViewReviewClick = () => {
        const spaceOnTop = 120;
        const reviewDiv = reviewDivRef.current;
        if (reviewDiv) {
            reviewDiv.focus();
            const topPosition = reviewDiv.getBoundingClientRect().top + window.pageYOffset - spaceOnTop;
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    };


    const videoData = fullproductDetails?.productImages?.filter?.(ele => ele.imageType === "Video");
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setMagnifyImage('')
        if (!RecentProducts?.includes(ProductCode)) {
            localStorage.setItem("recentViewedProduct", JSON.stringify([ProductCode, ...RecentProducts]))
        }
        let data = async () => {
            await GetProductReletedItems(ProductCode)
            await GetFullProductDetails(ProductCode);
        }
        data()
        GetReviewsList()
        GetRecentViewProduct(JSON.parse(localStorage.getItem('recentViewedProduct')) || []);
        GetTodayDealsProduct(JSON.parse(localStorage.getItem('TodayDealsProduct')) || [])
    }, [ProductCode])

    const mystyle = {
        objectFit: 'contain',
        height: 500,
    }

    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        // speed: 500,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 4,
        arrows: fullproductDetails?.productImages?.length > 4 ? true : false,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    rows: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                    arrows: false,
                    variableWidth: false,
                    rows: 1,
                    dots: true,
                    swipeToSlide: true,

                }
            },

        ]
    };

    //New Code...................
 
    const [showRequiredProductModal, setShowRequiredProductModal] = useState(false)
    const dispatch = useDispatch()
    const [, toggleQuickView] = useState(false)
    const { isAuthenticated } = useSelector((state) => state.authData)
    const productURL = window.location.href;




    const handleDecreseQuantity = async () => {
        if (Number(fullproductDetails.minimumCartQty) === Number(itemQuantity)) {
            await setItemQuantity(Number(fullproductDetails.minimumCartQty))
            await toast.error(`Minimum ${fullproductDetails?.minimumCartQty} Product to buy`)
        } else {
            await setItemQuantity(Number(itemQuantity) - 1)
        }
    }
    const handleIncreseQuantity = async () => {
        if (Number(fullproductDetails.maximumCartQty) === Number(itemQuantity)) {
            await setItemQuantity(Number(fullproductDetails.maximumCartQty))
            await toast.error(`Allow Only ${fullproductDetails?.maximumCartQty} Product in Cart`)
        } else {
            await setItemQuantity(Number(itemQuantity) + 1);
        }
    }

    const handleAddToCart = (item, itemQuantity) => {
        if (item.requiredOtherProducts?.length) {
            setShowRequiredProductModal(true)
        } else {
            dispatch(addToCartApi({
                productCode: item.productCode,
                requiredOtherProducts: [],
                itemQuantity,
                Source: "ProductDetails",
            }))
                .then(async (response) => {
                    toast.success(response.payload.message)
                    dispatch(GetOverAllCountAPI())
                    setShowRequiredProductModal(false)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        }
    }

    const handleBuyNow = (item, itemQuantity) => {
        if (isAuthenticated) {

            const filteredData = [
                {
                    productCode: item.productCode,
                    Quantity: itemQuantity.toString(),
                    requiredOtherProducts: [],
                }
            ]

            localStorage.setItem('CartItemDetails', JSON.stringify(filteredData))
            navigate('/checkout')

        } else {
            navigate('/login')
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (shareDropDown.current && !shareDropDown.current.contains(event.target)) {
                setShareOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [shareOpen]);


    if (showRequiredProductModal) {
        return <RequiredProductModal
            Source={'ProductDetails'}
            showRequiredProductModal={showRequiredProductModal}
            setShowRequiredProductModal={setShowRequiredProductModal}
            Product={fullproductDetails}
            toggleQuickView={toggleQuickView}
        />
    }

    if (fullproductDetails?.status === "ERROR") {
        return (
            <div style={{ height: "51vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h2 className='text-center p-3'> This Product Details is not Available.</h2>
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title>{fullproductDetails?.metaTitle}</title>
                <meta name="keywords" content={fullproductDetails?.metaKeywords} />
            </Helmet>
            {
                !fullProductDetailsLoading ? (
                    <>

                        <section className='pt-2 product_details'>
                            <div className="container">
                                <nav className="breadcrumb align-items-center">
                                    <Link className="breadcrumb-item" to='/'>Home</Link>
                                    <span>/</span>
                                    <Link className="breadcrumb-item active" to='/products'>Products</Link>
                                </nav>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="product_gallary sticky-lg-top" key='ProductImages'>

                                            <div className='row'>
                                                <div className='col-xxl-2 col-md-3'>
                                                    <div className="thumb_image">
                                                        <Slider {...settings}>
                                                            {fullproductDetails?.productImages?.map((imgPath, index) =>
                                                                <div key={`ProductImage_${index}_key`}>
                                                                    {imgPath.imageType === "Image" ?
                                                                        < img src={imgPath.productImageUrl}
                                                                            onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }}
                                                                            alt={`ProductImage_${index}`}
                                                                            onClick={() => setMagnifyImage(imgPath.productImageUrl)}
                                                                            style={{ cursor: 'pointer' }} />
                                                                        :
                                                                        < img src='./images/Video.png'
                                                                            alt={`ProductVideo_${index}`}
                                                                            onClick={async () => {
                                                                                await setValue(1);
                                                                                await setShowImageGalleryModal(true)
                                                                                await setMagnifyImage(imgPath)
                                                                            }}
                                                                            style={{ cursor: 'pointer' }} />
                                                                    }
                                                                </div>
                                                            )}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                {/*const emailLink = `mailto:?subject=${encodeURIComponent(productTitle)}&body=${encodeURIComponent(productURL)}`;*/}

                                                <div className='col-xxl-10 col-md-9 d-none d-md-block'>
                                                    <div className="large_image" onClick={async () => {
                                                        await setValue(0);
                                                        await setShowImageGalleryModal(true);


                                                    }} >
                                                        {/* image should be 500 x 500 pixels*/}
                                                        {magnifyImage?.imageType === "Video" ?
                                                            <iframe
                                                                width="100%"
                                                                height="100%"
                                                                src={magnifyImage?.productImageUrl}
                                                                onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }}
                                                                title="YouTube video player"
                                                                frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                referrerpolicy="strict-origin-when-cross-origin"
                                                                allowfullscreen></iframe>
                                                            : <ReactImageMagnify className="l_img_container" imageClassName="l_img_simg" lensStyle={{ width: '100px', height: '100px' }} {...{
                                                                smallImage: {
                                                                    alt: 'MagnifiedImage',
                                                                    imageStyle: [mystyle],
                                                                    isFluidWidth: true,
                                                                    onError: async (e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = './images/Image_not_available.png';
                                                                    },

                                                                    src: `${magnifyImage ? magnifyImage : fullproductDetails?.productImages ? fullproductDetails?.productImages.find(image => image.isPrimaryImage === true)?.productImageUrl : './images/Image_not_available.png'}`
                                                                },
                                                                largeImage: {

                                                                    src: `${magnifyImage ? magnifyImage : fullproductDetails?.productImages ? fullproductDetails?.productImages.find(image => image.isPrimaryImage === true)?.productImageUrl : './images/Image_not_available.png'}`,
                                                                    width: 1200,
                                                                    height: 1200,

                                                                }
                                                            }} />
                                                        }
                                                    </div>
                                                    <div ref={shareDropDown} className="share_btn position-absolute">
                                                        <Tooltip title="Share" placement="top" onClick={() => setShareOpen(!shareOpen)}>
                                                            <IconButton>
                                                                {
                                                                    shareOpen ? <CloseIcon /> : <IosShareIcon />
                                                                }
                                                                
                                                            </IconButton>
                                                        </Tooltip>
                                                        <div className={`dropdown ${shareOpen ? 'd-block' : 'd-none'}`}>
                                                            <ul>
                                                                <li onClick={() => window.open(`mailto:?subject=${encodeURIComponent(fullproductDetails?.productName)}&body=${encodeURIComponent(productURL)}`, "_blank", "noopener,noreferrer")}><Mail className='me-2 text-danger ' /> E-mail</li>
                                                                <li onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(fullproductDetails?.productName)}%0A${productURL}`, "_blank", "noopener,noreferrer")}><WhatsApp className='me-2 text-success' /> Whatsapp</li>
                                                                <li onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productURL)}`, "_blank", "noopener,noreferrer")}><Facebook className='me-2' sx={{ color: "#1877F2"} }  /> Facebook</li>
                                                                <li onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(fullproductDetails?.productName)}%0A${encodeURIComponent(productURL)}`, "_blank", "noopener,noreferrer")}><X className='me-2 text-dark' /> X</li>
                                                                <li onClick={() => 
                                                                    navigator.clipboard.writeText(productURL)
                                                                        .then(() => {
                                                                            toast.success("Link Copied!");
                                                                        })
                                                                        .catch((error) => {
                                                                            toast.error("Some error occured, please try after some time.");
                                                                        })
                                                                }><LinkRounded className='me-2 text-dark' /> Copy Link</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                            

                                        </div>
                                    </div>

                                    {/* left side */}
                                    <div className="col-lg-6 mt-5 mt-md-0">
                                        <div className="pd_info">
                                            <div className="border-bottom pb-3">
                                                <figcaption className="figure-caption text-start mb-2">
                                                    {fullproductDetails?.categoryName}
                                                </figcaption>
                                                <h6 className='head mb-0'>{fullproductDetails.productName}</h6>
                                                {fullproductDetails?.soldBy && <p className='p-0 my-1'>Sold By : <span className='fw-bold  prod_discount'>{fullproductDetails?.soldBy}</span> </p>}
                                                {/* Rating which is toggle to bottom rating section */}
                                                {CustomerReviews?.reviewList?.length > 0 ?
                                                    <div className="rating_link d-flex gap-2">
                                                        <div>  {(() => {
                                                            const stars = [];
                                                            const rating = parseFloat(CustomerReviews.ratingAverage);
                                                            const integerPart = Math.floor(rating);
                                                            const decimalPart = rating - integerPart;
                                                            for (let i = 0; i < 5; i++) {
                                                                if (i < integerPart) {
                                                                    stars.push(<i key={i} className='fa-solid fa-star text-warning'></i>);
                                                                } else if (i === integerPart && decimalPart > 0) {
                                                                    stars.push(<i key={i} className='fa-solid fa-star-half-alt text-warning'></i>);
                                                                } else {
                                                                    stars.push(<i key={i} className="fa-regular fa-star text-warning"></i>);
                                                                }
                                                            }
                                                            return stars;
                                                        })()}
                                                        </div>
                                                        <span>{CustomerReviews?.ratingAverage ? CustomerReviews.ratingAverage : '0'} Ratings & {CustomerReviews?.reviewList ? CustomerReviews?.reviewList?.length : '0'} Reviews</span>


                                                        {CustomerReviews?.reviewList?.length > 0 && <button className='border-0 bg-secondary text-white' onClick={handleViewReviewClick}>View Review</button>}
                                                    </div>
                                                    : <span> <i className="fa-regular fa-star text-warning"></i> Purchase this item to give a first Review</span>}
                                            </div>

                                            {/* Price section */}

                                            <div className="product-item__price-list price-list my-2">
                                                {fullproductDetails.isMarkAsNew && <div className='newlaunch d-inline px-2 py-1 shadow-sm'>New Launch</div>}
                                                <div className='mt-2'>
                                                    <span className="price price--highlight">₹{fullproductDetails?.sellingPrice}</span>
                                                    {
                                                        fullproductDetails.discount >= '1.00' &&
                                                        <span className="price price--compare">₹{fullproductDetails?.mrp}</span>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        fullproductDetails.discount >= '1.00' &&
                                                        <span className='prod_discount me-2'>({fullproductDetails.discountType === "FLAT" && "₹"} {fullproductDetails.discount}{fullproductDetails.discountType === "PERCENTAGE" && "%"} Off )</span>
                                                    }
                                                    <span>Inclusive of all taxes</span>
                                                </div>
                                            </div>

                                            {/* select Verient options */}
                                            <div className='select_colors mb-3'>
                                                <p className='fw-bold mb-2'>{fullproductDetails?.productVarrients?.length > 0 ? 'More Variant' : ''}</p>
                                                <div className='d-flex flex-wrap gap-1' >
                                                    {fullproductDetails && fullproductDetails?.productVarrients?.length > 0 && fullproductDetails?.productVarrients?.map((varrients, index) =>
                                                        <div className='custom_open_link' key={`varrients_${varrients.productCode}_${index}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open(`${baseUrl}${fullproductDetails.searchEnginePageName}/${varrients.productCode}`, '_blank');
                                                            //  window.open(`/products-details?productCode=${varrients.productCode}`, '_blank');
                                                        }}>
                                                            <div className={`bg-transparent rounded verrientItem ${ProductCode === varrients.productCode ? 'active' : ''}`} style={{ cursor: 'pointer' }}>
                                                                <Tooltip arrow title={varrients.productName} TransitionComponent={Zoom} placement="top-start">
                                                                    <img src={varrients.primaryImageUrl} alt={`VerrientImg_${index}`} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            </div>

                                            {/* Quentity  */}
                                            <div className="add_count me-4">
                                                <IconButton onClick={handleDecreseQuantity}><Remove fontSize='small' /></IconButton>
                                                <span>{itemQuantity}</span>
                                                <IconButton onClick={handleIncreseQuantity}><Add fontSize='small' /></IconButton>
                                                {fullproductDetails?.packs > "0" ?
                                                    <p className='m-0 ms-3 mt-2 text-nowrap'>(Packs of {fullproductDetails?.packs})</p> : <></>
                                                }
                                            </div>

                                            <div className="product_add_count border-0 d-flex align-items-center gap-2 m-0" >
                                                {fullproductDetails?.isOutOfStock ?
                                                    <ButtonBase disabled> <button className='bg-primary text-white buybtn border-0 rounded disabledBTN'>Out Of Stock</button> </ButtonBase>
                                                    :
                                                    <ButtonBase onClick={() => handleAddToCart(fullproductDetails, itemQuantity)}>
                                                        <button className={`bg-primary text-white border-0 rounded addbtn mybtn `} >Add to cart</button>
                                                    </ButtonBase>}

                                                {fullproductDetails?.isOutOfStock || fullproductDetails?.isDisableBuyBtn ?

                                                    <ButtonBase disabled >
                                                        <button
                                                            className={`bg-secondary disabled text-white border-0 rounded buybtn disabledBTN`}>Buy Now</button>
                                                    </ButtonBase>
                                                    :
                                                    <ButtonBase onClick={() => handleBuyNow(fullproductDetails, itemQuantity)} >
                                                        <button
                                                            className={`bg-secondary disabled text-white border-0 rounded buybtn  mybtn`}>Buy Now</button>
                                                    </ButtonBase>
                                                }

                                            </div>

                                            {fullproductDetails?.orderDelivery && <div className="delivert_pr">
                                                <img src="./images/icons/delivery-truck.svg" width='30' className='me-2' alt="" />
                                                <span>{fullproductDetails?.orderDelivery}</span>
                                            </div>}

                                            {/* Available offers */}
                                            {/*{fullproductDetails?.availableOffer && <div className="availableoffers mt-2 mb-3">*/}
                                            {/*    <div className="ao_heading fs-6 fw-bold mb-1">*/}
                                            {/*        Available offers*/}
                                            {/*    </div>*/}
                                            {/*    <div className="offers">*/}
                                            {/*        <p className='m-0'>{fullproductDetails?.availableOffer}</p>*/}
                                            {/*        */}{/*<p className='m-0'>20% off on purchase above Rs 2000. Use code <b>ICW20</b> to get discount.</p>*/}
                                            {/*    </div>*/}
                                            {/*</div>}*/}

                                            {/* icon details */}

                                            <div className="icon_details p-3 bg-light d-block d-md-flex justify-content-between">
                                                <div className="d-flex mb-4 mb-md-0">
                                                    <div className="flex-shrink-0">
                                                        <img src="./images/icons/shipping.svg" alt="" width="45" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="mt-0">Shipping Cost</h6>
                                                        {fullproductDetails?.isFreeShipping ?
                                                            <p className="m-0">Free Shipping</p> : <p className='m-0'>{fullproductDetails?.shippingCharge}</p>}

                                                    </div>
                                                </div>
                                                <div className="d-flex mb-4 mb-md-0">
                                                    <div className="flex-shrink-0">
                                                        <img src="./images/icons/return.svg" alt="" width="45" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        {
                                                            fullproductDetails?.isNotReplaceable === true && fullproductDetails?.isNotReturnable === true ? (
                                                                <h6 className="mt-0">Replace & Return applicable</h6>
                                                            ) : fullproductDetails?.isNotReplaceable === true ? (
                                                                <h6 className="mt-0">Replaceable</h6>
                                                            ) : fullproductDetails?.isNotReturnable === true ? (
                                                                <h6 className="mt-0">Returnable</h6>
                                                            ) : (
                                                                <h6 className="mt-0">Replace & Return not applicable</h6>
                                                            )
                                                        }
                                                        <p className="m-0">
                                                            {fullproductDetails?.isNotReplaceable && fullproductDetails?.replaceableDays && (
                                                                <>
                                                                    We have {fullproductDetails?.replaceableDays} Day's Replace Policy
                                                                    {fullproductDetails?.isNotReturnable && fullproductDetails?.returnableDays && " & "}
                                                                </>
                                                            )}

                                                            {fullproductDetails?.isNotReturnable && fullproductDetails?.returnableDays && (
                                                                <>
                                                                    We have {fullproductDetails?.returnableDays} Day's Return Policy
                                                                </>
                                                            )}

                                                            {!fullproductDetails?.isNotReplaceable && !fullproductDetails?.isNotReturnable && (
                                                                "No Replace & Return Policy"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-4 mb-md-0">
                                                    <div className="flex-shrink-0">
                                                        <img src="./images/icons/secure.svg" alt="" width="45" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="mt-0">Secure Payment</h6>
                                                        <p className='m-0'>Guarantee secure payments</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fs-5 fw-bold mb-1">
                                                Details
                                            </div>
                                            <p>
                                                {fullproductDetails?.productDetails?.map((item, index) => <>
                                                    <p className='p-0 m-0 row' key={`ProductDetails_List_${index}`}>
                                                        <span className='col-3 d-flex justify-content-between' >
                                                            <b>{item.key}</b>
                                                            <span>:</span>
                                                        </span>
                                                        <span className='col-9'>{item.value}</span></p>
                                                </>)}
                                            </p>
                                            <p>
                                                {fullproductDetails?.productProperty?.map((item, index) =>
                                                    <p className='p-0 m-0 row' key={`ProductProperty_List_${index}`}>
                                                        <span className='col-3 d-flex justify-content-between' >
                                                            <b>{item.propertyName}</b>
                                                            <span>:</span>
                                                        </span>
                                                        <span className='col-9'>{item.propertyValue}</span></p>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {fullproductDetails?.fullDescription && <section style={{ marginTop: "50px" }} className='container py-0'>
                            {fullproductDetails?.fullDescription && <div style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fullproductDetails?.fullDescription) }} />}
                        </section>}

                        {productReletedItems && productReletedItems.length > 0 && < section className='' style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="container">
                                <div className="heading mb-4 text-center">
                                    <h4><b className='text-theme'>You May</b> Also Like</h4>
                                </div>
                                <EssentialSlider
                                    Source={'ProductDetails-alsolike-Section'}
                                    ProductsList={productReletedItems ? productReletedItems : []}
                                />
                            </div>
                        </section>}
                        {viewListData.length > 0 && <section className='mt-0' style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="container">
                                <div className="heading mb-4 text-center">
                                    <h4><b className='text-theme'>Recent</b> Viewed</h4>
                                </div>
                                <EssentialSlider
                                    Source={'ProductDetails-RecentViewd-Section'}
                                    ProductsList={viewListData ? viewListData : []}
                                />
                            </div>
                        </section>}
                        {CustomerReviews?.reviewList?.length > 0 &&
                            <div className="container" id="reviewDiv"
                                ref={reviewDivRef}>
                                <div className="cust_review py-3 py-md-4">
                                    <div className="row">
                                        <div className="col-md-4 col-xl-3">
                                            <div className="fs-5 fw-bold mb-1">
                                                Rating
                                            </div>
                                            <div className="stars mb-3">
                                                {(() => {
                                                    const stars = [];
                                                    const rating = parseFloat(CustomerReviews.ratingAverage);
                                                    const integerPart = Math.floor(rating);
                                                    const decimalPart = rating - integerPart;
                                                    for (let i = 0; i < 5; i++) {
                                                        if (i < integerPart) {
                                                            stars.push(<i key={i} className='fa-solid fa-star text-warning'></i>);
                                                        } else if (i === integerPart && decimalPart > 0) {
                                                            stars.push(<i key={i} className='fa-solid fa-star-half-alt text-warning'></i>);
                                                        } else {
                                                            stars.push(<i key={i} className="fa-regular fa-star text-warning"></i>);
                                                        }
                                                    }
                                                    return stars;
                                                })()}
                                                <span className='ms-2 fs-6'>{CustomerReviews.ratingAverage} out of 5</span>
                                            </div>
                                            <div className="table-responsive" >
                                                <table className="table table-sm table-borderless" >
                                                    <tbody>
                                                        <tr className="">
                                                            <td style={{ width: '45px' }}>5 <span><i className='fa-solid fa-star text-secondary' style={{ width: '10px' }}></i></span></td>
                                                            <td style={{ width: '185px' }}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${CustomerReviews.rating5}%` }} ariaValuenow={CustomerReviews.rating4} ariaValuemin="0" ariaValuemax="100"></div>
                                                                </div>
                                                            </td>
                                                            <td>{CustomerReviews.ratingCount5}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td style={{ width: '45px' }}>4 <span><i className='fa-solid fa-star text-secondary' style={{ width: '10px' }}></i></span></td>
                                                            <td style={{ width: '185px' }}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${CustomerReviews.rating4}%` }} ariaValuenow={CustomerReviews.rating4} ariaValuemin="0" ariaValuemax="100"></div>
                                                                </div>
                                                            </td>
                                                            <td>{CustomerReviews.ratingCount4}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td style={{ width: '45px' }}>3 <span><i className='fa-solid fa-star text-secondary' style={{ width: '10px' }}></i></span></td>
                                                            <td style={{ width: '185px' }}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${CustomerReviews.rating3}%` }} ariaValuenow={CustomerReviews.rating3} ariaValuemin="0" ariaValuemax="100"></div>
                                                                </div>
                                                            </td>
                                                            <td>{CustomerReviews.ratingCount3}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td style={{ width: '45px' }}>2 <span><i className='fa-solid fa-star text-secondary' style={{ width: '10px' }}></i></span></td>
                                                            <td style={{ width: '185px' }}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${CustomerReviews.rating2}%` }} ariaValuenow={CustomerReviews.rating2} ariaValuemin="0" ariaValuemax="100"></div>
                                                                </div>
                                                            </td>
                                                            <td>{CustomerReviews.ratingCount2}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td style={{ width: '45px' }}>1 <span><i className='fa-solid fa-star text-secondary' style={{ width: '10px' }}></i></span></td>
                                                            <td style={{ width: '185px' }}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${CustomerReviews.rating1}%` }} ariaValuenow={CustomerReviews.rating1} ariaValuemin="0" ariaValuemax="100"></div>
                                                                </div>
                                                            </td>
                                                            <td>{CustomerReviews.ratingCount1}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-xl-9">
                                            <CustomerReviewsComponent CustomerReviews={CustomerReviews} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                ) : (
                    <section className='pt-2 product_details'>
                        <div className="container">
                            <nav className="breadcrumb align-items-center">
                                <Skeleton animation="wave" width='20%' height="30px"></Skeleton>
                            </nav>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="product_gallary sticky-lg-top" key='ProductImages'>
                                        <div className='row'>
                                            <div className='col-xxl-2 col-md-3'>
                                                <>
                                                    <div className="mb-2">
                                                        <Skeleton height="130px"></Skeleton>
                                                    </div>
                                                    <div className="mb-2">
                                                        <Skeleton height="130px"></Skeleton>
                                                    </div>
                                                    <div className="mb-2">
                                                        <Skeleton height="130px"></Skeleton>
                                                    </div>
                                                </>
                                            </div>
                                            <div className='col-xxl-10 col-md-9 d-none d-md-block'>
                                                <Skeleton width='100%' height='100%'></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* left side */}
                                <div className="col-lg-6 mt-5 mt-md-0">
                                    <div className="pd_info">
                                        <div className="border-bottom pb-3">
                                            <figcaption className="figure-caption text-start mb-2">
                                                <Skeleton width='90px'></Skeleton>
                                            </figcaption>
                                            <h6 className='head mb-0'> <Skeleton height='60px'></Skeleton></h6>
                                            <p className='p-0 my-1'>
                                                <Skeleton width="20vw"></Skeleton>
                                            </p>
                                            {/* Rating which is toggle to bottom rating section */}
                                            <Skeleton width="30vw"></Skeleton>
                                        </div>

                                        {/* Price section */}
                                        <div className="product-item__price-list price-list my-2">
                                            <Skeleton width='60px'></Skeleton>
                                            <div className='mt-2'>
                                                <span className="price price--highlight"><Skeleton width='30%'></Skeleton></span>
                                            </div>
                                        </div>

                                        {/* select Verient options */}
                                        <div className='select_colors mb-3'>
                                            <p className='fw-bold mb-2'>
                                                <Skeleton width='100px'></Skeleton>

                                            </p>
                                            <div className='d-flex flex-wrap gap-1'  >
                                                <div className={`bg-transparent rounded verrientItem`} >
                                                    <Skeleton height='100%'></Skeleton>
                                                </div><div className={`bg-transparent rounded verrientItem`} >
                                                    <Skeleton height='100%'></Skeleton>
                                                </div><div className={`bg-transparent rounded verrientItem`} >
                                                    <Skeleton height='100%'></Skeleton>
                                                </div><div className={`bg-transparent rounded verrientItem`} >
                                                    <Skeleton height='100%'></Skeleton>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Quentity  */}
                                        < div className="add_count me-4">
                                            <Skeleton width='100%'></Skeleton>
                                        </div>

                                        {/* add to cart button */}
                                        <div className="product_add_count border-0 d-flex align-items-center gap-2 m-0" >
                                            <Skeleton width='30%' height='50px'></Skeleton>
                                            <Skeleton width='30%' height='50px'></Skeleton>
                                        </div>

                                        <Skeleton width='50%'></Skeleton>

                                        {/* Available offers */}
                                        <div className="availableoffers mt-2 mb-3">
                                            <div className="ao_heading fs-6 fw-bold mb-1">
                                                <Skeleton width='20%'></Skeleton>
                                            </div>
                                            <div className="offers">
                                                <p className='m-0'> <Skeleton width='50%'></Skeleton></p>
                                            </div>
                                        </div>

                                        {/* icon details */}
                                        <Skeleton height='120px'></Skeleton>

                                        {/* Description */}
                                        <div className="description my-4 p-3 border">
                                            <Skeleton height='380px' width='550px'></Skeleton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
            <Dialog
                open={showImageGalleryModal}
                fullWidth={true}
                maxWidth='lg'
                aria-labelledby="responsive-dialog-title">
                <div className='ImageGallery'>
                    <h4 className='p-2'>Product Images</h4>
                    <div className="close_btn">
                        <IconButton onClick={() => setShowImageGalleryModal(false)}>
                            <Close fontSize='small' />
                        </IconButton>
                    </div>
                    <div className='col-12 border'>
                        <Tabs value={value} onChange={handleTabChange} aria-label="icon label tabs example">
                            <Tab icon={<ImageIcon />} label="Image" />
                            {fullproductDetails?.productImages?.map(ele => ele.imageType)?.includes("Video") ? < Tab icon={<OndemandVideoIcon />} label="Video" /> : <></>}
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <div className=''>
                                <div className=' '>
                                    <div className=''>
                                        {loading.fullProductDetails ?
                                            <div className=" ">
                                                <Swiper pagination={true} navigation={true} modules={[Pagination, Navigation]} className="product_full_view">
                                                    {fullproductDetails?.productImages?.filter?.(ele => ele.imageType !== "Video").map((imgPath, index) =>
                                                        <SwiperSlide key={`SwiperSlide_${index}`} center>
                                                            <div className="text-center" style={{ cursor: 'pointer' }}>
                                                                <img
                                                                    src={imgPath.productImageUrl ? imgPath.productImageUrl : './images/Image_not_available.png'}
                                                                    className=' '
                                                                    alt=''
                                                                    onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} />

                                                            </div>
                                                        </SwiperSlide>
                                                    )}
                                                </Swiper>
                                            </div> : <></>}
                                    </div>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className=''>
                                <div className=''>
                                    {videoData?.length > 0 ? (
                                        <div className=''>
                                            <Swiper pagination={true} navigation={true} modules={[Pagination, Navigation]} className="product_full_view">
                                                {videoData && videoData.map((video, index) =>
                                                    <SwiperSlide key={`SwiperSlide_${index}`} center>
                                                        {video.imageType === 'Video' &&
                                                            <div className="text-center swiper-video-container">
                                                                <iframe

                                                                    src={video.productImageUrl}
                                                                    title="Embedded Video"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                    onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }}>
                                                                </iframe>
                                                            </div>
                                                        }
                                                    </SwiperSlide>
                                                )}
                                            </Swiper>
                                        </div>
                                    ) : (
                                        <h4>No Video Available</h4>
                                    )}
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </Dialog>
           
        </>

    )
}


const CustomerReviewsComponent = ({ CustomerReviews }) => {
    const [visibleReviews, setVisibleReviews] = useState(2);
    const reviewsPerPage = 5;

    const handleSeeMore = () => {
        setVisibleReviews(prev => prev + reviewsPerPage);
    };

    return (
        <div className="customer_info">
            <div className="fs-5 fw-bold mb-3">Customer Reviews</div>
            {CustomerReviews?.reviewList.slice(0, visibleReviews).map((review, index) => (
                <div className="d-flex align-items-baseline gap-2 bg-light p-3 rounded" key={`CustomerReviews${index}`}>
                    <span>{review.rating} <i className='fa-solid fa-star text-dark ms-2'></i></span>
                    <div>
                        <p className='mb-0 fs-6 fw-bold'>{review.reviewTitle}</p>
                        <div className="cust_name">{review.reviewBy} | {review.reviewDate}</div>
                        <p>{review.reviewRemarks}</p>
                    </div>
                </div>
            ))}
            {visibleReviews < CustomerReviews.reviewList.length && (
                <>
                    <div onClick={handleSeeMore} style={{
                        padding: "1rem",
                        color: "#28a892",
                        fontWeight: "600",
                        fontSize: "16px",
                        cursor: "pointer"
                    }} className='review_seemore_txt'>See More...</div>
                </>
            )}
        </div>
    );
};


