import { Skeleton, Tooltip, Zoom } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Card from '../Common/Card';

export const EssentialSlider = ({ Source, ProductsList }) => {

    let settings = {
        dots: false,
        //infinite: ProductsList?.length <=5 ? false :true ,
        arrows: ProductsList?.length <= 5 ? false : true,
        infinite: false,
        autoplay: false,
        speed: 500,
        loop: true,
        nav: true,
        centerPadding: "60px",
        //slidesToShow: ProductsList?.length === 5 ? 5 : ProductsList?.length === 4 ? 4 : ProductsList?.length === 3 ? 3 : ProductsList?.length === 2 ? 2 : ProductsList?.length === 1 ? 1 : 5,
        slidesToShow: 5,
        swipeToSlide: true,
        slidesToScroll: 1,
        rows: 1,
        //   rows: 1,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    return (
        <>
            <div className='also_like'>
            <Slider {...settings}>
                {ProductsList.length > 0 && ProductsList?.map((item, index) =>
                    <div className='px-2'>
                        <Card Source={Source} item={item} index={index} />
                    </div>
                )}
            </Slider>
            </div>

        </>
    );
}

export const FeaturedSlider = ({ Source, section, sliderDataList}) => {

    let settings = {
        dots: false,
        infinite: sliderDataList?.length < 5 ? false : true,
       // infinite: sliderDataList.length % 5 === 0 ? true : false,
        arrows: sliderDataList?.length <= 5 ? false : true,
        autoplay: sliderDataList?.length <= 5 ? false : true,
        speed: 500,
        nav: true,
        centerPadding: "60px",
        // slidesToShow: sliderDataList.length === 5 ? 5 : sliderDataList.length === 4 ? 5 : sliderDataList.length === 3 ? 3 : sliderDataList.length === 2 ? 2 : sliderDataList.length === 1 ? 1 : 5,
       // swipeToSlide: sliderDataList.length % 5 === 0 ? true : false,
        swipeToSlide: false,
        slidesToScroll: 1,
        slidesToShow: 5,
        slidesPerRows: 1,
        rows: section?.noOfRows ? Number(section?.noOfRows) : 1,
        //  rows: 1,
        draggable : false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    return (
        <>
            {section.isScrollable ?
                <Slider {...settings}>
                    {sliderDataList.length > 0 && sliderDataList.map((item, index) => {
                        return (
                            <>
                                <div className='px-2' key={index} >
                                    <Card Source={Source } item={item} index={index} />                                   
                                </div>
                            </>
                        )
                    })}
                </Slider>
                : <div className="product_list">
                    {section.sectionImages.map((item, index) =>
                        <Card Source={Source} item={item} index={index} />  
                    )}
                </div>
            }       
        </>
    );
}

export const Brands = ({ TopBrandDataList, loading = false }) => {
    const settings = {
        dots: false,
        infinite: TopBrandDataList.length < 8 ? false : true,
        //  infinite: true,
        autoplay: TopBrandDataList?.length < 8 ? false : true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        //loop: true,
        loop: TopBrandDataList.length < 8 ? false : true,
        nav: false,
        slidesToShow: 8,
        //  slidesToShow: TopBrandDataList?.length < 8 ? TopBrandDataList?.length < 4 ? 4 : TopBrandDataList?.length : 8,
        arrows: false,
        swipeToSlide: TopBrandDataList?.length < 8 ? false : true,
        draggable: TopBrandDataList?.length < 8 ? false : true,
        slidesToScroll: 1,
        //   rows: section?.noOfRows ? Number(section?.noOfRows) : 1,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="slider-container">
            
            <Slider {...settings}>
                {TopBrandDataList?.map((item, index) =>
                    <div key={`TopBrand_${index}`}>

                        <div className="p-0" style={{ border: '2px solid #f8f8f8' }}>
                            <Link to={`/products?brand=${item.brandName}`} >
                                <div className="brand_img">
                                    <img src={item.brandImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img m-0 rounded" alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>
                )}

            </Slider>


        </div>
    );
}

export const Categories = ({ catlist, loading }) => {
    return (
        <div className="slider-container">
            <Swiper
                slidesPerView={8}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    300: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                    1200: {
                        slidesPerView: 8,
                    },
                }}
                className="mySwiper"
            >
                {loading ? catlist?.popularCategories?.map((item, categoryNameIndex) =>
                    <SwiperSlide key={categoryNameIndex}>
                        <div className="categories_list">
                            <Link className="d-block" to={`/products?category=${item.categoryName}`} >
                                <div className="categories_img">
                                    <img src={item.categoryImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img w-100 h-100 m-0 rounded" alt="" />
                                </div>
                                <Tooltip arrow title={item.categoryName} TransitionComponent={Zoom} placement="bottom">
                                    <p className='m-0 pt-3 productName'>{item.categoryName}</p>
                                </Tooltip>
                            </Link>
                        </div>
                    </SwiperSlide>
                ) :
                    <>
                        {(() => {
                            const slides = [];
                            for (let i = 0; i < 8; i++) {
                                slides.push(
                                    <SwiperSlide key={i}>
                                        <div className="categories_list">
                                            <div className="categories_img mb-2">
                                                <Skeleton sx={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <Skeleton width='80%' />
                                        </div>
                                    </SwiperSlide>
                                );
                            }
                            return slides;
                        })()}
                    </>

                }
            </Swiper>
        </div>
    );
}






