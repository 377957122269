import { CloseOutlined, FavoriteBorder, MenuOutlined, SearchOutlined, ShoppingCart } from '@mui/icons-material';
import { Badge, Divider, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { GetCatagoryData } from '../../features/CommonApiSlice';
import {  logoutUser } from '../../features/authSlice';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { ApiCall } from '../Common/service';
import './NavMenu.css';
import SideDrawer from './SideDrawer';


export const NavMenu = () => {
    const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { wishListCount, cartCount } = useSelector((state) => state.CartAndWishlistCount.overAllCountDetails)
    const { isAuthenticated, user } = useSelector((state) => state.authData)
    const WishListcartCountLoading = useSelector((state) => state.CartAndWishlistCount.loading)
    const { categoryList, flashMessage } = useSelector((state) => state.commonApiData.getCategoryCart)


    const [openSearchDataBox, setOpenSearchDataBox] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
    }


    const handleLogOut = async () => {
        dispatch(logoutUser())
        //dispatch(generateUserId())
        navigate('/')
        window.location.reload();
    }


    useEffect(() => {
        dispatch(GetCatagoryData())
        dispatch(GetOverAllCountAPI())
    }, [])


    return (
        <>
            <div className='py-1 bg-secondary d-none'>
                <div className="container d-flex justify-content-between align-items-center">
                    <div style={{ width: "50%" }} >
                        <Slider {...settings}>
                            {flashMessage && flashMessage?.map((msg, index) =>
                                <div key={`flashMessage_${index}`}>
                                    <p className='m-0 text-dark' >{msg.flashMessage}</p>
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className='d-flex align-items-center'>
                        <a
                            className='mx-3 text-dark text-decoration-none small'
                            href="https://play.google.com/store/apps/details?id=com.sipl.indo_craft_world"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download App
                        </a>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#555' }} />
                        <Link className='mx-3 text-dark text-decoration-none small' to="/sellerRegistration">Become a Supplier</Link>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#555' }} />
                        <span className='mx-3 text-dark text-decoration-none small' style={{ cursor: "pointer" }} onClick={() => window.open(ADMIN_URL, '_blank')} >Seller Login</span>
                        
                    </div>
                </div>
            </div>
            <div className="sticky-top">
                <nav className="navbar navbar-expand-lg">
                    <div className="container align-items-center">
                        <div>
                            
                            <Link className="navbar-brand" to="/">
                                <img
                                    src="./images/sample_logo1.png"
                                    className=""
                                    alt=""
                                />
                            </Link>
                        </div>
                        <ul className='list-unstyled d-none d-lg-block'>
                            <li className='main_dropdown_list' key='Category'>
                                <Link className='text-decoration-none text-dark d-flex justify-content-center align-items-center catog_link' href='.'>
                                    <img className='me-2' src="./images/menu-dots.svg" width="20" alt="" /> Categories
                                </Link>
                                <div className="main_dropdown_item" style={{ overflow: 'hidden', minWidth: "800px", height: "400px", columnCount: 4, columnGap: "20px", columnFill: 'auto' }}>
                                    {categoryList?.map((category, categoryIndex) => (
                                        <div className='' key={categoryIndex}>
                                            <Link to={`/products?category=${category.categoryName}`} className='mdi_heading'>{category.categoryName}</Link>
                                            {category.subCategoryList?.map((subCategory, subCategoryIndex) => (
                                                <Link key={subCategoryIndex} to={`/products?subCategory=${subCategory.subCategoryName}`} className='mdi_link'>{subCategory.subCategoryName}</Link>
                                            ))}
                                            <div style={{ marginBottom: "10px" }} className="divider"></div>
                                        </div>
                                    ))}
                                </div>
                            </li>
                        </ul>
                        <div className="order-2 order-lg-1 d-flex align-items-center gap-2 w-100">
                            <MenuOutlined className='d-lg-none' onClick={toggleDrawer(true)} fontSize='large' sx={{ mr: '5px' }} />
                            <div className="input-group my-3 my-lg-0 mx-xl-5 position-relative d-flex align-items-center" style={openSearchDataBox ? { border: '3px solid var(--secondary)', borderRadius: '10px' } : {}}>
                                <LiveSearchBar2 />
                            </div>
                        </div>
                        <div className='d-flex align-items-center order-1 order-lg-2'>
                            <div id="google_translate_element"></div> {/* Google Translate Element */}
                            
                            <Divider orientation="vertical" className='my-1' flexItem sx={{ borderColor: '#555' }} />
                            <div className="mx-md-3">
                                <Link
                                   // to={isAuthenticated ? '/wishlist' : '/login'}
                                    to={'/wishlist' }
                                >
                                    <IconButton>
                                        <Badge badgeContent={WishListcartCountLoading ? 0 : Number(wishListCount) } color="secondary">
                                            <FavoriteBorder />
                                        </Badge>
                                    </IconButton>
                                </Link>
                            </div>
                            <Divider orientation="vertical" className='my-1' flexItem sx={{ borderColor: '#555' }} />
                            <Link to='/cart' className="cart d-flex mx-2 mx-md-3">
                                <Badge className='align-items-center gap-1' badgeContent={WishListcartCountLoading ? 0 : Number(cartCount)} color="secondary">
                                    {/*<ShoppingCart />*/}
                                    <img src='./images/cart.svg' alt='cart icon' width='35' /> <span className="d-none d-md-block">Cart</span>
                                </Badge>
                                {/*<Link className='d-none d-md-block ms-2' ml={2} variant='body-2'>Cart</Link>*/}
                            </Link>
                        </div>
                    </div>
                </nav>

                <div className="bottom_bar d-none d-lg-block">
                    <div className="container d-flex justify-content-center align-items-center">
                        <ul className='d-flex p-0 ms-auto gap-3 justify-content-center list-unstyled align-items-center'>
                            <li key='home'>
                                <NavLink className='text-decoration-none' to="/">Home</NavLink>
                            </li>
                            {/*<li key='Stores'>*/}
                            {/*    <NavLink className='text-decoration-none' to="/Stores">Stores</NavLink>*/}
                            {/*</li>*/}
                            <li key='TodayDeals'>
                                <NavLink className='text-decoration-none' to="/TodayDeals">Today Deals</NavLink>
                            </li>
                            <li key='BestSeller'>
                                <NavLink className='text-decoration-none' to="/BestSeller">Best Seller</NavLink>
                            </li>
                            <li key='Seller'>
                                <NavLink className='text-decoration-none' to="/sellerRegistration">Sell</NavLink>
                            </li>
                            <li key='Gift'>
                                <NavLink className='text-decoration-none' to="/gift">Gifts</NavLink>
                            </li>
                            <div>
                                <Link to="/recently-viewed" className='text-decoration-none'>Recently Viewed</Link>
                            </div>
                        </ul>
                            {/* ----------------------login-dropdown------------------------------------------------- */}
                        <div className="position-relative headsignup_btn rounded ms-auto me-3">
                            <Link className='text-decoration-none text-dark' style={{ whiteSpace: 'nowrap', fontSize: '15px', fontWeight: 500 }} to={!isAuthenticated ? 'Login' : 'Profile'}> {!isAuthenticated ? 'Login' : 'Profile'}</Link>
                            <div className="dropdown_item position-absolute rounded">
                                {!isAuthenticated ? <Link to='/signup' className='d-flex justify-content-between border-bottom px-2 py-2'><span className='text-dark'>New customer?</span><span>Sign Up</span></Link> : <div className='d-flex justify-content-between border-bottom px-2 py-2 align-items-center'><span className='text-dark'>{user.userName}</span><span className='online_dot pulse'></span></div>}
                                <ul>
                                    <li key='profile'><Link to={'/profile'} className='text-dark'><i className="fa-solid me-1 fa-user"></i> My Profile</Link></li>
                                    <li key='Orders'><Link to={'/profile/order'} className='text-dark'><i className="fa-solid me-1 fa-box"></i> Orders</Link></li>
                                    <li key='Wishlist'><Link to={'/wishlist'} className='text-dark'><i className="fa-solid me-1 fa-heart"></i> Wishlist</Link></li>
                                    {isAuthenticated &&
                                        <li key='Logout' onClick={() => handleLogOut()}><span style={{
                                            padding: "8px 10px",
                                            display: "inline-block",
                                            width: "100%",
                                            fontSize: "14px",
                                            cursor: 'pointer'
                                        }} className='text-dark'><i className="fa me-1 fa-sign-out" ></i> Sign Out</span></li>}
                                </ul>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
            <SideDrawer open={open} toggleDrawer={toggleDrawer} CatagoryList={categoryList} />
        </>
    );
}


const LiveSearchBar2 = () => {
    const [search, setSearch] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [selectedItem, setselectedItem] = useState(-1);
    const [suggestionData, setSuggestionData] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();



    

   



    const handleChange = (e) => {
        setSearch(e.target.value);
        if (searchData === '') setSuggestionData(false)
        else setSuggestionData(true)
    };
    const handleClose = () => {
        setSearch("");
        setSearchData([]);
        setselectedItem(-1)
        setSuggestionData(false)
    };
  
    const handleKeyDown = (e) => {

        if (selectedItem < searchData.length) {
            if (e.key === "ArrowUp" && selectedItem > 0) {
                setselectedItem((prev) => prev - 1);     
            } else if (
                e.key === "ArrowDown" &&
                selectedItem < searchData.length - 1
            ) {
                setselectedItem((prev) => prev + 1);

            } else if (e.key === "Enter") {
                if (selectedItem >= 0) {
                    setSuggestionData(false)
                    setSearch(searchData[selectedItem].value)
                    navigate(`/products?searchingText=${searchData[selectedItem].value}`)
                } else {
                    setSuggestionData(false)
                    if (search.trim('') === '') {
                        toast.error("Please Seacrh Something")
                        return;
                    }
                    navigate(`/products?searchingText=${search}`)
                }
            }
        } else {    
            setselectedItem(-1)  
        }
    };

    const handleClick = (value) => {
        navigate(`/products?searchingText=${value}`)
        setSearch(value)
        setSuggestionData(false)
    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    async function getDataApiCall(search) {
        let data = await ApiCall(`/api/Web/GetSearchingText`, { Prefix: search ? search : "" })
        if (data.status === 'SUCCESS') {
            setSearchData(data.searchDataList);
        } else {
            setSearchData([]);
        };
    }

    useEffect(() => {
        if (search === '') {
            setselectedItem(-1)
            setSuggestionData(false)
            setSearchData([]);
            return;
        }
        const getData = setTimeout(() => {
            getDataApiCall(search)
        }, 500)
        return () => clearTimeout(getData)
    }, [search]);
    //useEffect(() => {
    //    if (search !== "") {
    //        getDataApiCall(search)
    //    }
    //    if (search === '') {
    //        setselectedItem(-1)
    //        setSuggestionData(false)
    //    }
    //}, [search]);

    const searchBoxRef = useRef(null); 

    useEffect(() => {
       
        function handleClickOutside(event) {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {   
                setSuggestionData(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const pathnameWithSearch = location.pathname + location.search;
        if (!pathnameWithSearch.includes('?searchingText')) {
            setSearch('')
        }
    }, [location.pathname, location.search]);


    return (
        <div
            className="w-100"
        >
            <div ref={searchBoxRef} className='position-relative srch_box_input d-flex justify-content-center align-items-center'>

                <div style={{ width: "100%", position: "relative" }}>

                <input
                    autoComplete="off"
                    type="text"
                   
                        className='search_input form-control'
                        onChange={handleChange}
                        value={truncateText(search, 50)}
                  //  value={search}
                    onKeyDown={handleKeyDown}
                    placeholder="Search for anything"
                   
                    />
                    {
                        search.trim("").length > 0 && 
                 
                        <button style={{ position: "absolute", top: "45%", right: "0", transform: "translate(-50%, -50%)", backgroundColor: "inherit", border: "none", fontSize: "28px" }} onClick={handleClose}><CloseOutlined /></button>
                    }
                </div>


                <button className="btn bg-primary srch_btn" type="button" id="button-addon2"
                    onClick={() => search.trim('') !== '' ? navigate(`/products?searchingText=${search}`) : toast.error("Please Seacrh Something")}
                >

                    <SearchOutlined /> </button>


                {suggestionData ===true && searchData && (
                    <div
                        className=' srch_box position-absolute' style={{ cursor: "pointer", overflowY: 'auto', maxHeight: '22rem', }}

                    >
                        {searchData.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleClick(item.value) }
                                  
                                    className={`d-flex justify-content-between srch_box_item ${selectedItem === index ? "srch_box_item active"
                                        : "srch_box_item"}`}
                                >
                                  {item.value}
                                </div>
                            );
                        })}
                    </div>
                )}
                {suggestionData === true && searchData && (<div onClick={() => setSuggestionData(false)} className='over-lay'></div>)}
            </div>
        </div>
    );
};





