import React from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer.jsx';

export const Layout=({children})=>{

    return (
      <div>
        <NavMenu />
        <div>
          {children}
        </div>
        <Footer/>
      </div>
    );
}
