import { ArrowRightAlt } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ApiCall } from '../Common/service';
import { Brands, Categories, FeaturedSlider } from '../Sliders/Sliders';

export const AllSection = () => {
    const [catlist, setCatlist] = useState([]);
    const [loading, setLoading] = useState({
        topCategories: false,
        topBrands: false,
        sections: false
    })
    const [discountSectionLoading, setDiscountSectionLoading] = useState(false)
    const [SectionDataList, setSectionDataList] = useState([]);
    const [TopBrandDataList, setTopBrandDataList] = useState([]); 
    const navigate = useNavigate();
    const [discountProduct, setDiscountProduct] = useState([])
    const [sectionDataListLoading, setSectionDataListLoading] = useState(false);
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];

    //const LoginToken = {
    //    Token: user.token ? user.token : authLocalStorage.authToken,
    //    LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
    //    Source: 'Home'
    //}
    
    //const BeforeLoginToken = { Token: "", LoginUserCode: "", Source: 'web' }
   
    const GetSectionData = async () => {
        await setSectionDataListLoading(true)
        let data = await ApiCall(`/api/Web/GetSection`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'Home'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            setSectionDataList(data.section)
            await setSectionDataListLoading(false)
        } else if (data.status === 'ERROR') {
            await setSectionDataList([])
            await setSectionDataListLoading(false)
        }
    }

    //for Popular Product
    const GetCatData = async () => {
        setLoading(prev => ({ ...prev, topCategories: true }))
        let data = await ApiCall(`/api/Web/GetPopularCategories`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'Home'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            await setCatlist(data)
            await setLoading(prev => ({ ...prev, topCategories: true }))
        } else if (data.status === 'ERROR') {
            await setCatlist([])
            await setLoading(prev => ({ ...prev, topCategories: true }))
        }
    };

    //for Featured Product Slider
    const GetTopBrandData = async () => {
        setLoading(prev => ({ ...prev, topBrands: true }))
        let data = await ApiCall(`/api/Web/GetTopBrands`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'Home'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            await setTopBrandDataList(data.topBrands)
            await setLoading(prev => ({ ...prev, topBrands: true }))
        } else if (data.status === 'ERROR') {
            await setTopBrandDataList([])
            await setLoading(prev => ({ ...prev, topBrands: true }))
        }
    }
    const GetDiscountProduct = async () => {
        setDiscountSectionLoading(true)
        let data = await ApiCall(`/api/Web/GetDiscountProducts`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'Home'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            await setDiscountProduct(data)
            await setDiscountSectionLoading(false)

        } else if (data.status === 'ERROR') {
            await setDiscountProduct([])
            await setDiscountSectionLoading(false)

        }
    }

    useEffect(() => {
        GetTopBrandData();
        GetCatData();
        GetDiscountProduct()
        GetSectionData()
    }, [])
   
    return (
        <>
            <section>
                <div className="categories">

                    {
                        sectionDataListLoading ? <div className="header text-center mb-4">
                            <Skeleton />
                        </div> :
                            <div className="header text-center mb-4">
                                <h2><b className='text-theme'>{catlist?.categoryHeaderName}</b></h2>
                            </div>
                    }
                    {
                        sectionDataListLoading ? <div className='d-flex gap-1'>
                            {(() => {
                                const slides = [];
                                for (let i = 0; i < 8; i++) {
                                    slides.push(
                                        <div key={i} style={{ padding: "0px" }} className="categories_list">
                                            <div className="categories_img mb-2">
                                                <Skeleton animation="wave" sx={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <Skeleton animation="wave" width='90%' />

                                        </div>
                                    );
                                }
                                return slides;
                            })()}
                        </div> :
                            <Categories catlist={catlist} loading={loading.topCategories} />
                    }
                </div>
            </section>
            {TopBrandDataList?.length > 0 ? < section >
                <div className="featured_brands">
                    <div className="heading">
                        {
                            sectionDataListLoading ? <Skeleton animation="wave" /> :  <h4><b className='text-theme'>Featured</b> Brands</h4>
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            {
                                sectionDataListLoading ? <div className='d-flex gap-1'>
                                    {(() => {
                                        const slides = [];
                                        for (let i = 0; i < 8; i++) {
                                            slides.push(
                                                <div key={i} style={{ padding: "0px" }} className="categories_list">
                                                    <div className="categories_img">
                                                        <Skeleton animation="wave" sx={{ width: '100%', height: '100%' }} />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return slides;
                                    })()}
                                </div> : 
                                <Brands TopBrandDataList={TopBrandDataList} loading={loading.topBrands} />
                            }
                        </div>
                    </div>
                </div>
            </section> : <></>}

            {
                sectionDataListLoading ? (
                    <>
                        <div className="row">
                            <div style={{ marginTop: "20px" }}>

                                <Skeleton height={30} />
                            </div>
                            {(() => {
                                const slides = [];
                                for (let i = 0; i < 10; i++) {
                                    slides.push(
                                        <div key={i} className={`pe-2 mt-3 pb-3 position-relative col-md-3 col-sm-6`} style={{ width: "20%" }}>
                                            <Skeleton animation="wave" style={{ height: "25rem" }} />
                                        </div>
                                    );
                                }
                                return slides;
                            })()}
                        </div>
                    </>
                ) : (
                    <div>
                        {
                            SectionDataList?.length > 0 &&
                            <> {SectionDataList.map((section, sectionIndex) =>
                                <>
                                    {section.sectionType === "Product" && <section className='' key={sectionIndex}>
                                        <div className="featured_products">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="heading">
                                                    <h4><b className='text-theme'>{section.sectionName.trim().split(" ")[0]}</b> {section.sectionName.trim().split(" ")[1]}</h4>
                                                </div>
                                                <div className="vabtn">
                                                    <Link className='text-decoration-none text-dark' to={`/products?section=${section?.sectionName}`}>View all <ArrowRightAlt /></Link>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <FeaturedSlider
                                                    Source={'Home'}
                                                    section={section}
                                                    sliderDataList={section.sectionImages ? section.sectionImages : []}
                                                />
                                            </div>
                                        </div>
                                    </section>}
                                    {section.sectionType === "AD" &&
                                        <section key={sectionIndex}> <div className="br-20" onClick={() => window.open(`${section?.sectionImages[0]?.adProductPath}`, 'blank')} style={{ cursor: 'pointer' }}>
                                            <div className="img">
                                                <span style={{ backgroundColor: "black", color: '#fff', padding: '0px 3px 3px 3px' }}>ad <AddCircleOutlineIcon style={{ color: "#fff", fontSize: "16px" }} /> </span>
                                                <img className="w-100 br-20" src={section?.sectionImages[0]?.productImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }} alt="AD_Image" />
                                            </div>
                                        </div>
                                        </section>}
                                </>)}
                            </>
                        }
                    </div >
                )
            }

            {
                discountSectionLoading ? (
                    < section >
                        <div className="br-20 row shopbydisscount" style={{ cursor: 'pointer' }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h3><b className='text-theme'>Shop By</b> Discounts</h3>
                                <div className="vabtn">
                                    <Link className='text-decoration-none text-dark' to={`/products?discount=${'all'}`}>View all <ArrowRightAlt /></Link>
                                </div>
                            </div>
                            <div className='row discountscroll mt-3 mt-md-4'>
                                {(() => {
                                    const slides = [];
                                    for (let i = 0; i < 6; i++) {
                                        slides.push(
                                            <div key={i} style={{ padding: "20px" }} className='col-lg-4 col-md-6 col-9'>
                                                <Skeleton style={{ width: "100%", height: "140px", borderRadius: "20px" }} />
                                            </div>
                                        );
                                    }
                                    return slides;
                                })()}
                            </div>
                        </div>
                    </section >
                ) : (
                    < section key={`Sections_Banner${1}`}>
                        <div className="br-20 row shopbydisscount" style={{ cursor: 'pointer' }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h3><b className='text-theme'>Shop By</b> Discounts</h3>
                                <div className="vabtn">
                                    <Link className='text-decoration-none text-dark' to={`/products?discount=${'all'}`}>View all <ArrowRightAlt /></Link>
                                </div>
                            </div>
                            <div className='row discountscroll mt-3 mt-md-4'>

                                {discountProduct?.products?.map((item, index) => {
                                        return (
                                            <div key={index} className='col-lg-4 col-md-6 col-9'>

                                                <div className='discount_card' onClick={() => navigate(`/products?discount=${item.discount}`)}>
                                                    <div className='row'>
                                                        <div className='col-8 d-flex justify-content-center align-items-center'>
                                                            <div className='p-3'>
                                                                <span>UPTO</span>
                                                                <span>{item.discount}%</span>
                                                                <span>OFF</span>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 bg-white d-flex justify-content-center align-items-center'>
                                                            <div className='p-3 text-center'>
                                                                <h4>SHOP NOW!!</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section >
                )
            }

        </>
    )
}


