

const RatingStar = ({ productRating }) => {
    const rating = parseFloat(productRating);
    const filledStars = Math.floor(rating);
    const remainingStar = rating - filledStars;

    const filledStarsArray = Array.from({ length: filledStars }, (_, index) => (
        <i key={index} className='fa-solid fa-star text-warning'></i>
    ));

    const halfStar = remainingStar > 0 ? (
        <i className='fa-solid fa-star-half-alt text-warning'></i>
    ) : null;

    const unfilledStarsArray = Array.from({ length: 5 - filledStars - (remainingStar > 0 ? 1 : 0) }, (_, index) => (
        <i key={index} className='fa-regular fa-star fa-fw text-warning'></i>
    ));

    return (
        <div className='ratings mb-2'> 
            {filledStarsArray}        
            {halfStar}
            {unfilledStarsArray}
            {/* Render rating count */}
        {/*<span className='text-theme ms-2'>{productRating}</span>*/}
        </div>
    );
}

export default RatingStar


//< div className = 'ratings mb-2' >
//    <i className='fa-solid fa-star text-warning'></i>

//    <i className='fa-solid fa-star text-warning'></i>

//    <i className='fa-solid fa-star text-warning'></i>

//    <i className='fa-solid fa-star text-warning'></i>

//    <i className='fa-regular fa-star fa-fw text-warning'></i>

//    <span className='text-theme ms-2'>2,153</span>
//    <span className='text-theme ms-2'>{item.productRating}</span>

//</div >