import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, Select, MenuItem, Box, CircularProgress, ListItemIcon, ListItemText } from '@mui/material';

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AlertBox, ApiCall, setFocus } from '../Common/service'
import './Signup/Signup.css'
import { convertToNumber } from '../Common/Input'
import { toast } from 'react-toastify';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect } from 'react'
import axios from 'axios';

export const SellerRegistration = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isDisableOTPInput, setIsDisableOTPInput] = useState(true);
    const [thankyoupage, setThankyoupage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [countryCodes, setCountryCodes] = useState([]);
    const [formData, setFormData] = useState({
        fullName: '',
        countryCode:'+91',
        mobileNo: '',
        otp: '',
        emailID: '',
        password: '',
        confirmPassword: '',
    });
    const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || 'https://example.com';
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleRegister = async (e) => {
        e.preventDefault();

        if (formData.fullName.trim() === '') {
            toast.error('Please Enter Your Name.');
            return;
        }

        if (formData.mobileNo.trim() === '') {
            toast.error('Please Enter Your Mobile Number.');
            return;
        }

        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.emailID)) {
            toast.error('Please Enter a Valid Email Address.');
            return;
        }

        if (formData.password.trim() === '') {
            toast.error('Please Enter Your Password.');
            return;
        }

        if (formData.confirmPassword.trim() === '') {
            toast.error('Please Enter Your Confirm Password.');
            return;
        }


        setLoading(true)
        let data = await ApiCall(`/api/Web/SellerRegistration`, formData)
        if (data.status === 'SUCCESS') {
            await setFormData({
                fullName: '',
                countryCode:'',
                mobileNo: '',
                otp: '',
                emailID: '',
                password: '',
                confirmPassword: '',
            })
            await setThankyoupage(true)
            await setIsDisableOTPInput(true)
            await setLoading(false)
        } else if (data.status === 'ERROR') {
            await toast.error(data.message);
            await setFocus(data.focus);
            await setLoading(false)
            await setThankyoupage(false)
        }
    }
    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countries = response.data.map((country) => ({
                name: country.name.common,
                dial_code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
                flag: country.flags.png,
            }));
            setCountryCodes(countries);
            setLoading(false);
        } catch (error) {
            toast.error('Failed to load country codes.');
            setLoading(false);
        }
    };
    const handleCountryCodeChange = (e) => {
        setFormData({ ...formData, countryCode: e.target.value });
    };
    const SendOTPToMobileNo = async () => {
        let data = await ApiCall(`/api/Web/SendOTPToMobileNo`, { MobileNo: formData.mobileNo })
        if (data.status === 'SUCCESS') {
            await AlertBox(data.status, data.message, data.focus)
            await setIsDisableOTPInput(false)
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message)
            await setIsDisableOTPInput(true)
        }
    }

    useEffect(() => {
            fetchCountryCodes()
    
        document.title = 'Seller Registration';
    },[])

    return (
        <>
            <section className={'bg-light pb-5'} >
                <div className={"container-fluid"}>
                    <div className="login rounded h-100">
                        <div className="row h-90">
                            <div className="col-md-6 p-0 login-img d-flex justify-content-center align-items-center flex-column">
                                <img src="./images/seller.svg" style={{ width: '60%' }} alt="" />
                                <h6 className='text-white mb-2 mt-4'>We Are Welcome You !</h6>
                                <h5 className='text-white'>The World Best Craft Products.</h5>
                            </div>
                            {!thankyoupage ? <div className="col-md-6 p-0 d-flex flex-column justify-content-between">
                                <div className="login-form">
                                    <h5 className='text-center'>Welcome to <span style={{ color: '#a57c49', }}>Indo Craft World</span></h5>
                                    <span className='text-center d-block'>Create your account to start selling</span>
                                    <div className='mb-3 mt-3' >
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="txtName"
                                            label="Full Name"
                                            variant="standard"
                                            type="text"
                                            required
                                            inputProps={{ maxLength: 100 }}
                                            value={formData.fullName}
                                            onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                                            
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.fullName.trim() === '') {
                                                        e.preventDefault();
                                                        toast.error('Please Enter Your Name.');
                                                    } else {
                                                        setFocus("txtMobileNo");
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    {/*<div className='my-3 d-flex align-items-end justify-content-center gap-3' >*/}
                                    {/*    <TextField*/}
                                    {/*        sx={{ width: '100%' }}*/}
                                    {/*        id="txtMobileNo"*/}
                                    {/*        label="Mobile Number"*/}
                                    {/*        variant="standard"*/}
                                    {/*        type="text"*/}
                                    {/*        inputProps={{ maxLength: 10 }}*/}
                                    {/*        required*/}
                                    {/*        value={formData.mobileNo}*/}
                                    {/*        onChange={(e) => setFormData({ ...formData, mobileNo: convertToNumber(e.target.value) })}*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') {*/}
                                    {/*                if (formData.mobileNo.trim() === '') {*/}
                                    {/*                    e.preventDefault();*/}
                                    {/*                    toast.error('Please Enter Your Mobile Number.');*/}
                                    {/*                } else {*/}
                                    {/*                    setFocus("txtOTP");*/}
                                    {/*                }*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*    <button className={`text-light border-0 rounded mybtn p-1 px-2 ${formData.mobileNo.length !== 10 ? "disabledBTN" : "mybtn"}`} disabled={formData.mobileNo.length !== 10} style={{ background: '#fab86a' }} onClick={SendOTPToMobileNo} >Send OTP</button>*/}
                                    {/*</div>*/}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl variant="standard" sx={{ minWidth: 120, marginRight: 2, marginTop: 2 }}>
                                            <Select
                                                labelId="country-code-label"
                                                id="countryCode"
                                                value={formData.countryCode}
                                                onChange={handleCountryCodeChange}
                                            >
                                                {loading ? (
                                                    <MenuItem value="">
                                                        <CircularProgress size={24} />
                                                    </MenuItem>
                                                ) : (
                                                    countryCodes.map((country) => (
                                                        <MenuItem key={country.dial_code} value={country.dial_code}>

                                                            <ListItemText primary={` ${country.dial_code}`} />
                                                            <ListItemIcon>
                                                                <img src={country.flag} alt={`${country.name} flag`} width="22" height="14" className="mt-2" />
                                                            </ListItemIcon>
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="txtMobileNo"
                                            label="Mobile Number"
                                            variant="standard"
                                            type="number"
                                            required
                                            value={formData.mobileNo}
                                            onChange={(e) => setFormData({ ...formData, mobileNo: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.mobileNo.trim() === '') {
                                                        e.preventDefault();
                                                        toast.error('Please Enter Your Mobile No.');
                                                    } else {
                                                        setFocus("txtEmailID");
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.countryCode === '+91' && (
                                            <button
                                                // className='bg-primary text-white py-2 w-70 border-0 rounded mybtn mt-3 ms-2'
                                                className={`bg-primary text-white py-2 w-70 border-0 rounded mybtn mt-3 ms-2 ${formData.mobileNo.length !== 10 ? "disabledBTN" : "mybtn"}`} disabled={formData.mobileNo.length !== 10}
                                                onClick={SendOTPToMobileNo}
                                            >
                                                Send OTP
                                            </button>
                                        )}
                                    </Box>
                                    {!isDisableOTPInput && (
                                    <div className='my-3 mb-3'>
                                        <label className="mb-2">Enter 6 Digit OTP</label>
                                        <MuiOtpInput
                                                value={formData.otp}
                                                id="txtOTP"
                                            TextFieldsProps={{ disabled: isDisableOTPInput, size: 'small' }}
                                            length={6}
                                                onChange={(otp) => setFormData({ ...formData, otp: convertToNumber(otp) })}
                                            
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (formData.mobileNo.trim() === '') {
                                                            e.preventDefault();
                                                            toast.error('Please Enter Your OTP.');
                                                        } else {
                                                            setFocus("txtEmail");
                                                        }
                                                    }
                                                }}
                                        />
                                    </div>
                                    )}
                                   
                                    <div className='mb-3' >
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="txtEmail"
                                            label="Email Address"
                                            variant="standard"
                                            type="email"
                                            required
                                            inputProps={{ maxLength: 100 }}
                                            value={formData.emailID}
                                            onChange={(e) => setFormData({ ...formData, emailID: e.target.value })}
                                            
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.mobileNo.trim() === '') {
                                                        e.preventDefault();
                                                        toast.error('Please Enter Your Email.');
                                                    } else {
                                                        setFocus("txtPassword");
                                                    }
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className='mb-3' >
                                        <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                            <InputLabel htmlFor="txtPassword">Set Password</InputLabel>
                                            <Input
                                                id="txtPassword"
                                                type={showPassword ? 'text' : 'password'}
                                                inputProps={{ maxLength: 20 }}
                                                value={formData.password}
                                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (formData.mobileNo.trim() === '') {
                                                            e.preventDefault();
                                                            toast.error('Please Enter Your Password.');
                                                        } else {
                                                            setFocus("txtConfirmPassword");
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='mb-2' >
                                        <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                            <InputLabel htmlFor="txtConfirmPassword">Confirm Password</InputLabel>
                                            <Input
                                                id="txtConfirmPassword"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                inputProps={{ maxLength: 20 }}
                                                value={formData.confirmPassword}
                                                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (formData.mobileNo.trim() === '') {
                                                            e.preventDefault();
                                                            toast.error('Please Enter Your Confirm Password.');
                                                        } else {
                                                            handleRegister()
                                                        }
                                                    }
                                                }}
                                               
                                            />
                                        </FormControl>
                                        <ul style={{ listStyle: 'disc', padding: '20px 0 15px 25px' }}>
                                            <li className='text-black-50 small'>White spaces are not allowed.</li>
                                            <li className='text-black-50 small'>Password length should must be 8 to 15 character(s).</li>
                                            <li className='text-black-50 small'>Password must be combination of atleast one numeric and one upper case letter and one lowercase and special character [$#@!()].</li>
                                        </ul>
                                    </div>
                                    <div className='mt-2' >
                                        <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={handleRegister}>Sign Up</button>
                                    </div>
                                </div>
                                <div className="login-footer text-center">
                                    <p>Already Have an Account? <Link to={'https://seller.indocraftworld.com/'} target='_blank' className='text-primary'>Login Now</Link></p>
                                </div>
                            </div>
                                : <div className="col-md-6 p-0 d-flex flex-column justify-content-between">
                                    <div className="login-form my-3">

                                        <div className="check_svg text-center">
                                            <svg
                                                width="115px"
                                                height="115px"
                                                viewBox="0 0 133 133"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g
                                                    id="check-group"
                                                    stroke="none"
                                                    strokeWidth={1}
                                                    fill="none"
                                                    fillRule="evenodd"
                                                >
                                                    <circle id="filled-circle" fill="#07b481" cx={66.5} cy={66.5} r={54.5} />
                                                    <circle id="white-circle" fill="#FFFFFF" cx={66.5} cy={66.5} r={55.5} />
                                                    <circle
                                                        id="outline"
                                                        stroke="#07b481"
                                                        strokeWidth={4}
                                                        cx={66.5}
                                                        cy={66.5}
                                                        r={54.5}
                                                    />
                                                    <polyline
                                                        id="check"
                                                        stroke="#FFFFFF"
                                                        strokeWidth={10}
                                                        points="41 70 56 85 92 49"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className='text-center'>
                                            <h1>Thank You </h1>
                                            <p> Your Registration has been Successful. Please Check Your<br/> Registered Mail Id and Complete your process. </p>
                                            <div className='mt-2' >
                                                <button className='bg-primary text-white py-2 px-3 border-0 rounded mybtn' onClick={async () => {
                                                    await setThankyoupage(false)
                                                    await window.open('https://seller.indocraftworld.com/', '_blank');
                                                }}>Login</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
