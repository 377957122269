import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Dialog, IconButton, Tooltip, Zoom } from '@mui/material';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomButton, InputBox } from "../Common/Input";
import { ApiCall, number, setFocus } from "../Common/service";
import { OrderTrack } from "../Profile/OrderTrack";
import { ReviewModal } from "../Profile/ReviewModal";

const OrderDetails = ({ stateValue = true }) => {
    const [customerOrderDetailsLoading, setCustomerOrderDetailsLoading] = useState(false)
    const { user } = useSelector((state) => state.authData)
    let location = useLocation();
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const OrderNo = searchParams.get('OrderNo');
    let token = JSON.parse(localStorage.getItem('authUser'))
    const [openTrack, setOpenTrack] = useState(false);
    const [OrderDetailsList, setOrderDetailsList] = useState({});
    const [OrderTrackingData, setOrderTrackingData] = useState([]);
    const [openReview, setOpenReview] = useState(false);
    const [showReason, setShowReason] = useState(false);
    const [orderData, setOrderData] = useState({});
    const [ProductCode, setProductCode] = useState('');
    const [state, setState] = useState(false)
    const [orderNo, setOrderNo] = useState('');
    const [singleOrderDetailsForCancel, setSingleOrderDetailsForCancel] = useState();
    const [ReviewData, setReviewData] = useState({
        "rating": "5",
        "reviewRemarks": "",
        "reviewTitle": "",
    })
    const [reasonForm, setReasonForm] = useState({
        Reason: "",
        BankName: "",
        AccountNo: "",
        IFSCCode: "",
        AccountHolderName: "",
        UPIID: "",
        RefundPaymenMode: '',
    });
    const handleReviewOpen = (productCode, orderNo) => {
        setReviewData({
            "rating": '5',
            "reviewRemarks": '',
            "reviewTitle": '',
        })
        setOpenReview(true);
        setProductCode(productCode);
        setOrderNo(orderNo);
    };

    const handleEditReview = async (productCode, orderNo = '') => {
        setProductCode(productCode);
        setOrderNo(orderNo);
        setState(false)
        let data = await ApiCall(`/api/Web/EditReview`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Product: productCode ? productCode : '',
            orderNo
        })
        if (data.status === 'SUCCESS') {
            setOpenReview(true);
            setReviewData({
                "rating": data.rating,
                "reviewRemarks": data.reviewRemarks,
                "reviewTitle": data.reviewTitle,
            })
            GetOrderDetails()
        } else if (data.status === 'ERROR') {
            setReviewData({
                "rating": '5',
                "reviewRemarks": '',
                "reviewTitle": '',
            })
        }
    };
    const handleClearReasonFormData = async () => {
        await setShowReason(false)
        await setReasonForm({
            ...reasonForm,
            Reason: "",
            BankName: "",
            AccountNo: "",
            IFSCCode: "",
            AccountHolderName: "",
            UPIID: "",
            RefundPaymenMode: '',
        })
        await setOrderData({});
    }
    const GetOrderDetails = async () => {
        setCustomerOrderDetailsLoading(true)
        let data = await ApiCall(`/api/web/GetOrderDetails`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            customer: token?.userCode,
            OrderNo: OrderNo
        })
        if (data.status === 'SUCCESS') {
            setOrderDetailsList(data)
            setCustomerOrderDetailsLoading(false)
        } else if (data.status === 'ERROR') {
            setOrderDetailsList([])
            setCustomerOrderDetailsLoading(false)

        }
    }
    const base64ToBlob = (base64, type = "application/pdf") => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type: type });
    }

    const downloadBlob = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
    const PrintInvoice = async (orderNo) => {
        let data = await ApiCall(`/api/Print/GetInvoicePrint`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "",
            OrderNo: orderNo ? orderNo : ''
        });

        if (data.status === 'SUCCESS') {
            const base64String = data.documentPdfBase64.split(';base64,').pop();
            const blob = base64ToBlob(base64String);
            downloadBlob(blob, 'invoice.pdf');
        } else if (data.status === 'ERROR') {
            toast.error("Some erorr occured. Please try after sometime.")
        }
    };
    const handleTrackOrder = async (orderNo) => {
        setOpenTrack(true);
        let data = await ApiCall(`/api/Web/TrackOrder`, {
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web",
            OrderNo: orderNo ? orderNo : ''
        })
        if (data.status === 'SUCCESS') {
            setOrderTrackingData(data)
        } else if (data.status === 'ERROR') {
            setOrderTrackingData([])
        }
    };
    const EcomAPIshipmentReturn = async (AwbNo,orderNo,type) => {
        const request = {
            AwbNo: AwbNo,
            ORDER_NUMBER: orderData?.orderNo,
            paymentType: singleOrderDetailsForCancel?.paymentType,
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/ReverseManifest`, { ...request })
        if (data.status === 'SUCCESS') {
            //await toast.success(data.message)
            HandleOrderAction(type);

            await handleClearReasonFormData()

        } else if (data.status === 'ERROR') {
            await toast.error(data.message)
        }

    }
    const handleClose = () => {
        setOpenTrack(false);
    };
    const HandleShowReasonModal = (Type, OrderDetails) => {
       // console.log(OrderDetails)
        setSingleOrderDetailsForCancel(OrderDetails)
        setShowReason(true)
        setOrderData({ ...OrderDetails, Type: Type })
        //   console.log(Type)
    }
    
    const EcomAPIshipmentCancellation = async (AwbNo, OrderNo,type) => {
        const request = {
            AwbNo: AwbNo,
            ORDER_NUMBER: OrderNo,
            Token: user?.token,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/ShipmentCancellation`, { ...request })
        if (data.status === 'SUCCESS') {
            HandleOrderAction(type);
            await handleClearReasonFormData()

        } else if (data.status === 'ERROR') {
            await toast.error(data.message)
        }

    }
   
    // console.log(OrderDetailsList,"OrderDetailsListOrderDetailsListOrderDetailsListOrderDetailsList")
    const HandleOrderAction = async (type) => {
        const request = {
            OrderNo: orderData?.orderNo,
            OrderStatusType:type,
            ProductCode: orderData.productCode,
            Token: user?.token,
            paymentType: singleOrderDetailsForCancel?.paymentType,
            LoginUserCode: user?.loginUserCode,
            Source: "web"
        }
        let data = await ApiCall(`/api/Web/CancelReturnReplace`, { ...request, ...reasonForm })
        if (data.status === 'SUCCESS') {
            await toast.success(data.message)
            await handleClearReasonFormData()
            GetOrderDetails()
        } else if (data.status === 'ERROR') {
            await toast.error(data.message)
        }

    }
    const currentDate = new Date();

    const getExpiryDate = (startDate, days) => {
        const expiryDate = new Date(startDate);
        expiryDate.setDate(expiryDate.getDate() + parseInt(days, 10));
        return expiryDate;
    };

    const orderDate = new Date(OrderDetailsList?.order?.deliveryDate);
    const returnableDays = OrderDetailsList?.order?.returnableDays;
    const replaceableDays = OrderDetailsList?.order?.replaceableDays;

    const returnExpiryDate = returnableDays ? getExpiryDate(orderDate, returnableDays) : null;
    const replaceExpiryDate = replaceableDays ? getExpiryDate(orderDate, replaceableDays) : null;

    const showPolicy = returnExpiryDate && replaceExpiryDate
        ? currentDate <= returnExpiryDate || currentDate <= replaceExpiryDate
        : true;

    //const deliveryDate = new Date(`${OrderDetailsList?.order?.deliveryDate}`);
    //const daysToAdd = `${OrderDetailsList?.order?.returnableDays}`;
    //const expirationDate = new Date(deliveryDate.getTime() + parseInt(daysToAdd) * 24 * 60 * 60 * 1000);
    //const formattedDate = expirationDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
   // console.log(showPolicy, 'bababbb', returnExpiryDate, replaceExpiryDate)
   /* const currentdate = new Date();*/
    useEffect(() => {
        GetOrderDetails()
    }, [])

    return (
        <section className={stateValue ? 'py-5 myprofile' : 'pt-0'} >
            <div className={stateValue ? 'container-fluid' : ''} style={{ width: "100%", display: "flex", flexWrap:"nowrap" }}>
                <div className="order_tab" style={{ width: "100%" }}>
                    <div className="tab_head py-2 d-flex justify-content-between align-items-center ">
                        <h5 className='m-0'> <b>Order Details</b> </h5>

                    </div>
                    <div className="OrderDetailsContainer">
                        <div className="d-flex mb-3 gap-2 mt-2">

                            <h6 className='p-2'>Ordered on {OrderDetailsList.orderDate}</h6>
                            <h6 className='p-2'>
                                Order #:<span style={{ color: "green" }}>{OrderDetailsList.orderNo}</span>
                            </h6>
                            {/*<span>Your item has been delivered</span>*/}
                            <div className='ms-auto p-2'>
                                {/*<button style={{ minWidth: "155px" }} className='bg-primary border-0 text-white py-1 px-2 rounded'*/}
                                {/*// onClick={() => handleTrackOrder(orders.orderNo)}*/}
                                {/*>*/}
                                {/*    Invoice Print</button>*/}
                            </div>
                        </div>


                        <div className="cardOrderDetails" >
                            <div className="col-lg-12 col-sm-12 col-md-12" style={{ padding: "20px" }}>
                                <div className="row border-bottom">
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <h5>Delivery Address</h5>
                                        <p style={{ fontSize: "15px" }}>
                                            {OrderDetailsList?.addressDetail?.name}<br />
                                            {OrderDetailsList?.addressDetail?.address}
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <h5>Payment Method</h5>
                                        <p style={{ fontSize: "15px" }}>
                                            {OrderDetailsList?.paymentMethod?.paymentDetails}
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <h5>Quantity</h5>
                                        <p style={{ fontSize: "15px" }}>
                                            {OrderDetailsList?.order?.quantity}
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <h5>Order Summary</h5>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Item(s) Subtotal:</span> ₹{OrderDetailsList?.orderSummary?.itemSubtotal}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Shipping:</span> ₹{OrderDetailsList?.orderSummary?.shipping}

                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Total:</span> ₹{OrderDetailsList?.orderSummary?.total}

                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Total Tax:</span> ₹{OrderDetailsList?.orderSummary?.tax}

                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span><strong>Grand Total:</strong></span> <strong>₹{OrderDetailsList?.orderSummary?.grandTotal}</strong>

                                        </div>
                                      
                                       
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <h6>Transactions</h6>
                                    <p style={{ fontSize: "13px" }}>
                                        <span style={{ display: "inline-block", width: "100px", color: "green" }}><strong>Grand Total:</strong></span> <strong style={{ color: "green" }}>₹{OrderDetailsList?.orderSummary?.grandTotal}</strong><br />
                                        <span style={{ display: "inline-block", width: "100px" }}><strong>Refund:</strong></span> <strong >{OrderDetailsList?.transaction?.refund}</strong>

                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="cardOrderDetails mt-2" >
                            <div className="tab_body pt-3">
                                <div className="bg-light rounded border-0 p-3">
                                    <div className="ordr_num mb-3 d-flex flex-wrap justify-content-between align-items-center">
                                        <div>
                                            <b>
                                                Order ID: {OrderDetailsList?.order?.orderNo}

                                            </b>
                                            <h6 className='m-0 p-0'>Order On {OrderDetailsList?.order?.orderDate}</h6>
                                        </div>
                                        <Tooltip arrow title={OrderDetailsList?.order?.deliveryStatus} TransitionComponent={Zoom} placement="top-start">
                                            <h6
                                                style={{ maxWidth: "350px" }}
                                                className={` m-0 p-0 productName
                                                ${OrderDetailsList?.order?.deliveryStatus === "DELIVERED" ? 'orderStatusDelivered' : ''}  
                                                ${OrderDetailsList?.order?.deliveryStatus === "Cancel" ? 'orderStatusCancel' : ''}   `}
                                            > {OrderDetailsList?.order?.deliveryStatus}</h6>
                                        </Tooltip>
                                        <div>
                                           
                                        </div>
                                        <button style={{ minWidth: "155px" }} className='bg-primary border-0 text-white py-1 px-2 rounded' onClick={() => handleTrackOrder(OrderDetailsList?.order?.orderNo)}>
                                            Track Order Status
                                        </button>
                                    </div>
                                    <div className="ordr_info d-flex flex-wrap justify-content-between gap-3">
                                        <div className='d-flex flex-wrap gap-3'>
                                            <figure className='rounded'>
                                                <img src={OrderDetailsList?.order?.productImageUrl} alt="" onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} />
                                            </figure>
                                            <div className=''>
                                                <Tooltip arrow title={OrderDetailsList?.order?.productName} TransitionComponent={Zoom} placement="top-start">
                                                    <h6 className='productName orderPage_heading' onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(`/${OrderDetailsList?.order?.seoFriendly}/${OrderDetailsList?.order?.productCode}`);
                                                    }}>{OrderDetailsList?.order?.productName}</h6>
                                                </Tooltip>

                                                <span>{OrderDetailsList?.order?.vendorName}</span>
                                                <div className="">
                                                    <span>Qty :{OrderDetailsList?.order?.quantity}</span>
                                                    <div>
                                                        <span className="text-success fs-5">₹{OrderDetailsList?.order?.sellingPrice}</span>
                                                    </div>
                                                    <div>
                                                        {OrderDetailsList?.order?.discount >= '1.00' &&
                                                            <>
                                                                <span className="text-secondary text-decoration-line-through">₹{OrderDetailsList?.order?.mrpAmount}</span> &nbsp;
                                                                <span className='text-danger'>({OrderDetailsList?.order?.discountType === "FLAT" && "₹"}{OrderDetailsList?.order.discount}{OrderDetailsList?.order.discountType === "PERCENTAGE" && "%"} Off)</span>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ordr_placed text-end">

                                            {
                                                (OrderDetailsList?.order?.isCancel || OrderDetailsList?.order?.deliveryStatus === "DISPATCHED" || OrderDetailsList?.order?.deliveryStatus === "Pending" || OrderDetailsList?.order?.deliveryStatus === "Accepted" || OrderDetailsList?.order?.deliveryStatus === "Undelivered" || OrderDetailsList?.order?.deliveryStatus === "Placed" || OrderDetailsList?.order?.deliveryStatus === "Pending" || OrderDetailsList?.order?.deliveryStatus === "Undelivered") ? (< div className="">
                                                    <button style={{ minWidth: "155px" }} className='bg-danger border-0 text-light  py-1 px-2 rounded' onClick={() => HandleShowReasonModal('Cancel', OrderDetailsList?.order)} ><i class="fa-solid fa-window-close me-2"></i>Cancel Order</button>
                                                </div>) : (<></>)

                                            }

                                            {showPolicy && OrderDetailsList?.order?.deliveryStatus === "DELIVERED" ? (OrderDetailsList?.order?.isNotReplaceable ? < div className="mt-1">
                                                <button style={{ minWidth: "155px" }} className='bg-primary border-0 text-light  py-1 px-2 rounded' onClick={() => HandleShowReasonModal('Replace', OrderDetailsList?.order)} >Replace Order</button>
                                            </div> : <></>) : ''

                                            }
                                            {showPolicy && OrderDetailsList?.order?.deliveryStatus === "DELIVERED" ? (OrderDetailsList?.order?.isNotReturnable ? < div className="mt-1">
                                                <button style={{ minWidth: "155px" }} className='bg-primary border-0 text-light  py-1 px-2 rounded' onClick={() => HandleShowReasonModal('Return', OrderDetailsList?.order)} >Return Order</button>
                                            </div> : <></>) : ''
                                            }
                                            {OrderDetailsList?.order?.deliveryStatus === "DELIVERED" || OrderDetailsList?.order?.deliveryStatus === "Return" || OrderDetailsList?.order?.deliveryStatus === "Replace" ? (<div className="mt-1">
                                                <button
                                                    style={{ minWidth: "155px" }}
                                                    onClick={() => PrintInvoice(OrderDetailsList?.order?.orderNo)}
                                                    className='bg-dark border-0 text-light py-1 px-2 rounded'
                                                >
                                                    <i class="fa-solid fa-file me-2"></i>Print Invoice
                                                </button>
                                            </div>) : (<></>)}
                                            <div className="mt-1 mb-1">
                                                {OrderDetailsList?.order?.deliveryStatus === "DELIVERED" ? (!OrderDetailsList?.order?.reviewStatus ?
                                                    <button style={{ minWidth: "155px" }} className='bg-secondary border-0 text-dark py-1 px-2 rounded' onClick={() => handleReviewOpen(OrderDetailsList?.order?.productCode, OrderDetailsList?.order?.orderNo)}><i class="fa-solid fa-pencil me-2"></i>Write a Review</button> :
                                                    <button style={{ minWidth: "155px" }} className='bg-secondary border-0 text-light py-1 px-2 rounded' onClick={() => handleEditReview(OrderDetailsList?.order?.productCode, OrderDetailsList?.order?.orderNo)}><i class="fa-solid fa-eye me-2"></i>View/Edit Review</button>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ordr_foot   align-items-center pt-3 border-top">
                                        {
                                            OrderDetailsList?.order?.deliveryStatus === "DELIVERED" && showPolicy ? (
                                                <h6>
                                                    We have {returnableDays === '0' ? 'no' : `${returnableDays} day(s)`} return policy and&nbsp;
                                                    {replaceableDays === '0' ? 'no' : `${replaceableDays} day(s)`} replace policy.
                                                </h6>
                                            ) : null
                                        }  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OrderTrack openTrack={openTrack} OrderTrackingData={OrderTrackingData} handleClose={handleClose} />

                <Dialog
                    open={showReason}
                    fullWidth={true}
                    maxWidth='md'
                    aria-labelledby="">
                    <div className='p-3 pt-0'>
                        <div className=" d-flex align-items-center justify-content-between px-2 mt-1">
                            <h4 className='p-0 m-0'>{`Reason for ${orderData?.Type}`}</h4>
                            <IconButton onClick={async () => {
                                await handleClearReasonFormData();
                            }}>
                                <Close />
                            </IconButton>
                        </div>
                        <hr className='mt-0' />

                        <div className='row p-3 pt-0' >
                            <div>
                                <label htmlFor='txtReason'>{`${orderData?.Type} Reason`}</label>
                                <textarea name='Request to Resubmit' className='col-md-12' id="txtReason" rows="5 " value={reasonForm.Reason} onChange={(e) => setReasonForm({ ...reasonForm, Reason: e.target.value })} />
                            </div>

                            {
                                (singleOrderDetailsForCancel?.paymentType === "COD" && singleOrderDetailsForCancel?.deliveryStatus === "DELIVERED") ||
                                    (singleOrderDetailsForCancel?.deliveryStatus === "Return" && singleOrderDetailsForCancel?.paymentType === "COD")
                                    ?

                                <>
                                    <h6 className='pt-3 mb-1'>Refund Payment Mode</h6>
                                    <div className='mb-2'>
                                        <div className="">
                                            <input type='checkbox' className='me-1' id='txtBanksAccountDetails' checked={reasonForm.RefundPaymenMode === "Bank"} onChange={() => setReasonForm({
                                                ...reasonForm,
                                                RefundPaymenMode: 'Bank',
                                                BankName: "",
                                                AccountNo: "",
                                                IFSCCode: "",
                                                AccountHolderName: "",
                                                UPIID: "",
                                            })} />
                                            <label> Bank Account Details </label>
                                        </div>

                                        <div className="">
                                            <input type='checkbox' className='me-1' id='txtUPIDetails' checked={reasonForm.RefundPaymenMode === "UPI"} onChange={() => setReasonForm({
                                                ...reasonForm,
                                                RefundPaymenMode: 'UPI',
                                                BankName: "",
                                                AccountNo: "",
                                                IFSCCode: "",
                                                AccountHolderName: "",
                                                UPIID: "",

                                            })} />
                                            <label>UPI Details</label>
                                        </div>
                                    </div>
                                    {reasonForm.RefundPaymenMode === "Bank" ? <>
                                        <InputBox
                                            divclassname="col-md-4 col-sm-6 fields mb-2"
                                            label="Account Holder Name"
                                            placeholder="Account Holder Name"
                                            id="txtAccountHolderName"
                                            maxLength='100'
                                            value={reasonForm.AccountHolderName}
                                            onChange={(e) => setReasonForm({ ...reasonForm, AccountHolderName: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtBankName") };
                                            }}
                                            required
                                        />

                                        <InputBox
                                            divclassname="col-md-4 col-sm-6 fields mb-2"
                                            label="Bank Name"
                                            placeholder="Bank Name"
                                            id="txtBankName"
                                            maxLength='100'
                                            value={reasonForm.BankName}
                                            onChange={(e) => setReasonForm({ ...reasonForm, BankName: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtAccountNo") };
                                            }}
                                            required
                                        />

                                        <InputBox
                                            divclassname="col-md-4 col-sm-6 fields mb-2"
                                            label="Account No"
                                            placeholder="Account No"
                                            id="txtAccountNo"
                                            maxLength='20'
                                            value={reasonForm.AccountNo}
                                            onChange={(e) => setReasonForm({ ...reasonForm, AccountNo: number(e.target.value) })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtIFSCCode") };
                                            }}
                                            required
                                        />
                                        <InputBox
                                            divclassname="col-md-4 col-sm-6 fields mb-2"
                                            label="IFSC Code"
                                            placeholder="IFSC Code"
                                            id="txtIFSCCode"
                                            maxLength='11'
                                            value={reasonForm.IFSCCode}
                                            onChange={(e) => setReasonForm({ ...reasonForm, IFSCCode: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtMobileNo") };
                                            }}
                                            required
                                        />
                                    </> : reasonForm.RefundPaymenMode === "UPI" ? <div className='d-flex align-items-end gap-1'>
                                        <InputBox
                                            divclassname="col-md-4 col-sm-6 fields mb-2"
                                            label="UPI ID"
                                            placeholder="UPI ID"
                                            id="txtUPIID"
                                            maxLength='50'
                                            value={reasonForm.UPIID}
                                            onChange={(e) => setReasonForm({ ...reasonForm, UPIID: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtMobileNo") };
                                            }}
                                            required
                                        />
                                        <span className={`text-danger pb-2`} style={{ cursor: 'pointer' }} >Verify</span>
                                    </div> : <></>}

                                </> : <></>
                            }
                        </div>
                        <div className='mb-2 d-md-flex gap-2 float-end'>
                            <CustomButton
                                id='btnSubmitReason'
                                ButtonIcon='fa-check'
                                ButtonText={`${orderData.Type} Order`}
                                ButtonClass='btn-danger btn-sm float-end'
                               
                                onClick={() => {
                                    if (orderData.Type === "Return" || orderData.Type === "Replace") {
                                        EcomAPIshipmentReturn(orderData.awbNo, orderData.orderNo, orderData.Type);
                                       // HandleOrderAction()
                                    } else if (orderData.dispatch || orderData.deliveryStatus === "DISPATCHED" || orderData.deliveryStatus === "Undelivered" || orderData.deliveryStatus === "Accepted" || orderData.Type === "Cancel") {
                                        EcomAPIshipmentCancellation(orderData.awbNo, orderData.orderNo, orderData.Type);
                                    } else {
                                        HandleOrderAction("Cancel");
                                    }
                                }}

                            />
                            
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <ErrorOutlineIcon style={{ fontSize: "18px" }} />
                            <Link style={{ textDecoration: "underline" }} to={'/cancellation-policy'}> Cancellation Policy</Link>
                        </div>
                    </div>

                </Dialog>
                <ReviewModal
                    openReview={openReview}
                    ProductCode={ProductCode}
                    setReviewData={setReviewData}
                    setState={setState}
                    state={state}
                    orderNo={orderNo}
                    ReviewData={ReviewData}
                    setOpenReview={setOpenReview} />

            </div>
        </section>
    )
}
export default OrderDetails
