import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";

const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];
const UniqueUserId = localStorage.getItem('uniqueUserId');


export const GetOverAllCountAPI = createAsyncThunk(
    'GetOverAllCountAPI',
    async (args, { getState }) => {
        try {
            const authData = getState().authData;
          
           // console.log(authLocalStorage, 'in slisecccccccc countttt', authData )
            let data = await ApiCall(`/api/Web/GetOverAllCount`,
                {
                    LoginUserCode: authData.user.loginUserCode ? authData.user.loginUserCode : authLocalStorage.loginUserCode,
                    Token: authData.user.token ? authData.user.token : authLocalStorage.token,
                    CartToken: authData.uniqueUserId ? authData.uniqueUserId : UniqueUserId
                }
            )
            return data;
        } catch (error) {
            throw new Error('Failed to fetch  GetOverAllCount');
        }
    }
);

export const getCartAndWishlistCountSlice = createSlice({
    name: 'getCartAndWishlistCountSlice',
    initialState: {
        overAllCountDetails: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetOverAllCountAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetOverAllCountAPI.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.status === "SUCCESS") {
                    state.overAllCountDetails = action.payload;
                }  
            })
            .addCase(GetOverAllCountAPI.rejected, (state, action) => {
                state.loading = false;
                state.overAllCountDetails = {}
                state.error = action.error.message;
            });
    }
});

export const {  } = getCartAndWishlistCountSlice.actions;

export default getCartAndWishlistCountSlice.reducer;

