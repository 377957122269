import Swal from 'sweetalert2';
import { Cookies } from 'react-cookie';

let baseUrl;
if (window.location.hostname.indexOf("react.") >= 0) {
    baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}
export const virtualPath = baseUrl;

export const ApiCall = (url, bodyData) => {
    const abortController = new AbortController();
    let data = fetch(`${virtualPath + url}`, {
        method: 'POST',
        body: JSON.stringify(bodyData),
        headers: {
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then((res) => res.json())
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err
        }).finally(() => {
            abortController.abort();
        });
    return data
};

export const AlertBox = (status = '', message = '', focusElementId = '') => {
    if (message !== "No Record Found" && message !== undefined && message !== "") {
        Swal.fire({
            text: message,
            icon: status.toLowerCase(),
            title: status.toUpperCase(),
            confirmButtonColor: '#238914',
            allowOutsideClick: false,
            width: 500,
            didClose: () => {
                if (focusElementId !== "")
                    document.getElementById(focusElementId)?.focus();
            }
        }).then((result) => {
            if (result.isConfirmed) {

            }
        });
    }
}


export const setFocus = (ID) => {
    const element = document.getElementById(ID);
    if (element) {
        element.focus()
    }
}

export const number = (Value) => {
    return Value.replace(/[^0-9]/g, '');
}

export const FetchAutoCompleteData = async (data, Prefix = '', type, contextKey = '', contextKey2 = '', contextKey3 = '') => {
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const loginCodeString = String(LoginUserCode);
    const requestData = {
        Token,
        LoginUserCode: loginCodeString,
        ...data
    };
    let dataList = [];
    try {
        if (Prefix !== null && Prefix !== undefined && Prefix !== '') {
            const abortController = new AbortController();
            const response = await fetch(`${virtualPath}/api/AutoComplete/AutoComplete`, {
                method: 'POST',
                body: JSON.stringify({ ...requestData, Type: type, Prefix: String(Prefix), ContextKey: contextKey, ContextKey2: contextKey2, ContextKey3: contextKey3 }),
                headers: {
                    "Content-Type": "application/json"
                },
                signal: abortController.signal
            });
            const res = await response.json();
            if (res.status === 'SUCCESS' && res.autoCompleteData) {
                dataList = res.autoCompleteData.map(item => item.values);
            } else {
                console.error('Error fetching autocomplete data:', res.message);
            }

            return dataList;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        return [];
    }
};