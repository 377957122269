import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";


export const GetCatagoryData = createAsyncThunk(
    'GetCatagoryData',
    async (args, { getState }) => {
        try {
            const authData = getState().authData.user;
            let data = await ApiCall(`/api/Web/GetCategory`, {
                LoginUserCode: authData.loginUserCode ? authData.loginUserCode : "",
                Token: authData.token ? authData.token : "",
                Source: 'Home'
            })
            return data;
        } catch (error) {
            throw new Error('Some Error has been Occured please try after some time');
        }
    }
);




export const CommonApiSlice = createSlice({
    name: 'CommonApiSlice',
    initialState: {
        getCategoryCart: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //For addToWishListApi
            .addCase(GetCatagoryData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetCatagoryData.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.status === "SUCCESS") {
                    state.getCategoryCart = action.payload;
                }
            })
            .addCase(GetCatagoryData.rejected, (state, action) => {
                state.loading = false;
                state.getCategoryCart = {};
                state.error = action.error.message;
            })
    }
});

export default CommonApiSlice.reducer;
