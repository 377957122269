import { KeyboardBackspace, ShoppingCartCheckout } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '../Common/Card';
import { ApiCall } from '../Common/service';

export const Gift = () => {

    const [viewListData, setViewlistData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];


    const GetGift = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetGift`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'web'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            setViewlistData(data.products)
            setLoading(false)
        } else if (data.status === 'ERROR') {
            setViewlistData([])
            setLoading(false)
        }
    }
    useEffect(() => {
        GetGift();
    }, []);

    return (
        <>
            {loading ?
                <section>
                    <div className='container-fluid'>
                        <Skeleton variant="rectangular" style={{ height: "30px", width: "20%" }} />
                        <div className="row mt-4 mb-4">
                            {(() => {
                                const slides = [];
                                for (let i = 0; i < 8; i++) {
                                    slides.push(
                                        <div className="col-md-4 col-xl-3 mb-3 ml-4" key={i}>
                                            <Skeleton variant="rectangular" style={{ height: "400px", width: "280px" }} />
                                        </div>
                                    );
                                }
                                return slides;
                            })()}
                        </div>
                    </div>
                </section> :
                < section className='pt-5 wishlist'>
                    {viewListData?.length > 0 ?
                        <div className="container-fluid">
                            <div className="d-block d-md-flex justify-content-between align-items-center">
                                <nav className="breadcrumb align-items-center mb-md-0">
                                    <Link className="breadcrumb-item" to='/' style={{ color: '#27225f' }}>Home</Link>
                                    <span>/</span>
                                    <Link className="breadcrumb-item active" style={{ color:'#27225f'}} to='/Gift'>Gifts</Link>
                                </nav>
                            </div>
                            
                            <div className="row mt-4 mb-4">
                                <>
                                    {viewListData?.map((item, index) =>

                                        <div className="col-md-4 col-xl-3 mb-3" key={index}>
                                            <Card Source={'Gift'} item={item} index={index} />

                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                        :
                        <>
                            <div className="container mb-3">
                                <div className="row justify-content-center">
                                    <div className="col-md-5 text-center">
                                        <img src="./images/wishlist_empty.svg" alt="" />
                                        <h3 className='fw-bold text-theme'>Your View Products Awaits</h3>
                                        <p>View items as you browse and they will magically appear here.</p>
                                        <div className="buttons-group d-flex gap-3">
                                            <Link to='/' className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'><KeyboardBackspace /> Browse Our Catalog</Link>
                                            <Link to='/' className='bg-secondary text-white py-2 w-100 border-0 rounded mybtn'><ShoppingCartCheckout /> Go To Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </section>
            }
        </>
    )
}
export default Gift;