import { configureStore } from "@reduxjs/toolkit";
import addToCartAfterloginSlice from "../features/addToCartAfterLogin";
import userSlice from "../features/authSlice";
import buyNowSlice from "../features/buyNowSlice";
import CartAndWishlistCountSlice from "../features/getCartAndWishlistCountSlice";
import wishListSlice from "../features/wishListSlice";
import CommonApiSlice from "../features/CommonApiSlice";


export const store = configureStore({
    reducer: {     
        authData: userSlice,
        addToCartAfterlogin: addToCartAfterloginSlice,
        wishList: wishListSlice,
        CartAndWishlistCount: CartAndWishlistCountSlice,
        buyNow: buyNowSlice,
        commonApiData: CommonApiSlice,
     
    }
})