/// <reference path="components/faq/faq.jsx" />
/// <reference path="components/faq/faq.jsx" />
import { Aboutus } from "./components/Aboutus/Aboutus";
import { BlogDetails } from "./components/Blogs/BlogDetails";
import { Blogs } from "./components/Blogs/Blogs";
import { Cart } from "./components/Cart/Cart";
import { Checkout } from "./components/Cart/Checkout";
import { OrderPlaced } from "./components/Cart/OrderPlaced";
import { ComingSoon } from "./components/Common/ComingSoon";
import Loader from "./components/Common/Loader";
import { Product } from "./components/Common/Product";
import { ProductDetails } from "./components/Common/ProductDetails";
import { Wishlist } from "./components/Common/Wishlist";
import { Contact } from "./components/Contactus/Contact";
import { Error404 } from "./components/Error404";
import { Home } from "./components/Home/Home";
import { Login } from "./components/Login/Login";
import { ResetPasssword } from "./components/Login/ResetPassword/ResetPasswordPage";
import { ResetPassswordSlide1 } from "./components/Login/ResetPassword/ResetPasswordSlide1";
import { SellerRegistration } from "./components/Login/SellerRegistration";
import { Signup } from "./components/Login/Signup/Signup";
import { CancelPolicy } from "./components/Pages/CancelPolicy";
import { PrivacyPolicy } from "./components/Pages/PrivacyPolicy";
import { ReturnandRefundPolicy } from "./components/Pages/ReturnandRefundPolicy";
import { TermsandConditions } from "./components/Pages/TermsandConditions";
import { Myprofile } from "./components/Profile/Myprofile";
import Orders from "./components/Profile/Orders";
import { RecentViewedProducts } from "./components/RecentViewed/RecentViewProduct";
import StorePage from "./components/StorePage/StorePage";
import { TodayDeals } from "./components/TodayDeals/TodayDeals";
import LoginPopup from "./components/Login/LoginPopup";
import OrderDetails from './components/OrderDetails/OrderDetails';
import FAQ from './components/FAQ/FAQ'
import Gift from "./components/Gift/Gift";

const AppRoutes = [
    {
        index: true,
        path: '/',
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/loginPopup',
        element: <LoginPopup />
    },
    {
        path: '/reset-password',
        element: <ResetPasssword />
    },
    {
        path: '/resetPasswordSlide',
        element: <ResetPassswordSlide1 />
    },
    {
        path: '/sellerRegistration',
       // path:'http://react.sagarinfotech.com/ShoppingCartAdmin/',
        element: <SellerRegistration />
    },
    {
        path: '/TodayDeals',
        element: <TodayDeals />
    },
    {
        path: '/BestSeller',
        element: <StorePage />
    },
    {
        path: '/recently-viewed',
        element: <RecentViewedProducts />
    },
    {
        path: '/OrderDetials',
        element: <OrderDetails />
    },
    {
        path: '/profile/orders',
        element: <Orders />,
        protected: true
    },{
        path: '/profile/order',
        element: <Myprofile />,
           protected: true
    },
    {
        path: '/signup',
        element: <Signup />
    },
    {
        path: '/products',
        element: <Product />
    },
    //{
    //    path: '/products-details',
    //    element: <ProductDetails />
    //},
    {
        path: '/:searchEnginePageName/:productCode',
        element: <ProductDetails />
    },
    {
        path: '/wishlist',
        element: <Wishlist /> ,
         protected: true
    },
  
    {
        path: '/stores',
        element: <StorePage />
    },
    {
        path: '/loader',
        element: <Loader />
    },
    {
        path: '/profile',
        element: <Myprofile />,
        protected: true
    },
    {
        path: '/cart',
        element: <Cart />
    },
    {
        path: '/checkout',
        element: <Checkout />,
        protected: true
    },
    {
        path: '/orderplaced',
        element: <OrderPlaced />,
           protected: true
    },
    {
        path: '/about-us',
        element: <Aboutus />
    },
    {
        path: '/contact',
        element: <Contact />
    },
    {
        path: '/blogs',
        element: <Blogs />
    },
    {
        path: '/blogsdetails',
        element: <BlogDetails />
    },
    {
        path: '/sitemap',
        element: <ComingSoon Heading={'Sitemap'} />
    }, {
        path: '/career',
        element: <ComingSoon Heading={'Career'} />
    }, {
        path: '/store-location',
        element: <ComingSoon Heading={'Store Location'} />
    }, {
        path: '/cancellation-policy',
        element: <CancelPolicy />
    }, {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
    }, {
        path: '/return-policy',
        element: <ReturnandRefundPolicy />
    }, {
        path: '/terms-conditions',
        element: <TermsandConditions />
    }, {
        path: '/faqs',
        element: <FAQ />
    }, {
        path: '/gift',
        element: <Gift />
    },
    {
        path: '*',
        element: <Error404 />
    }
];

export default AppRoutes;
