import { Call, ChatBubble } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AlertBox, ApiCall } from '../Common/service';
import { convertToNumber } from '../Common/Input';

export const Contact = () => {
    const [formData, setFormData] = useState({
        "name": "",
        "email": "",
        "phone": "",
        "subject": "",
        "remarks": ""
    });

    const handleContact = async () => {
        let data = await ApiCall(`/api/Web/Contact`, formData)
        if (data.status === 'SUCCESS') {
            await setFormData({
                "name": "",
                "email": "",
                "phone": "",
                "subject": "",
                "remarks": ""
            })
            await AlertBox(data.status, data.message, data.focus)
        } else if (data.status === 'ERROR') {
            AlertBox(data.status, data.message, data.focus)
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="contact">
                    <div className="contact_head">
                        <h1>Contact Us</h1>
                        <nav className="breadcrumb align-items-center">
                            <Link className="breadcrumb-item text-white" to='/'>Home</Link>
                            <span className='text-white'>/</span>
                            <Link className="breadcrumb-item text-white active" to='/profile'>My Profile</Link>
                        </nav>
                    </div>
                    <div className="contact_info">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="d-flex bg-light p-3 rounded mb-3">
                                    <div className="flex-shrink-0">
                                        <Call />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='mb-1 fs-6'>Call Us Directly At
                                        </p>
                                        <h5 className="mt-0 text-theme">(+005) 5896 72 78 79</h5>
                                        <p><b>Hours of Operation:</b> Monday to Friday from 9:30AM to 6:30PM</p>
                                    </div>
                                </div>
                                <div className="d-flex bg-light p-3 rounded">
                                    <div className="flex-shrink-0">
                                        <ChatBubble />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='mb-1 fs-6'>Get your answers quick by chatting with a live agent.
                                        </p>
                                        <h5 className="mt-0 text-theme">Chat wih an agent</h5>
                                        <p><b>Hours of Operation:</b> Monday to Friday from 9:30AM to 6:30PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contact_form p-3">
                                    <h5>Get In Touch</h5>
                                    <div className="d-flex gap-2">
                                        <div className="mb-2 w-100">
                                            <label for="" className="form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" name="" id="txtName"
                                                value={formData.name}
                                                maxLength='100'
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                className="form-control" placeholder="" />
                                        </div>
                                        <div className="mb-2 w-100">
                                            <label for="" className="form-label">Email <span className="text-danger">*</span></label>
                                            <input type="email" name=""
                                                value={formData.email}
                                                maxLength='100'
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                id="txtEmail" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 mb-2">
                                        <div className="mb-2 w-100">
                                            <label for="" className="form-label">Phone <span className="text-danger">*</span></label>
                                            <input type="text" name="" id="txtPhone" className="form-control"
                                                value={formData.phone}
                                                maxLength='10'
                                                onChange={(e) => setFormData({ ...formData, phone: convertToNumber(e.target.value) })}
                                                placeholder="" />
                                        </div>
                                        <div className="mb-2 w-100">
                                            <label for="" className="form-label">Subject <span className="text-danger">*</span></label>
                                            <input type="text" name="" id="txtSubject"
                                                value={formData.subject}
                                                maxLength='100'
                                                onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                                                className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <textarea name="" className='form-textarea w-100'
                                        value={formData.remarks}
                                        maxLength='300'
                                        onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
                                        id="txtRemarks" rows="4"></textarea>
                                    <div className='mt-3'>
                                        <button className='bg-primary text-white py-2 px-4 border-0 rounded mybtn' onClick={handleContact}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
