import React from 'react'
import { Link } from 'react-router-dom'

export const TermsandConditions = () => {
    return (
        <>
            <section className='pt-3 pb-3 pb-md-5' style={{ backgroundColor: '#f8f8f8' }}>
                <div className="container">
                    <nav className="breadcrumb align-items-center">
                        <Link className="breadcrumb-item" to='/'>Home</Link>
                        <span>/</span>
                        <Link className="breadcrumb-item active" to='/return-policy'>Terms and Conditions</Link>
                    </nav>
                    <h3 className='text-center mb-4'>Terms and Conditions</h3>

                    <p>Please read these terms and conditions ("Terms") carefully before accessing or using the Platform (defined hereinafter). These Terms along with the Privacy Policy published on the Platform (" <a className='text-primary' href="/privacy-policy">Privacy Policy</a> ") and other policies (as may be notified/displayed/published on the Platform) constitutes the contract between the Users of this Platform and Company (collectively "Agreement"). By use of the Platform, Users agree to be bound by these Agreement as posted on the Platform from time to time.   </p>

                    <ol>
                        <li>
                            <h6><b>ABOUT THE TERMS</b></h6>
                            <ol>
                                <li><h6><b>What is Indocraftworld.com and who operates it?  </b></h6>
                                    <p>Indocratfworld.com is an application which provides an online marketplace ("Application") where registered Supplier/Sellers ("Supplier/Sellers") can offer to sell their products to registered users of Application including to resellers ("Resellers") and other Users.</p>

                                    <p>The Application and the website at <a href="http://www.Indocraftworld.com">www.Indocraftworld.com</a> ("Website") (collectively, "Platform") are operated by Indo Craft World Foundation ("Company").</p>

                                    <p>The Company’s role is limited to the managing Application and associated marketing, facilitating payment collections, fulfilment, order management, enquiry management and other incidental services to enable the transactions between the Supplier/Sellers and the Reseller ("Services").</p>

                                    <p>Services are not made available on the Website and to avail the same, Users are required to install the Application.</p>

                                </li>

                                <li><h6><b>When are these Terms applicable and binding on User?</b></h6>
                                    <p>The Agreement is applicable to any person when they install, download or even merely visit or access any part of the Platform or utilise the Services, such persons are referred to as users, which include without limitation users who are browsers, Supplier/Sellers, Resellers, merchants, other purchaser or contributors of content (collectively, "User").   </p>

                                    <p>The Agreement between User and Company is effective on the date on which the Application is downloaded/Website is accessed and/or the date on which terms of Agreement are updated, creating a legally binding arrangement between the User and the Company.   </p>

                                </li>

                                <li><h6><b>Whether the terms of this Agreement can be modified? </b></h6>
                                    <p>Users can review the most current version of the Agreement at any time on the Website. Company reserves the right to unilaterally update, change or replace any part of the Agreement by publishing updates or changes on the Platform and such amended provisions of the Agreement shall be effective immediately upon being posted on the Platform.   </p>

                                    <p>It is the responsibility of the Users to check this page periodically for changes. The Users’ continued use of or access to the Application following the posting of any changes constitutes acceptance of those changes.   </p>

                                </li>

                                <li><h6><b> What if the terms of the Agreement are not acceptable to User?   </b></h6>
                                    <p> If the User does not agree with the terms of the Agreement, the User is advised to refrain from using the Platform. By accessing or using the platform, the user irrevocably accepts the Agreement and agrees to abide by the same (as updated from time to time).   </p>

                                </li>

                            </ol>
                        </li>
                        <li>
                            <h6><b>ACCOUNT REGISTRATION, SUSPENSION AND TERMINATION   </b></h6>
                            <ol>
                                <li>
                                    <h6><b>Does a User necessarily need to create an account on the Platform?  </b></h6>
                                    <p>Company does not permit Users to avail the Services on the Platform without prior registration. Users may access the Application by registering to create an account and become a member. The membership is limited for the purpose of buying or selling products, is subject to this Agreement and strictly not transferable.  </p>
                                </li>
                                <li>
                                    <h6><b>For the use of Platform, is a User subject to any eligibility criteria?</b></h6>
                                    <p>The Services on the Platform shall be availed by User(s) who can form legally binding contracts under Indian Contract Act, 1872 and are at least eighteen (18) years of age.</p>
                                    <p>The Company reserves the right to terminate the Users account and / or deny access to the Platform if it is brought to the Company’s notice or if it is discovered that the User does not meet the conditions herein. User(s) accessing or using the Platform represent and warrant that they have the right to access or use the Platform.   </p>
                                </li>
                                <li>
                                    <h6><b>Are there any specific requirements for registering an account on Platform?   </b></h6>
                                    <p>The Users are required to enter a valid phone number while registering on Platform. By such registration, User consents to be contacted by Company via phone calls, SMS notifications, instant messages or other such means of communication inter alia for subscription/services/promotional updates etc. Users may 'opt-out' of such subscription/service/promotional updates either through the ‘opt out’ means provided or by writing to the support team. </p>
                                    <p>It is the responsibility of the Users to provide correct mobile number so that the Company can communicate with the Users via SMS. The Users understand and agree that if the Company sends an SMS but the Users do not receive it because the Users’ mobile number is incorrect or out of data or blocked by the User's service provider, or the Users are otherwise unable to receive SMS, the Company shall be deemed to have provided the communication to the Users effectively. </p>
                                    <p>It is the User’s responsibility to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. </p>
                                </li>
                                <li>
                                    <h6><b>Can User account registered on Platform be suspended or terminated?   </b></h6>
                                    <p>The Company reserves the right to suspend or terminate the account or access to Services (or any part thereof) or block the User on the Application including blocking any amounts due to the User and associated account without notice and the Users will remain liable for all amounts due up to and including the date of termination, if:</p>
                                    <ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
                                        <li>If any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete; and/or</li>
                                        <li>In Company’s assessment, the User has:</li>
                                        <li>Charged an unreasonably high price;</li>
                                        <li>Exhibited unreasonable instances of returns and/or cancellations initiated;</li>
                                        <li>Engaged in actions that are fraudulent, negligent, or derogatory to the Company’s interests;</li>
                                        <li>Failed or is suspected to have failed to comply with any term or provision of the Agreement or applicable law;</li>
                                        <li>User is found to be non-compliant with the Agreement.</li>
                                        <li>Further, where the violation of the Agreement gives rise to criminal or civil action, the Company may at its sole discretion pursue such action.</li>
                                    </ul>
                                    <p className='mt-3'>Without prejudice to the above stated rights of the Company, in case of alleged fraud or other breach of this Agreement by User, Company may at its sole discretion (a) withhold all amounts payable to such User; and (b) impose penalties as the Company may reasonably determine and set off such penalties from the monies payable by Company to such User.</p>
                                </li>
                                <li>
                                    <h6><b>What are User obligations vis-à-vis its registered account on Platform?</b></h6>
                                    <p>Having an account on the Platform gives authenticity to the actions of the User. It means that the User is solely responsible for all activities that occur under its account and that all transactions made by such User are intended for bona fide sale or consumption in the course of their business activities.</p>

                                    <p>Any and every activity undertaken by a User under his/her account shall be the sole responsibility of such User and the Company shall not be liable for such activity in any manner. Hence it shall be the responsibility of the User to treat the user identification code, password and any other piece of information that is provided by the Company, as part of the security procedures, as confidential and not disclose the same to any person or entity other than the Company.</p>

                                    <p>User acknowledges and agrees that having an account on Platform does not grant it any rights on Platform not specifically granted to them by the Company, and that the User has no ownership or other interest in the account. The User understands that all rights in and to the account are and shall forever be owned by and inure to the benefit of the Company.</p>

                                    <p>On registration, the Users may receive a password-protected account and an identification. The Users agree to:</p>
                                    <ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
                                        <li>Maintain the confidentiality of their password, if applicable;</li>
                                        <li>Take full responsibility for all activities by Users accessing the Application through their account;</li>
                                        <li>Immediately notify the Company of any unauthorized use of their account or any other breach of security that they become aware of; and</li>
                                        <li>Ensure that they exit from their account at the end of each session.</li>
                                    </ul>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>PLACING ORDERS AND FINANCIAL TERMS</b></h6>
                            <ol>
                                <li>
                                    <h6 className='mt-3'><b>How does order placement work on the Platform?   </b></h6>
                                    <p>The Application allows Users to place orders for the products listed by Supplier/Sellers on the Application, and the Application, subject to Agreement herein, facilitates the placement of orders for the products by the Users.</p>

                                    <p>On receipt of an order from a User, the Company shall send electronically a confirmation of such order to Supplier/Seller and the User concerned. Further, the Company may inform the User about the availability or unavailability or change in price of the order as informed by Supplier/Seller concerned, from time to time. Confirmation of the order by Supplier/Seller shall be treated as final.</p>

                                    <p>The Company does not own, sell, or resell any products on its own and/or does not control the Supplier/Sellers and only facilitates the transaction between buyers and sellers, including User and Supplier/Seller, as a 'marketplace'. Company makes all reasonable efforts to promptly update the User's account and other information in order to assist facilitate the transaction completion. Hence, Users are required to provide current, complete, and accurate purchase and account information for all purchases made on the Application.</p>

                                    <p>Additionally, fulfilment of orders to their end users/consumers by Reseller shall be the responsibility of the Reseller inter se such end user/consumer. Any add-on service provided by Platform towards such order shall be provided merely as a service provider of such Reseller by the Platform, and accordingly, Platform shall not have any privity of contract with such end user/consumer.</p>

                                </li>
                                <li>
                                    <h6><b> How are the commercial terms fixed on Application?</b></h6>
                                    <p>All commercial/contractual terms of sale are offered by Supplier/Sellers and agreed to between Supplier/Sellers and the Users alone. The commercial/contractual terms include, without limitation, price, date, period and mode of delivery, warranties related to products, etc. Company does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Supplier/Sellers and the Users.</p>

                                    <p>Similarly, in the case of deliveries effected by Resellers using the Platform, the Platform only acts as a service provider for the Reseller facilitating delivery or other service related to an order. Company does not have any control nor does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between Reseller and its end user/consumer.</p>

                                    <p>Policies related to returns/exchanges, penalties, refunds, cancellation will be updated in the Application from time to time. The Company holds the right to change these policies as required in the Application without any permission from the Users.</p>

                                </li>
                                <li>
                                    <h6><b>How does payment and settlement of payment work on the Platform?</b></h6>
                                    <p>The Users acknowledge and agree that the Company may, at the request of the Supplier/Seller or the Reseller, act as the payment agent for the limited purpose of accepting payments on behalf of such Supplier/Sellers and Resellers. The Users understand, accept and agree that the payment facility provided by the Company is neither a banking nor financial service but is merely a facilitator providing a third party payment processor for the transactions on the Application. Further, by providing payment facility, the Company is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price. The Company will not be liable for any charges made by the Users bank in relation to payment of the total amount.</p>

                                    <p>In connection with any order, information such as name, billing address, and credit card information may need to be provided either to the Company or the third party payment processor. If the Users are directed to the third party payment processor, they may be subject to terms and conditions governing use of that third party’s service and that third party’s personal information collection practices. Users are requested to review such terms and conditions and privacy policy before using the Application. In case of Reseller providing billing information, delivery address or other contact information of its end user/consumer to Company or other delivery service provider, Reseller shall ensure that it has necessary consents and approvals from the respective end user/consumer as may be required under applicable law.</p>

                                    <p>Company merely collects the payment on behalf of the Supplier/Seller or Reseller, as the case may be. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes are to be charged and determined by the Supplier/Seller or Reseller. Company holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole liability with respect to any legal issue arising on the taxes payable shall be with the Reseller.</p>

                                    <p>The transaction is bilateral between Supplier/Sellers & Users and/or Reseller & end users/consumers ("User Transactions"), the Company is not liable to charge or deposit any taxes applicable on such transaction.</p>

                                    <p>The User may provide the bank account details to receive any refunds, margins, referrals and/or other payments which may be owed to the User by Indocraftworld. While a User is permitted to update the bank account details, the following conditions will apply: (a) the user cannot edit or update the bank account details if the bank details already provided are valid and there is no pending payment to the User from Indocraftworld; (b) the user cannot edit or update the bank account details if there is a pending transaction which is being processed to the existing bank account; (c) the user cannot edit or update the bank account details if the new account number which is being inserted is already in use in another User’s account. The User can only attempt to edit or update the bank account details three times in a day. If the User fails to update the bank account within three attempts, the User will have to wait for 24 hours to attempt to update the bank account details again. Indocraftworld may also designate a total number of edits or updates a User can carry out to the bank account details in the lifetime of the account holder. In the event that: (a) a User has breached the total limit to edit or update the bank account details and (b) the same bank account is used for more than ten platform accounts, the User will have to contact the customer support in order to update the bank account details again. Indocraftworld reserves the right to change this policy on editing and updating the bank account at its sole discretion.</p>

                                </li>
                                <li>
                                    <h6><b>Whether Company charges User(s) for Services provided by Company on the Platform? </b></h6>
                                    <p>Services on Platform may require payment of charges, rate of which shall be solely at the discretion of the Company and shall be subject to User approval at the time of placing an order on the Platform. Company reserves the right to revise charges towards Service at any time at its sole discretion. The charge, applicable at any given time, will be the charge displayed at the time of purchase/booking of the respective Service (if any) by User on the Platform.</p>

                                    <p>Company reserves the right to introduce additional chargeable services on the Platform including charges for a premium return service, cancellation charges, cash on delivery handling fees etc. </p>
                                </li>
                                <li>
                                    <h6><b>Whether for transacting on Platform, User is required to be registered under the Central or State Goods and Services Tax Legislations ("GST Laws")?</b></h6>
                                    <p>Company is not obligated towards any direct or indirect tax obligation of the User that may arise as a result of User's access or use of Services on the Platform. The requirement for registration and compliances under the GST Laws and other tax laws is the sole responsibility of the User including Reseller, the Company is not liable for any omissions or commissions by such User who acts in violation of the any applicable law. Accordingly, User is advised to seek independent tax advice relating to its business and/or transaction through Platform including whether it is liable for GST registration.   </p>
                                </li>
                                <li>
                                    <h6><b>What are the terms and conditions regarding the offers and benefits provided on the Platform and advertised by Indocraftworld ? </b></h6>
                                    <p>Subject to below and unless otherwise mentioned, all product discounts and offers are by the Supplier/Sellers and not by the Company.</p>

                                    <p>From time to time, we may conduct various types of marketing and promotional campaigns which may include offers, discounts and other promotional offers to be used on our platform. Such offers shall be subject to the terms and conditions which are solely determined by us, and the terms of such discounts and offers may vary for the customers based on factors relating to the customer such as usage of the platform, volume of transactions, time spent on the platform, city, place of residence, time, etc.</p>

                                    <p>We reserve the right to void, discontinue, cancel or reject the use of any of the offers, discounts or promotional offers without any prior intimation.</p>

                                    <p>The offers, discounts and promotional offers may be changed or amended from time to time.</p>

                                    <p>It is your responsibility to review and comply with the terms and conditions governing the offers, discounts and other promotional offers provided on our platform.</p>

                                    <p>Any of the offers, discounts or promotional offers may not be valid when used in conjunction with other promotional offers or vouchers unless explicitly permitted by us or the seller.</p>

                                    <p>The offers, discounts and promotional offers cannot be exchanged for cash and can only be availed in accordance with the terms and conditions of the offers, unless otherwise communicated.</p>

                                    <p>Certain offers, discounts and promotions offered such as first order discount, reactivation discount etc., may be applied at the cart level and in the event that multiple products are purchased in a single transaction, then such offer, discount, promotion may be divided and applied to each product in the cart, in a manner determined by us.</p>

                                    <p>Subject to Indocraftworld’s Cancellation and/or Return, Refund and Replacement policy, if the User: (i) cancels any product which is subject to any promotions, offer or discounts; or (ii) returns any product purchased which is subject to any promotions, offer or discounts, the User will be eligible to receive only the refund of the amount paid by the User on the purchase of such product.</p>

                                    <p>In the event, any product is cancelled or returned in accordance with Indocraftworld's Cancellation and/or Return, Refund and Replacement Policy, then any offer, promotion, discount applied to such product shall be forfeited.</p>

                                    <p>We and/or the Seller shall have no liability with respect to the offers, discounts and promotional offers on the Platform.</p>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b> USE OF THE PLATFORM </b></h6>
                            <ol>
                                <li>
                                    <h6><b>Can User access and use the Platform at any time or could there be any limitations?</b></h6>
                                    <p>Company endeavors to make the Application available 24X7. However, the Company does not represent that access to the Application will be uninterrupted, timely, error-free, free of viruses or other harmful components, or that such defects will be corrected.</p>

                                    <p>Users understand and acknowledge that the use of Application requires internet connectivity and telecommunication links. Users shall bear the costs incurred to access and use the Application and avail Services, and Company shall not, under any circumstances whatsoever, be responsible or liable for such costs.</p>

                                    <p>Company does not warrant that Application will be compatible with all hardware and software which is used by Users.</p>

                                    <p>Application may be under constant upgrades, and some functions and features may not be fully operational. Application is provided on an 'as is' and 'as available' basis. Company expressly disclaims all warranties of any kind, whether express or implied, with respect to the records and other data that is made available by it to Users.</p>

                                    <p>Users shall be solely responsible for damages to their data system or for loss of data arising from download of content from Application. No guidance or information, written or oral, obtained from Company or via Platform, shall constitute any warranty, unless stated otherwise.</p>
                                </li>
                                <li>
                                    <h6><b>Does the Company guarantee performance of the agreement or other arrangements inter se between User(s) or otherwise in respect of products on Platform?</b></h6>
                                    <p>Company, through Platform, is a mere facilitator of the transaction including between Supplier/Seller and User and is not responsible for any non-performance or breach of any contract entered into towards User Transactions. The Company cannot and does not guarantee that the concerned Supplier/Sellers will perform any transaction concluded on the Platform. The Company shall not and is not required to mediate or resolve any dispute or disagreement between the Users concerned including with any other third party.</p>

                                    <p>The Company does not represent any of User or Supplier/Seller, and disclaims any liability with respect to any error or inconsistency with respect to any information relating to such Supplier/Seller or User displayed on the Platform.</p>

                                    <p>The Company does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of its Users. Company shall not be liable for any misuse of information shared by Users with it; or through the Users profile; or with a third party on the Platform, chat rooms, forums, or comments.</p>

                                    <p>Users acknowledge and agree that the Company is not an arbitrator or judge of disputes concerning intellectual property and it cannot, by any means, verify that any Supplier/Seller or Reseller selling or supplying merchandise on/through the Platform have the right to sell the products. Company encourages Users to assist it in identifying listings on the Platform, which, according to the Users’ knowledge or belief infringe their rights or third party rights.</p>

                                    <p>Company does not at any point of time during any transaction between any Supplier/Seller and a User take possession of any product offered nor does it at any point gain title to or have any rights or claims over such products. At no time shall the Company hold any right, title or interest over the products nor shall the Company have any obligations or liabilities in respect of such contract entered into between the Users. Company is not responsible for damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>

                                </li>
                                <li><h6><b>Whether the use of Platform (a) is restricted in any manner; and (b) requires any generic compliances from User? </b></h6>
                                    <p>User should not use the Platform to host, display, upload, download, modify, publish, transmit, update or share any information which:</p>
                                    <p>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
                                    <p>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
                                    <p>is harmful to a child or a minor;</p>
                                    <p>harasses or advocates harassment of another person;</p>
                                    <p>infringes upon or violates any third party’s rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person’s name, email address, physical address or phone number) or rights of publicity;</p>
                                    <p>promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices;</p>
                                    <p>tries to gain unauthorized access or exceeds the scope of authorized access to the Application or to the profiles, blogs, communities, account information, or other areas of the Application or solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;</p>
                                    <p>interferes with another User’s use and enjoyment of the Platform or any third party users enjoyment of similar services;</p>
                                    <p>refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the spirit of these Terms;</p>
                                    <p>violates any law for the time being in force;</p>
                                    <p>impersonates another person;</p>
                                    <p>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, threatens public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or insulting other nations.</p>
                                    <p>contains software viruses or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information; and</p>
                                    <p>directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</p>
                                    <p>When accessing or using the Platform or availing the Services, the User has to comply and ensure the following:</p>
                                    <p>All registration information submitted by User is truthful, lawful and accurate;</p>
                                    <p>User's use of the Application/Platform shall be solely for their use and they shall not authorize others to use the account;</p>
                                    <p>User does not submit, post, upload, distribute, or otherwise make available or transmit any information that: ( i ) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (ii) is bigoted, hateful, or racially or otherwise offensive; (iii) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (iv) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them;</p>
                                    <p>All necessary licenses, consents, permissions and rights are owned by Users and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by the Agreement, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that Users submit, post, upload, distribute or otherwise transmit or make available;</p>
                                    <p>User will not use Platform in any way that is unlawful, or harms the Company or any other person or entity;</p>
                                    <p>User will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity’s use or enjoyment of Application;</p>
                                    <p>User will not use another person’s username, password or other account information, or another person’s name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity;</p>
                                    <p>User will not or attempt to delete or modify any content of Platform, including but not limited to, disclaimers or proprietary notices such as copyright or trademark symbols, logos;</p>
                                    <p>User will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest;</p>
                                    <p>User shall not access Platform without authority or use Platform in a manner that damages, interferes or disrupts, any part of Platform or any equipment or any network on which Platform is stored or any equipment of any third party;</p>
                                    <p>User shall not attempt to gain unauthorized access to any portion or feature of the Application, or any other systems or networks connected to the Platform by any means. User shall not probe, scan or test the vulnerability of Platform nor breach the security or authentication measures on Platform or any network connected to Platform.</p>
                                    <p>User agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of Platform or any transaction being conducted on Platform, or with any other person’s use of Platform. User may not use Platform or any of its content for any purpose that is unlawful or prohibited by this Agreement.</p>
                                    <p>User shall at all times ensure full compliance with the applicable law, as amended from time to time, including that of ( i ) the Information Technology Act, 2000 and the rules thereunder; (ii) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (iii) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to Direct and Indirect Taxes applicable as per current statue in the country) regarding the use of the Application and listing, purchase, solicitation of offers to purchase, and sale of products or Services. User shall not engage in any transaction which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</p>
                                    <p>In order to allow Company to use the information supplied by the Users, without violating any rights or any laws, Users agree to grant Company a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights. Company will only use the information in accordance with this Agreement, applicable to use of Platform and for provision of Services.</p>
                                    <p>Company shall at times and at their sole discretion reserve the right to disable any user identification code or password if any User has failed to comply with any of the provisions of this Agreement. Company shall have all the rights to take necessary action and claim damages that may occur due to User's involvement/participation in any way either on their own or through group/s of people, intentionally or unintentionally in hacking.</p>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>FAIR USAGE POLICY</b></h6>
                            <p>We always strive hard to provide the best experience to our customers on the platform. To ensure that all customers use our platform in good faith, we keep track of customer behaviour which includes maintaining order history and other details relating to the manner of use of our platform. In the event of any abuse of our platform or the policies, which include excessive returns or refusal to accept shipments which are not otherwise wrong or defective, actions such as levying a service fee, discontinuing COD options, etc. may be undertaken to address such issues. Customers whose profiles indicate high volumes of valid transactions on the platform may be offered benefits by Supplier/Sellers, from time to time, based on their discretion and policies.   </p>
                        </li>
                        <li>
                            <h6><b>ACCURACY AND COMPLETENESS OF INFORMATION ON PLATFORM</b></h6>
                            <ol>
                                <li>
                                    <h6><b>What is the accuracy and completeness of all information displayed on the Platform?</b></h6>

                                    <p>Company takes all endeavours to the best of its efforts to keep information on the Platform accurate. However, the material and content on the Platform is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or timely sources of information. Reference to paragraph 5.2 below, User will agree that a majority of content including products displayed on Platform are provided by the respective Supplier/Sellers, who shall at all times be responsible for provision of information related to such products listed by them. Apart from reasonable checks to ensure general hygiene of Platform, Company does not exercise any control over such content or information.</p>

                                    <p>Company undertakes no obligation to update, amend or clarify information in the Platform, including without limitation, pricing information, except as required by law. Company does not own any responsibility or obligation whatsoever towards either ensuring the accuracy of the information provided by the Users. Any reliance on the material on Platform is at the Users’ own risk.</p>

                                    <p>Platform may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. The Company reserves the right to modify the contents of Platform at any time, but has no obligation to update any information on Platform. User is solely responsible to monitor changes to the information on Platform. No specified update or refresh date applied to Platform, should be taken to indicate that all information on Platform or pertaining to the Services have been modified or updated.</p>

                                    <p>Occasionally there may be information on Platform that contains typographical errors, inaccuracies or omissions that may relate to information pertaining to the products, pricing, promotions, offers, shipping charges, transit times and availability. Company reserves the right to correct any errors, inaccuracies or omissions, and to change or update information if any information on Platform is inaccurate at any time without prior notice.</p>

                                    <p>The Information is provided 'as is' with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of the Information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. Nothing contained in this Agreement shall to any extent substitute for the independent investigations and the sound technical and business judgment of User.</p>
                                </li>
                                <li>
                                    <h6><b>Is information related to products on Platform provided by the Company? </b></h6>
                                    <p>Not all information on the Platform is provided by Company. From time to time, Users who are Supplier/Sellers provide information relating to the products proposed to be sold by them and are hence responsible for the same. In this connection, Supplier/Sellers undertake that all such information shall be accurate in all respects. Supplier/Sellers are discouraged from and should not exaggerate or overemphasise the attributes of such products so as to mislead Users in any manner.</p>

                                    <p>Company reserves the right, but has no obligation, to monitor the materials posted on Platform. Company, however, has the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit of these Terms. In no event shall Company assume any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on Platform.</p>

                                    <p>Supplier/Sellers take sole responsibility for the correctness of the details pertaining to specifics (such as quality, value, saleability, etc.) of the products proposed to be sold or offered to be sold or purchased on Platform. Company does not implicitly or explicitly support or endorse the sale or purchase of any products nor provide any warrantee/guarantee of the products sold to Users, and in no event shall such products be the responsibility of Company. Company does not represent or warrant that the information available on Platform will be correct, accurate or otherwise reliable.</p>

                                    <p>Company is not responsible for any inaccuracy, incompleteness or outdated information made available on the Application, either provided by any User including Supplier/Sellers.</p>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>LISTING AND SELLING </b></h6>
                            <ol>
                                <li>
                                    <h6><b>As Supplier/Seller, in addition to this Agreement, what other terms is a User required to abide by?</b></h6>
                                    <p>Supplier/Sellers, in addition to this Agreement are also bound by applicable laws of India, including without limitation, the following laws:</p>

                                    <ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
                                        <li>The Legal Metrology Act, 2009 and Legal Metrology (Packaged Commodities) Rules, 2011 (Packaging Rules);</li>
                                        <li>Drugs and Cosmetics Act, 1940 and Drugs and Cosmetics Rules, 1945 (D&C Rules);</li>
                                        <li>Food Safety and standard Act, 2006, Food Safety and Standard (Packaging and labelling) Regulation 2011, (FSS Packaging Regulation) Food Safety and Standard (Food Product Standard and Food Addictive) Regulation, 2011 (FSS Standard Regulations) and Food Safety and Standard (food or Health Supplements, Nutraceuticals, Food for Special Medical Purpose, Functional Food and Novel Food) Regulation 2016 (FSS Supplement Regulation).</li>
                                    </ul>

                                    <p className='mt-3'>As per above mentioned statutes and regulations and any other relevant law in place during the tenure of this association, Company understands that there is an obligation on Supplier/Seller to ensure that the package in which the products are sold complies with labelling and packing requirements and other laws that may be prescribed in this regard. Hence, it shall be the sole responsibility of Supplier/Seller to comply with applicable laws and the Company shall not be held responsible in any manner. Supplier/Sellers shall indemnify Company and Platform for any harm or loss in relation to contravention of above regulations or other applicable laws.</p>

                                </li>
                                <li>
                                    <h6><b>When can the Supplier/Sellers get their products listed?</b></h6>
                                    <p>Supplier/Sellers are permitted to list products for sale on the Application in accordance with the terms mentioned in this Agreement and other contract entered into with Company for the said purpose, wherein other rights and obligations of the parties are defined in detail.</p>
                                    <p>By listing its products on the Platform, the Supplier/Sellers represent and warrant that they are legally capable to sell or list the products on Platform; and the listed items do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of any third party. Supplier/Sellers and Users agree that the Company is not responsible for the breach of the same.   </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>USER INFORMATION AND THRID PARTY TOOLS/LINKS  </b></h6>
                            <ol>
                                <li>
                                    <h6><b>What information is collected from the User? How does Company deal with the information provided to it by a User while using Platform?</b></h6>
                                    <p>Company collects various types of information, some information is non-personal information and some is personal information.
                                        <br /><br />
                                        All information about Users that are collected, stored or transmitted in any way on Platform is processed for facilitating various operations on Platform, including registration, order placement, listing, or payments.
                                        <br /><br />
                                        For a more comprehensive understanding, Users are encouraged to view the Platform’s Privacy Policy available on the Platform. </p>
                                </li>
                                <li>
                                    <h6><b>Does the Company use Third Party tools on Platform?</b></h6>
                                    <p>The Company may provide User with access to third-party tools over the Platform which Company neither monitors nor has any control nor input. User acknowledges and agrees that access to such tools is on an 'as is' and 'as available' basis, without any warranties, representations or conditions of any kind and without any endorsement by Company. Company shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>
                                    <p>Any use by the User of the optional tools offered through the Application/Platform is entirely at its own risk and discretion and it is the responsibility of User that it ensures that it is familiar with and approves the terms on which such tools are provided by the relevant third-party provider(s). </p>
                                    <p>The Company may from time to time, offer new features through Platform which may include the use new third-party tools and resources. Such new features shall also be subject to this Agreement. Complaints, claims, concerns, or questions regarding third-party tools or third party websites should be directed to the third-party.</p>
                                </li>
                                <li>
                                    <h6><b>Does Company use third party links or third party tools on Platform? Are these links and tools accurate and secure? </b></h6>
                                    <p>Certain content or products available via the Platform may include materials from third-parties. Third-party links on the Application/Platform may direct User to third-party websites that are not affiliated with the Company. The Company is not responsible for examining or evaluating the content or accuracy and does not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
                                    <p>Company is not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites regardless of the existence of any third party link on Platform. Please review carefully such third-party’s policies and practices and make sure to understand them before engaging in any transactions.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>INTELLECTUAL PROPERTY (IP) AND IP INFRINGEMENT</b></h6>
                            <ol>
                                <li>
                                    <h6><b>Can User use the content published on Platform such as " Indocraftworld " mark when doing business with other parties?</b></h6>
                                    <p>Users may not use any trademark, service mark or logo of any independent third parties without prior written approval from such parties.</p>

                                    <p>"Indocraftworld" and related icons and logos whether registered or unregistered are the trademarks of the Company and are protected under applicable copyright, trademark and other proprietary and intellectual property laws. Users’ unauthorized adoption copying, modification, use or publication of these marks is strictly prohibited.</p>

                                    <p>Users must not modify the paper or digital copies of any materials printed or downloaded in any way, and they must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>

                                    <p>Users must not use any part of the materials on Platform for commercial purposes without obtaining a licence to do so from Company. All rights, not otherwise claimed under this Agreement by Company are hereby reserved.</p>

                                    <p>User understands that Platform and software embodied within Platform may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by Company or other parties that facilitate the same. User agrees that it will not attempt to override, disable, circumvent or otherwise interfere with any such security components and usage rules embedded in the Platform.</p>

                                </li>
                                <li>
                                    <h6><b>How does the Company deal with IP infringement?</b></h6>
                                    <p>Any trademark, word mark or intellectual property of any User(s) or Supplier/Seller(s) belongs to such User(s)/Supplier/Seller(s) alone, and Company has no right or claim over the same.</p>

                                    <p>Company reserves the right in its sole discretion to remove any material/content/photos/offers displayed on the Platform which in Company’s reasonable belief is unlawful or could subject Company to liability or is in violation of this Agreement or is otherwise found inappropriate in the Company’s opinion. Company reserves the right to cooperate with any investigation in this regard.</p>

                                    <p>Company reserves the right to suspend or terminate the account of a User as deemed appropriate by it. Users agree that the Company shall have no liability to any Users, including liability in respect of consequential or any other damages, in the event Company takes any of the actions pursuant to allegations of IP infringement.</p>

                                    <p>Users acknowledge and agree that Company is not an arbitrator or judge of disputes concerning intellectual property and it cannot, by any means, verify that any Supplier/Seller selling or supplying merchandise on the Platform have the right to sell the products. Company encourages Users to assist it in identifying listings on the Platform, which, according to Users’ knowledge or belief infringe their rights or third party rights.</p>

                                    <p>The delisting of product from Platform is to safeguard Company’s interest, by taking down a listing, Company does not and cannot be deemed to be endorsing a claim of infringement and further in those instances in which Company declines to take down a listing, Company does not and cannot be deemed to be endorsing that the listing is not infringing of third party rights or endorsing any sale or supply of merchandise or services pursuant to or on account of such listing.</p>

                                    <p>We request you to review the Intellectual Property Policy available on the Application for more information.</p>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>DISCLAIMER AND LIABILITIES </b></h6>
                            <ol>
                                <li>
                                    <h6><b>What are the standard disclaimers in relation to the Platform and the Services?</b></h6>
                                    <p>Company, in no event, is or will be liable to User including the Reseller or anyone claiming through a User in respect of product or other User Transaction under contract, negligence, strict liability or other legal or equitable theory for any special, incidental, indirect, consequential, exemplary or punitive damages, loss of goodwill, loss of revenue, loss of opportunity, loss of anticipated profits, whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not Company has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty or negligence or any other claim arising out of or in connection with the use of or access of Platform. </p>

                                    <p>Company shall not be liable for: any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation any financial losses, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability or otherwise, arising from the use of Platform, or for any other claim related in any way to the use of the Application, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Application/Platform or any content posted, transmitted, or otherwise made available via the Application/Platform, even if advised of their possibility.</p>

                                    <p>Users shall be solely responsible for damages, if any, to their data system or for loss of data arising from download of content from Platform. No guidance or information, written or oral, obtained from Company or via the Platform, shall constitute any warranty, unless stated otherwise. </p>

                                </li>
                                <li>
                                    <h6><b>What are the disclaimers regarding advertisements (including any information or offer thereunder) contained on, distributed through, or linked, downloaded or accessed from Platform (“Platform Advertisements”)?</b></h6>
                                    <p>Advertisements in Platform are intended, solely to provide general information for the personal use of the user(s). The Company does not represent, warrant or endorse in any manner the accuracy or reliability of the Platform Advertisements. The Company accepts no responsibility or liability in relation to the Platform Advertisements including without limitation on account of your use or reliance placed by you on such Subject Information.</p>

                                    <p>The Platform Advertisements are advertised / displayed at the behest of the advertisers. The Company does not by itself create such content and neither does it exercise any control over the content that is displayed by the advertisers. The advertisers are third parties over which Indocraftworld does not have any direct or indirect control. The Company does not make any representation, warranty, recommendation, guarantee in respect of the content of the Advertisements as well as its subject matter and the products/services being advertised (including without limitation with respect to suitability, merchantability, reliability, availability or quality of the product/service) nor does the Company implicitly or explicitly support or endorse the sale or purchase of any products/services which are subject matter of the advertisements or are referred therein. The Company accepts no liability for any error, inaccuracy or omission of third parties and advertisers in this regard.</p>

                                    <p>The correspondence or business dealings of Users with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the User and such advertiser. The Company shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</p>

                                    <p>In the event, a link is displayed on the Platform Advertisement and if you select to click on a link which leaves Platform, you will be redirected to a third-party website that is not owned, operated, or controlled by The Company or its affiliates. The link is provided solely for the convenience of the User and may assist the User in locating other useful information on the internet. The third-party website is governed exclusively by its respective policies over which we have no control. You bear the sole risk in proceeding to access the contents, products/services of the third-party website and links provided therein.</p>

                                    <p>The User shall not hold the Company responsible and the Company disclaims any liabilities, losses, damages, expenses, claims or injury arising out of or in connection with: (i) the advertisements displayed on Platform; (ii) contents of the Platform Advertisement; (iii) representations and statements made by the advertiser; (iv) subject matter of the Platform Advertisements and the products/services referred thereunder (including without limitation on account of suitability, merchantability, reliability, availability or quality of the product/service); and/or (v) Platform Advertisement being misleading and/or not in compliance with applicable laws.</p>

                                </li>
                                <li>
                                    <h6><b>What are the disclaimers regarding advertisements (including any information or offer thereunder) of products listed on the Platform and shown on other media and forums (including print media, digital platforms etc.,) (“Other Advertisements”)? </b></h6>
                                    <p>The images of the products shown in Other Advertisements are for illustrative purposes and creative representation only and may vary from the actual products.</p>

                                    <p>Unless otherwise mentioned, all the product related deals/discounts mentioned in Other Advertisements are offered by the participating sellers/brands to the total exclusion of the Company.</p>

                                    <p>All Other Advertisements with details of starting prices for a product category are for reference only and the actual price of the product may vary.</p>

                                    <p>The scenes, characters, references in brand films and videos are purely fictional and only for representational purposes.</p>

                                    <p>The offers, discounts and other promotional offers displayed in Other Advertisements may vary for the customers based on factors relating to the customer such as usage of the platform, volume of transactions, time spent on the platform, city, place of residence, time, etc.</p>

                                    <p>Indocraftworld and/or the Supplier/Sellers reserves the right to amend or discontinue any of the offers/deals/discounts at their sole discretion and without any prior intimation to any User.</p>

                                </li>
                                <li>
                                    <h6><b>What happens to User order in case of a lockdown or other force majeure event?</b></h6>
                                    <p>Company shall not be liable for any damages whatsoever arising out of force majeure or other similar circumstances, directly or indirectly affecting Company and/or the Platform. Examples of force majeure events include without limitation real or potential labour disputes, governmental actions, war or threat of war, sabotage, civil unrest, demonstrations, fire, storm, flooding, explosion, earthquake, epidemic or pandemic, provisions or limitations of materials or resources, inability to obtain the relevant authorization, accident, and defect in electricity or telecommunication network.</p>

                                    <p>Force majeure or other events beyond the Company’s control, hindrance, delay or complication in the maintenance of the Platform entitles the Company to suspend or limit the Platform until further notice. </p>

                                </li>
                                <li>
                                    <h6><b>Under what circumstances may User be liable for any damages to Company?</b></h6>
                                    <p>User shall indemnify, defend, and hold harmless Company and its subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, Supplier/Sellers, interns and employees, from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs, and expenses (including legal and statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Company that arise out of, result from, or in connection with:</p>

                                    <ol>
                                        <li>User’s breach of this Agreement;</li>
                                        <li>any claims made by any third party due to, or arising out of, or in connection with User’s use of Platform including by end users/consumers of Reseller(s);</li>
                                        <li>the User’s violation of any rights of another, including intellectual property rights; and</li>
                                        <li>the User’s violation of any applicable laws.</li>
                                    </ol>

                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>COMMUNICATION</b></h6>
                            <ol>
                                <li>
                                    <h6><b>How to contact Company in case of any queries regarding this Agreement or grievances relating to Platform? </b></h6>
                                    <p>All queries, concerns or questions about the Agreement should be sent to Company at query@Indocraftworld.com or legalsupport@Indocraftworld.com. Anmol Bakshi is the designated Grievance Officer in respect of this Agreement . Any complaints or concerns with regard to the Platform or any breach of this Agreement can be directed to the Grievance Officer in writing at the following address: F/F, KH NO 101, BLOCK L, (Adjacent Hotel Delhi Airport Plaza) Near Radison Blue, Mahipalpur extn. New Delhi 110037.  </p>
                                </li>
                                <li>
                                    <h6><b>How will the Company contact User?</b></h6>
                                    <p>All notices or demands to or upon a User(s) shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last-known correspondence, fax or email address provided by User(s) on the Platform, or by posting such notice or demand on an area of the Platform that is publicly accessible.     </p>
                                    <p>Notice to a User(s) shall be deemed to be received by such User(s) if and either when sent to User at the address, email or other communication details provided by such User at the time of registration, whether in physical or electronic form, has been sent to such User(s), or immediately upon publishing of such notice on an area of the Platform that is publicly accessible.</p>
                                </li>
                                <li>
                                    <h6><b>In case of a call from a person asking for access to User account registered with Company, what should User do?</b></h6>
                                    <p>Company urges the users to beware of fake offers and fraudulent callers/messengers who may impersonate themselves as representatives of the Company. The Company’s authorised representatives will never contact the Users to demand money for prizes or ask for password/PIN/CVV. In the event you are asked for confidential details by anyone posing as the Company’s representatives, please ask them to communicate with you through email and only respond to emails from Indocraftworld.com domain. Please see our Anti Phishing communication available on the Platform.</p>
                                </li>
                                <li>
                                    <h6><b>Can User disclose its communication through calls with the Company to third parties?</b></h6>
                                    <p>All calls to the Company are completely confidential. However, the Users’ calls may be recorded to ensure quality of service. Further, for training purpose and to ensure excellent customer service, calls from the Company may be monitored and recorded.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h6><b>MISCELLANEOUS PROVISIONS APPLICABLE TO AGREEMENT</b></h6>
                            <ol>
                                <li>
                                    <p>This Agreement is governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Agreement or the Platform shall be subject to the jurisdiction of the courts at New Delhi , India.     </p>
                                </li>
                                <li>
                                    <p>Company shall have the right to assign its obligations and duties in this Agreement to any person or entity.</p>
                                </li>
                                <li>
                                    <p> The failure of Company to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.</p>
                                </li>
                                <li>
                                    <p>Platform is controlled and operated from India and Company makes no representation that the content, information or materials made available herein are appropriate or will be available for use in other locations. Access and use of this Platform from outside India is entirely at User's sole risk and User agrees and undertakes to be responsible for compliance with all applicable local laws and agrees to release, discharge and absolve Company from any liability or loss in this respect.</p>
                                </li>
                                <li>
                                    <p>Company reserves the right to introduce and initiate new features, functionalities and components to Platform and/or change, alter, modify, suspend, discontinue or remove the existing ones without any prior notice to you. Further, Company is entitled to discontinue (either permanently or temporarily) one or more of the Services provided or terminate the Platform or charge for Service which were early free of cost, without any prior notice to User.   </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </section>
        </>
    )
}
