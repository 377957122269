import { ButtonBase } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const OrderPlaced = () => {

    let url = window.location.search;
    const queryString = new URLSearchParams(url);
    const OrderNo = queryString.get('orderNo');
    return (
        <>
            <section>
                <div className="container text-center">
                    <div className="check_svg text-center">
                        <svg
                            width="115px"
                            height="115px"
                            viewBox="0 0 133 133"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                id="check-group"
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                            >
                                <circle id="filled-circle" fill="#07b481" cx={66.5} cy={66.5} r={54.5} />
                                <circle id="white-circle" fill="#FFFFFF" cx={66.5} cy={66.5} r={55.5} />
                                <circle
                                    id="outline"
                                    stroke="#07b481"
                                    strokeWidth={4}
                                    cx={66.5}
                                    cy={66.5}
                                    r={54.5}
                                />
                                <polyline
                                    id="check"
                                    stroke="#FFFFFF"
                                    strokeWidth={10}
                                    points="41 70 56 85 92 49"
                                />
                            </g>
                        </svg>
                    </div>
                    {
                        OrderNo && <h5 className='my-2'>Order No. #{OrderNo}</h5>
                    }
                    <h1 className='my-2'>Your Order Successfully Placed!</h1>
                    <p>We'll send you a shipping confirmation email
                        as soon as your order ships.
                    </p>
                    <div className="product_add_count border-0 d-flex align-items-center justify-content-center gap-2 m-0">
                        <ButtonBase><Link to='/profile/order' className='bg-primary text-white border-0 rounded addbtn mybtn'>View Orders</Link></ButtonBase>
                        <ButtonBase><Link to='/' className='bg-secondary text-white border-0 rounded buybtn mybtn'>Continue Shopping</Link></ButtonBase>
                    </div>
                </div>
            </section>
        </>
    )
}
