import { KeyboardBackspace, ShoppingCartCheckout } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetWishListDataApi } from '../../features/wishListSlice'
import Card from './Card'
import Loader from './Loader'


export const Wishlist = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authData);
    const { wishListData } = useSelector((state) => state.wishList);
    const wishListDataLoading = useSelector((state) => state.wishList.loading);

    useEffect(() => {
        if (user) {
            dispatch(GetWishListDataApi(user));
        }
    }, [dispatch, user]);

    if (wishListDataLoading) {
        return <Loader />;
    }


    return (
        <section className='py-5 wishlist'>
            {wishListData?.length > 0 ?
                <div className="container-fluid">
                    <div className="d-block d-md-flex justify-content-between align-items-center">
                        <nav className="breadcrumb align-items-center mb-md-0">
                            <Link className="breadcrumb-item" to='/'>Home</Link>
                            <span>/</span>
                            <Link className="breadcrumb-item active" to='/wishlist'>Wishlist</Link>
                        </nav>                  
                    </div>
                    <div className="row mt-4">
                        {wishListData && wishListData?.map((item, index) =>
                            <div className="col-md-4 col-xl-3 mb-3" key={index}>
                                <Card Source={'Wishlist'} item={item} index={index} />
                            </div>
                        )}
                    </div>
                </div>
                :
                <>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-5 text-center">
                                <img src="./images/wishlist_empty.svg" alt="" />
                                <h3 className='fw-bold text-theme'>Your Wishlist Awaits</h3>
                                <p>Add items to your wishlist as you browse and they will magically appear here.</p>
                                <div className="buttons-group d-flex gap-3">
                                    <Link to='/' className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'><KeyboardBackspace /> Browse Our Catalog</Link>
                                    <Link to='/cart' className='bg-secondary text-white py-2 w-100 border-0 rounded mybtn'><ShoppingCartCheckout /> Go To Your Cart</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }  
        </section>
    )
}
