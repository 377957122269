import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { AlertBox, ApiCall, setFocus } from '../Common/service'
import AddressForm from './AddressForm'


// Handle Get Address List
export const GetAddresslists = async (tokenData, setState) => {
    const data = await ApiCall(`/api/Web/GetCustomerAddress`, { ...tokenData })
    if (data.status === 'SUCCESS') {
        setState(data.data)
    } else if (data.status === 'ERROR') {
        //  AlertBox(data.status, data.message)
    }
}


export const Address = ({ heading, selectedAddress, setSelectedAddress, setTotalAddress, noteShow, tokenData, addressList, setAddressList }) => {
    const [openAddressAdd, setOpenAddressAdd] = useState(false);
    const [focuss, setFocuss] = useState(false);
    const [editAddress, setEditAddress] = useState()
   
    const [formData, setFormData] = useState({
        customerAddressID: "",
        customerName: "",
        countryCode:"",
        mobileNo: "",
        pincode: "",
        state: "",
        address: "",
        locality_Town: "",
        city: "",
        typeOfAddress: "",
        isOpenSaturday: false,
        isOpenSunday: false,
        isDefaultAddress: false,
    })

    const handleClickOpen = (edit) => { 
        setEditAddress(edit)
        setOpenAddressAdd(true);     
        HandleClear();
        
    };

    const handleClose = () => {
        setFocuss(false)

        setOpenAddressAdd(false);
        HandleClear();
    };



    // Handle Edit Address List
    const HandleEditAddress = async (id, edit) => {
        const data = await ApiCall(`/api/Web/EditCustomerAddressMaster`, { ...tokenData, CustomerAddressID: id })
        if (data.status === 'SUCCESS') {
            handleClickOpen(edit)
            setFormData({ ...data })
        } else if (data.status === 'ERROR') {
            AlertBox(data.status, data.message)
        } else {
            AlertBox(data.status, data.message)
        }
    }

    // Handle Delete Address List
    const handleDelete = async (id) => {
        const data = await ApiCall(`/api/Web/DeleteCustomerAddressMaster`, { ...tokenData, CustomerAddressID: id })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            await GetAddresslists(tokenData, setAddressList);;
        } else if (data.status === 'ERROR') {
            AlertBox(data.status, data.message)
        } else {
            AlertBox(data.status, data.message)
        }
    }
    const HandleDeleteAddress = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id)
            }
        });
    }

    // Handle Add Address
    const handleAddAddress = async () => {
        const data = await ApiCall(`/api/Web/InsertUpdateCustomerAddressMaster`, { ...formData, ...tokenData })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            handleClose()
            await GetAddresslists(tokenData, setAddressList);
        } else if (data.status === 'ERROR') {
            toast.error(data.message)
            setFocus(data.focus)
        }
    }

    // Handle Get Pincode wise State
    const GetStatCity = async (value) => {
        const data = await ApiCall(`/api/AutoComplete/GetStateCityPinCodeWise`, { Pincode: value ? value : '', ...tokenData })
        if (data.status === 'SUCCESS') {
            setFormData({ ...formData, pincode: value ? value : '', city: data.city, state: data.state })
        } else if (data.status === 'ERROR') {
            //   toast.error(data.message)
        }
    }

    // handle Clear 
    const HandleClear = () => {
        setFormData({
            ...formData,
            customerAddressID: "",
            customerName: "",
            countryCode:"+91",
            mobileNo: "",
            pincode: "",
            state: "",
            address: "",
            locality_Town: "",
            city: "",
            typeOfAddress: "",
            isOpenSaturday: false,
            isOpenSunday: false,
            isDefaultAddress: false,
        })
        GetAddresslists(tokenData, setAddressList);
    }   
    useEffect(() => {
        if (focuss) {
            setFocus("txtFirstName")
        }

    }, [focuss])

    useEffect(() => {
        let data = addressList?.filter(item => item.isDefaultAddress)
         setSelectedAddress && setSelectedAddress(data && data[0]?.customerAddressID) 
    }, [addressList])


    return (
        <>
            <div className="address_tab">
                <div className="tab_head d-flex justify-content-between align-items-center border-bottom">
                    <h6 className='m-0'>{heading ? heading : `My Addresses (${addressList ? addressList?.length : "0"})`}</h6>
                    <div>
                        <span>Add a new address :</span>
                        <IconButton onClick={async () => {

                            await handleClickOpen()
                            await setFocuss(true)

                        } 
                        }>
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="tab_body py-3">
                    {addressList?.map((item, index) =>
                        <label htmlFor={`address${index}`} className='d-flex' key={`address_${index}`} onClick={() => setSelectedAddress(item.customerAddressID)}>
                            <input type="radio"
                                name='addressselect'
                                id={`address${index}`}
                                onChange={(e) => setSelectedAddress(e.target.checked ? item.customerAddressID : item.customerAddressID)}
                                checked={selectedAddress === item.customerAddressID || item.isDefaultAddress ? true : false}
                            />
                            <div className="d-flex justify-content-between gap-2 w-100 adrss_body position-relative">
                                <div>
                                    <div className="circle_radio">
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <p className='m-0 customerName me-3'>{item.customerName} </p>
                                        {item.isDefaultAddress && < div className="adrs_type">
                                        <span>DEFAULT ADDRESS</span>
                                        </div>}
                                    </div>
                                    <p className='m-0'>{item.address}, {item.state}, {item.city} - {item.pincode}</p>
                                    {selectedAddress === item.customerAddressID && item.note && noteShow && (
                                        <p
                                            className='m-0 mt-2'
                                            style={{
                                                border: "1px solid #dc3545",
                                                backgroundColor: "#ffeef3",
                                                padding: "10px",
                                                borderRadius: "10px"
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-triangle-exclamation me-2"
                                                style={{ color: "#fab86a" }}
                                            ></i>
                                            {item.note}
                                        </p>
                                    )}

                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <button style={{width:'30px',height:'30px'}} className='bg-primary text-white py-1 border-0 rounded mybtn' onClick={() => HandleEditAddress(item.customerAddressID, 'edit')}><i class="fa-regular fa-pen-to-square"></i></button>
                                    {!item.isDefaultAddress && <button style={{width:'30px',height:'30px'}} className='bg-danger text-white py-1 border-0 rounded mybtn' onClick={() => HandleDeleteAddress(item.customerAddressID)}><i class="fa-regular fa-trash-can"></i></button>}
                                </div>
                            </div>
                        </label>
                    )}
                </div>
            </div>

            <AddressForm
               
                formData={formData}
                tokenData={tokenData}
                setFormData={setFormData}
                handleAddAddress={handleAddAddress}
                GetStatCity={GetStatCity}
                openAddressAdd={openAddressAdd}
                setOpenAddressAdd={setOpenAddressAdd}
                handleClose={handleClose}
                editAddress={editAddress}
            />
        </>
    )
}
