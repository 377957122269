import { Dialog, Skeleton, Tooltip, Zoom } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addToCartApi } from '../../features/addToCartAfterLogin';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { baseUrl } from '../../index';

const RequiredProductModal = ({ Source, Product, showRequiredProductModal, setShowRequiredProductModal, toggleQuickView }) => {
    const [requiredAddToCartItem, setRequiredAddToCartItem] = useState(Product.requiredOtherProducts);
    const dispatch = useDispatch();

    const addToRequiredProducts = (product) => {
        const findIndex = requiredAddToCartItem.findIndex(
            (item) => item.productCode === product.productCode
        );
        if (findIndex === -1) {
            setRequiredAddToCartItem([...requiredAddToCartItem, product]);
        }
    };
    const removeFromRequiredProducts = (item) => {
        const updatedCart = requiredAddToCartItem.filter((cartItem) => cartItem.productCode !== item.productCode);
        setRequiredAddToCartItem(updatedCart);
    };

    const handleSkip = (e) => {
        e.preventDefault()
        const productWithoutRequired = { ...Product, requiredOtherProducts: null };       
        dispatch(addToCartApi({ productCode: productWithoutRequired.productCode, requiredOtherProducts: [], Source: Source }))
                .then(async (response) => {     
                    toast.success(response.payload.message)
                    dispatch(GetOverAllCountAPI())
                    setShowRequiredProductModal(false);
                    toggleQuickView(false)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                    setShowRequiredProductModal(false);
                });      
    }
    const handleAddProductsInCart = (e) => {
        e.preventDefault()
        const productWithRequired = { ...Product, requiredOtherProducts: requiredAddToCartItem };
        const res = requiredAddToCartItem.length === 0 ? Product.requiredOtherProducts.map((ele) => ele.productCode) : requiredAddToCartItem.map((ele) => ele.productCode)
        dispatch(addToCartApi({ productCode: productWithRequired.productCode, requiredOtherProducts: res, Source: Source }))
                .then(async (response) => {
                    toast.success(response.payload.message)
                    dispatch(GetOverAllCountAPI())
                    setShowRequiredProductModal(false);
                    toggleQuickView(false)
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                    setShowRequiredProductModal(false);
                });      
    }
 
    return (
        
        <>
            <Dialog
                open={showRequiredProductModal}
                fullWidth={true}
                maxWidth='lg' aria-labelledby="responsive-dialog-title" >
                <div classname='p-2 m-2 h-100 w-100'>
                    <div className='bg-light position-sticky top-0 z-3'>
                        <h1 className='p-2'>Required Products</h1>
                        <div className="close_btn d-flex gap-2">
                            <button className='bg-success border-0 text-light  py-1 px-2 rounded' onClick={(e)=>handleAddProductsInCart(e) }   ><i class="fa-solid fa-check me-2"></i>Add Products in Cart</button>
                            <button className='bg-primary border-0 text-light  py-1 px-2 rounded' onClick={(e)=>handleSkip(e)}  ><i class="fa-solid fa-arrow-right me-2"></i>Skip</button>
                            <button className='bg-danger border-0 text-light  py-1 px-2 rounded' onClick={() => setShowRequiredProductModal(false) }  ><i class="fa-solid fa-window-close me-2"></i>Close</button>
                        </div>
                    </div>

                    <div className=' '>
                        <div className="product_list row ms-0" >
                            {Product?.requiredOtherProducts?.map((item, index) =>
                                <div className={`col-md-4 col-lg-4 col-6 mb-2`} key={`product_item${index}`}>
                                    <div className={`product_item m-0`} key={index}>
                                        <figure className='figure m-0'>
                                            {item.productImageUrl ? <img src={item.productImageUrl} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img img-fluid" alt="" /> :
                                                <Skeleton animation="wave" />
                                            }
                                        </figure>
                                        <div className='product_info'>
                                            <div className="">
                                                <div className='d-flex justify-content-between'>
                                                    <figcaption className="figure-caption text-start mb-2" >
                                                        {item.categoryName}
                                                    </figcaption>

                                                    <figcaption className="figure-caption text-start mb-2" >
                                                        {item.brandName}
                                                    </figcaption>
                                                </div>
                                                <span onClick={(e) => {
                                                    e.preventDefault()
                                                    window.open(`${baseUrl}${item.searchEnginePageName}/${item.productCode}`, '_blank');

                                                } } /*to={`/products-details?productCode=${item.productCode}`}*/ style={{ cursor: 'pointer' }}>
                                                    <Tooltip arrow title={item.productName} TransitionComponent={Zoom} placement="top-start">
                                                        <h6 className='productName'>{item.productName}</h6>
                                                    </Tooltip>
                                                </span>
                                                <div className="product-item__price-list price-list mb-2 d-flex justify-content-between align-items-baseline">
                                                    <div>
                                                        <span className="price price--highlight me-1">₹{item.sellingPrice}</span>
                                                        <span className="price price--compare ">₹{item.mrp}</span>
                                                    </div>
                                                    <div>
                                                        <span className='prod_discount'>({item.discountType === "FLAT" && "₹"}{item.discount}{item.discountType === "PERCENTAGE" && "%"} OFF)</span>
                                                    </div>
                                                </div>

                                                <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'
                                                    onClick={() => requiredAddToCartItem?.some(data => data.productCode === item.productCode) ? removeFromRequiredProducts(item) : addToRequiredProducts(item)}
                                                >
                                                    {requiredAddToCartItem?.some(data => data.productCode === item.productCode) ? 'Remove from cart' : 'Add to cart'}
                                                </button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </Dialog >
        </>
    )

}


export default RequiredProductModal;
