import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { ApiCall } from '../Common/service';
import { useEffect } from 'react';

export const BlogDetails = () => {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const paramValue = query.get('id');

    const GetBlogsData = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetDescriptionDetails`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'web',
                ID: paramValue ? paramValue : ''
            } : { Token: "", LoginUserCode: "", Source: 'web', ID: paramValue ? paramValue : '' }
        )

        if (data.status === 'SUCCESS') {
            setBlogData(data)
            setLoading(false)
        } else if (data.status === 'ERROR') {
            setBlogData([])
            setLoading(false)
        }
    }
    const applyLinkStyles = (html) => {
        if (typeof html !== 'string') {
            return '';
        }
        return html.replace(/<a\s+(.*?)>(.*?)<\/a>/g, (match, attributes, content) => {
            return `<a style="color: #84ced1;" ${attributes}>${content}</a>`;
        });
    };
    useEffect(() => {
        GetBlogsData();
    }, []);
    return (
        <>
            <section className='p-0'>
                <div className="container">
                    <div className="contact_head py-5" style={{ backgroundImage: 'unset' }}>
                        <h1 className='text-dark text-center'>Blogs Details</h1>
                        <nav className="breadcrumb align-items-center justify-content-center">
                            <Link className="breadcrumb-item text-dark" to='/'>Home</Link>
                            <span className='text-dark'>/</span>
                            <Link className="breadcrumb-item text-dark" to='/'>Blogs</Link>
                            <span className='text-dark'>/</span>
                            <Link className="breadcrumb-item text-dark active" to='/blogsdetails'>Blogs Details</Link>
                        </nav>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="blogs_details">
                                {blogData.blogUrl ?
                                    <a className="blog-a fs-6" href={blogData.blogUrl} target='_blank'rel='noreferrer'>
                                        <img src={blogData.imageURL} alt="" />
                                    </a> :
                                        <img src={blogData.imageURL} alt="" />
                                }
                                <div className="blog_date mt-3 d-flex justify-content-between align-items-center">
                                    <span className='fw-bold text-black-50 d-flex justify-content-end'>{blogData.createdDate} by <a className="blog-a fs-6 mb-1 ms-1" href="/blogs">{blogData.createdBy}</a></span>
                                </div>
                                {blogData.blogUrl ?
                                    <a className="blog-a fs-6" href={blogData.blogUrl} target='_blank' rel='noreferrer'>
                                        <div className='fw-bold fs-2'>{blogData.description}</div>
                                    </a> : <div className='fw-bold fs-2'>{blogData.description}</div>
                                }
                                <p
                                    className='text-black-50 fsz-18'
                                    style={{ whiteSpace: 'pre-wrap' }}
                                    dangerouslySetInnerHTML={{ __html: applyLinkStyles(blogData.descriptionDetails) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
