import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from './app/store';


export const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
                <App />
        </Provider>     
    </BrowserRouter>);
serviceWorkerRegistration.unregister();

reportWebVitals();
