import { Close } from '@mui/icons-material';    
import { Dialog, IconButton } from '@mui/material';
import React from 'react';
import ReactStars from "react-rating-stars-component";
import { ApiCall, setFocus } from '../Common/service';
import imagename from './thankyou.jpeg';
import { InputBox } from '../Common/Input';
import { toast } from 'react-toastify';


export const ReviewModal = ({ orderNo, setState, state, ProductCode, openReview, setOpenReview, setReviewData, ReviewData }) => {
    let token = JSON.parse(localStorage.getItem('authUser'))
    const HandleAddReview = async () => {
        let data = await ApiCall(`/api/Web/AddReview`, { ...ReviewData, reviewBy: token?.loginUserCode, product: ProductCode ? ProductCode : '', orderNo })
        if (data.status === 'SUCCESS') {
            setState(true)
            setReviewData({
                "rating": "5",
                "reviewRemarks": "",
                "reviewTitle": "",
            })
        } else if (data.status === 'ERROR') {
            setState(false)
            setFocus(data.focus)
            toast.error(data.message)
        }
    }

    return (
        <>
            <Dialog
                open={openReview}
             //   onClose={() => setOpenReview(false)}
                fullWidth='md'
                aria-labelledby="responsive-dialog-title">
                <div className="review">
                    <h6 className="header p-3 bg-primary m-0">
                        Rate and Review
                    </h6>
                    {state ? <div>
                        <img src={imagename} height='300px' alt='...' onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} />
                    </div> : <>
                        <div className="rating position-relative">
                            <ReactStars
                                classNames='mx-auto'
                                color='#f4f4f4'
                                count={5}
                                size={50}
                                value={ReviewData.rating}
                                isHalf={false}
                                activeColor="#ffd700"
                                char={<i class="fa-solid fa-star"></i>}
                                onChange={(newValue) => {
                                    setReviewData({ ...ReviewData, rating: newValue.toString() })
                                }}
                            />
                            <div className='rateValue'>
                                Rating ({ReviewData.rating}/5)<br />
                                {
                                    ReviewData.rating === "1" ? <span className='ratingtxt text-danger'>Poor</span> :
                                        ReviewData.rating === "2" ? <span className='ratingtxt text-danger'>Too bad</span> :
                                            ReviewData.rating === "3" ? <span className='ratingtxt text-warning'>Average quality</span> :
                                                ReviewData.rating === "4" ? <span className='ratingtxt text-warning'>Nice</span> : <span className='ratingtxt text-success'>very good quality </span>
                                }
                            </div>
                        </div>
                            <div className="reviewtxt px-4">
                                <InputBox
                                    divclassname='mb-0 pb-0'
                                    label="Review Title"
                                    placeholder="Review Title"
                                    id="txtReviewTitle"
                                    maxLength='100'
                                    value={ReviewData.reviewTitle}
                                    onChange={(e) => setReviewData({ ...ReviewData, reviewTitle: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { setFocus("txtReviewRemarks") };
                                    }}
                                    required
                                />
                            </div>
                            <div className="reviewtxt p-3 px-4">
                                <label className="form-label" >Review Remarks
                                     <span style={{ color: "red" }}> *</span> 
                                </label>
                                <textarea className='form-control' name="" id="txtReviewRemarks" rows="4"
                                value={ReviewData.reviewRemarks}
                                onChange={(e) => setReviewData({ ...ReviewData, reviewRemarks: e.target.value })}
                                placeholder='Describe your experience'></textarea>
                        </div>
                        
                            <div className='text-center pb-3'>
                                <button className='bg-primary border-0 text-dark py-1 px-2 rounded' onClick={HandleAddReview}>Submit Review</button>
                            </div>
                    </>}
                    <div className="close_btn">
                        <IconButton onClick={() => setOpenReview(false)}>
                            <Close fontSize='small' style={{color : '#fff'} } />
                        </IconButton>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
