import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify';



import './ResetPassword.css'
import { SignupSvg } from './SignupSvg'
import { AlertBox, ApiCall } from '../../Common/service'

export const ResetPasssword = () => {
    const [EmailID, setEmailID] = useState('');


    const prevRoute = useLocation()



    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleResetPassword = async () => {
        //setOtpSent(true);
        let data = await ApiCall(`/api/Web/ForgetPassword`, { EmailID })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message, data.focus);
        }
    };
    const handleKeyDown = (e) => {
        const { key } = e;
        if (key === "Enter") {
            if (EmailID === "") {
                toast.error("Please Enter Your Email")
            } else if (!validateEmail(EmailID)) {
                toast.error("Please Enter Valid Email")
            } else {
                toast.success("Please Check Your Email")

                console.log(EmailID, 'restepass handle Key Down')

            }
        }


    }
    const validateEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    };

    let token = JSON.parse(localStorage.getItem('authUser'))

    return (
        <>
            {
                token ? (<Navigate to={'/'} state={prevRoute?.pathname} replace />) :

                    (<section style={{ padding: "50px 50px" }} className={'bg-light'}>
                        <div className={"container"}>
                            <div className="login rounded h-100">
                                <div className="row">
                                    <div className="col-md-6 p-0 login-img justify-content-center align-items-center flex-column d-none d-md-flex">
                                        <SignupSvg />
                                        <h6 className='text-white mb-2 mt-4'>We Are Welcome You !</h6>
                                        <h5 className='text-white'>The World Best Craft Products.</h5>
                                    </div>
                                    <div className="col-md-6 p-0 d-flex flex-column justify-content-center">
                                        <div className="login-form">
                                            <h5>Reset Your Password Here</h5>
                                            <div className='my-3' >
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="txtEmail"
                                                    label="Your Email"
                                                    variant="standard"
                                                    type="text"
                                                    onChange={(e) => setEmailID(e.target.value)}
                                                    required
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>

                                            <div className='mt-3' >
                                                <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={() => handleResetPassword()}>Reset Forgot Password</button>
                                            </div>
                                        </div>
                                        <div className="login-footer text-center">
                                            <p>Already Have an Account? <Link to='/login' className='text-primary'>Login Now</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>)
            }
        </>
    )
}
