import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";


export const loginUser = createAsyncThunk(
    'loginUser',
    async (allData) => {
        try {
           let data = await ApiCall(`/api/Web/CustomerLogin`, allData)
            return data;
        } catch (error) {
            throw new Error('Failed to log in');
        }
    }
);

export const loginUserWithNumber = createAsyncThunk(
    'loginUserWithNumber',
    async (allData) => {
        try {
            let data = await ApiCall(`/api/Web/VerifyOTP`, allData)
            return data;
        } catch (error) {
            throw new Error('Failed to login')
        }
    }

)
const keysToRemove = [
    'authUser',
    'cartProduct',
    'checkOutDetails',
    'billAmount',
    'recentViewedProduct',
    'isOpen',
    'uniqueUserId',
    'CartItemDetails',
    'CheckOutToken',

];
const removeLocalStorageItems = (keys) => {
    keys.forEach(key => localStorage.removeItem(key));
};

const initialState = {
    user: {},
    uniqueUserId:'',
    isAuthenticated: false,
    loginPopupModal: false,
    error: null,
};

let storedUser = localStorage.getItem('authUser');

if (storedUser) {
    initialState.user = JSON.parse(storedUser);
    initialState.isAuthenticated = true;
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;
            state.isAuthenticated = action.payload.isAuthenticated;
        },
        setLoginPopupModal: (state, action) => {
            state.loginPopupModal = action.payload;
        },
        setUniqueUserId: (state, action) => {
            state.uniqueUserId = action.payload;
        },
        logoutUser: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.loginPopupModal = false;
            removeLocalStorageItems(keysToRemove);
            storedUser = null;   
            state.uniqueUserId = '';
        }
    },
    extraReducers: (builder) => {
        builder
        //for login with Email
            .addCase(loginUser.pending, (state) => {
                state.loading = true;              
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;

                if (action.payload.status === "SUCCESS") {
                    state.user = action.payload;
                    state.loginPopupModal = false;
                    localStorage.setItem('authUser', JSON.stringify({
                        token: action.payload.token,
                        userName: action.payload.userName,
                        loginUserCode: action.payload.loginUserCode
                    }));
                    state.isAuthenticated = true;
                }              
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            //for login with Mobile
            .addCase(loginUserWithNumber.pending, (state) => {
                state.loading = true;              
            })
            .addCase(loginUserWithNumber.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.status === "SUCCESS") {
                    state.user = action.payload;
                    localStorage.setItem('authUser', JSON.stringify({
                        token: action.payload.token,
                        loginUserCode: action.payload.loginUserCode
                    }));
                    state.isAuthenticated = true 
                }             
            })
            .addCase(loginUserWithNumber.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { setUser, logoutUser, generateUserId, setUniqueUserId, setLoginPopupModal } = userSlice.actions;

export default userSlice.reducer;
