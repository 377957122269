import { MailOutline } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
import { ApiCall, setFocus } from '../Common/service';
import { toast } from 'react-toastify';

export const CTA = () => {
    const [subscribeMail, setSubscribeMail] = useState('');
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const handleSubscribeMail = async () => {

        if (validateEmail(subscribeMail)) {
            let data = await ApiCall(`/api/Web/SubscribedEmail`, { EmailID: subscribeMail ? subscribeMail : "" })
            if (data.status === 'SUCCESS') {
                toast.success(data.message)
                setSubscribeMail('')
            } else if (data.status === 'ERROR') {
                toast.error(data.message)
            }
        } else {
            setFocus('txtSubscribeMail')
            toast.error('Please Enter Valid Mail')
        }
      
    }
  return (
    <>
        <section className="cta bg-third py-5">
            <div className="container">
            <div className="row align-items-center justify-content-between">
                      <div className="col-lg-6">
                          <h3 className="fsz-30 fw-400 mb-0"> <strong className="fw-700"> Subscribe </strong> &amp; Get <span style={{ color:'var(--primary-dark)'} }> 10% OFF </span> for first order </h3>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="form-group">
                              <span className="icon"> <MailOutline fontSize='small' /> </span>
                              <input type="text" onChange={(e) => setSubscribeMail(e.target.value)} value={subscribeMail} id="txtSubscribeMail" placeholder="Enter your email address" />
                              <button onClick={handleSubscribeMail }> Subscribe </button>
                        </div>
                    </div>
                </div>
            </div>              
        </section>
    </>
  )
}
