import { EditNote, Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { generateUserId, logoutUser } from '../../features/authSlice'
import { AlertBox, ApiCall } from '../Common/service'
import { Address, GetAddresslists } from './Address'
import Orders from './Orders'

export const Myprofile = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const prevRoute = useLocation()
    const [tab, setTab] = useState(0);
    const [ToggleEdit, setToggleEdit] = useState(false)
    const [ChangePasswordData, setChangePasswordData] = useState({
        currentPassword: '',
        confirmPassword: '',
        newPassword: '',
    })

    const location = useLocation();
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const [policyValidations, setPolicyValidations] = useState({
        noWhitespace: false,
        length: false,
        matchConfirmPassword: false,
        differentOldPassword: false,
        complexity: false
    });

    useEffect(() => {
        const pathName = window.location.pathname;
        const trimmedPath = pathName.startsWith("/") ? pathName.slice(1) : pathName;
        const parts = trimmedPath.split("/");
        const result = parts.slice(1).join("/");
        if (result === 'order') {
            setTab(2)
        } else {
            setTab(0)
        }
    }, [location]);
    const tokenData = { Token: user.token ? user.token : "", LoginUserCode: user.loginUserCode ? user.loginUserCode : "", customer: user.loginUserCode ? user.loginUserCode : "", Source: "web" };
    const validatePassword = (password, confirmPassword) => {
        if (password !== '') {
            const noWhitespace = !/\s/.test(password);
            const length = password.length >= 8 && password.length <= 15;
            const complexity = /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
            setPolicyValidations({
                noWhitespace,
                length,
                matchConfirmPassword: password === confirmPassword,
                differentOldPassword: password !== ChangePasswordData.currentPassword,
                complexity
            });
        } else {
            setPolicyValidations({
                noWhitespace: false,
                length: false,
                matchConfirmPassword: false,
                differentOldPassword: false,
                complexity: false
            });
        }
    };
    const handlePasswordChange = (field, value) => {
        setChangePasswordData({ ...ChangePasswordData, [field]: value });

        if (field === 'newPassword') {
            validatePassword(value, ChangePasswordData.confirmPassword);
        } else if (field === 'confirmPassword') {
            validatePassword(ChangePasswordData.newPassword, value);
        }
    };
    const changePassword = async () => {
        if (ChangePasswordData.currentPassword === "") {
            AlertBox("Error", "Please Enter Current Password", "txtCurrentPassword")
            return
        }
        if (ChangePasswordData.newPassword === "") {
            AlertBox("Error", "Please Enter New Password", "txtNewPassword")
            return
        }
        if (ChangePasswordData.confirmPassword === "") {
            AlertBox("Error", "Please Enter Confirm Password", "txtConfirmPassword")
            return
        }
        if (ChangePasswordData.newPassword === ChangePasswordData.currentPassword) {
            AlertBox("Error", "Please Enter New Password different from Current Password", "txtNewPassword")
            return
        }
        let data = await ApiCall(`/api/Web/ChangeCustomerPassword`, { ...ChangePasswordData, ...tokenData })
        if (data.status === 'SUCCESS') {
            //AlertBox(data.status, data.message)
            navigate('/login')
            dispatch(logoutUser())
            setChangePasswordData({
                currentPassword: '',
                confirmPassword: '',
                newPassword: '',
            })
        } else if (data.status === 'ERROR') {
            AlertBox(data.status, data.message)
        }
    }


    const [formData, setFormData] = useState({
        "customerCode": user?.loginUserCode,
        "customerName": "",
        "customerEmailID": "",
        "customerPhoneNo": "",
        "customerProfile": "",
        "gender": "",
        "customerProfileExt": ""
    })
    const [addressList, setAddressList] = useState([]);

    const [selectedAddress, setSelectedAddress] = useState('');


    const GetCustomerDetails = async (tokenData) => {

        let data = await ApiCall(`/api/Web/GetCustomerDetails`, tokenData)
        if (data.status === 'SUCCESS') {
            setFormData({
                "customerName": data.customerName,
                "customerEmailID": data.customerEmailID,
                "customerPhoneNo": data.customerPhoneNo,
                "customerProfile": "",
                "customerProfileExt": "",
                "gender": data.gender
            })
        } else if (data.status === 'ERROR') {
            console.warn('Something went wrong. !')
        }

    }

    const UpdateCustomerDetails = async () => {
        let data = await ApiCall(`/api/Web/UpdateCustomerProfile`, { ...formData, ...tokenData })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            setToggleEdit(false)
        } else if (data.status === 'ERROR') {
            AlertBox(data.status, data.message)
        }
    }

    const extractInitials = (name) => {
        const parts = name.split(' ');
        return parts.map(part => part.charAt(0)).join('');
    }

    const Name = formData.customerName;
    const Intial = extractInitials(Name);


    useEffect(() => {
        GetAddresslists(tokenData, setAddressList)
        GetCustomerDetails(tokenData);
    }, [])

    const dispatch = useDispatch()

    return (
        <>
            {
                !isAuthenticated ? <Navigate to={'/login'} state={prevRoute?.pathname} replace /> :
                    <>
                        <section className='py-5 myprofile'>
                            <div className="container-fluid">
                                <nav className="breadcrumb align-items-center">
                                    <Link className="breadcrumb-item" to='/'>Home</Link>

                                    <span>/</span>
                                    <Link className="breadcrumb-item active" to='/profile'>My Profile</Link>
                                </nav>
                                <div className="row">
                                    <div className="col-md-4 col-xl-3 mb-3">
                                        <div className="card border-0 bg-light p-3 overflow-hidden">
                                            <div className="profile_head d-flex gap-3 align-items-center">

                                                <div className="head_info">
                                                    <span className="text-truncate d-block">{formData.customerName ? formData.customerName : 'Hello User'}</span>
                                                    <div className="head_mail">{formData.customerEmailID ? formData.customerEmailID : 'xyz@gmail.com'}</div>
                                                </div>
                                            </div>
                                            <div className="profile_links mt-3">
                                                <button onClick={(prev) => setTab(0)} className={` ${tab === 0 ? 'bg-primary' : ''}`}>My Profile</button>
                                                <button onClick={(prev) => setTab(1)} className={`d-flex justify-content-between ${tab === 1 ? 'bg-primary' : ''}`}>My Addresses {/*<span>({addressList?.length || '0'})</span>*/}</button>
                                                <button onClick={(prev) => setTab(2)} className={`d-flex justify-content-between ${tab === 2 ? 'bg-primary' : ''}`}>My Orders </button>
                                                <button onClick={(prev) => setTab(3)} className={` ${tab === 3 ? 'bg-primary' : ''}`}>Change Password</button>
                                                <button
                                                    onClick={(prev) => {
                                                        setTab(4);
                                                        dispatch(logoutUser())
                                                        dispatch(generateUserId())
                                                        navigate('/')
                                                        window.location.reload();
                                                    }}
                                                    className={` ${tab === 4 ? 'bg-primary' : ''}`}>Logout</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-xl-9">
                                        <div className="card border-0">
                                            {tab === 0 ?

                                                <div className="profile_tab">

                                                    <div className="tab_head d-flex justify-content-between align-items-center border-bottom">
                                                        <h6 className='m-0'>Account Info</h6>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => setToggleEdit(!ToggleEdit)}>
                                                            <span>Edit Info :</span>
                                                            <IconButton >
                                                                <EditNote />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/*<div className="col-md-4 d-flex flex-column align-items-center">*/}
                                                        {/*    <div className="profile_photo mt-3">*/}
                                                        {/*        <img src="./images/profile_photo.jpg" alt="" />*/}
                                                        {/*    </div>*/}
                                                        {/*    {*/}
                                                        {/*        ToggleEdit && <div className='photo_uploader w-100 text-center mt-3'>*/}
                                                        {/*            <input id="file-upload" className='d-none' type="file" name="fileUpload" accept="image/*" />*/}
                                                        {/*            <label for="file-upload" id="file-drag">*/}
                                                        {/*                <div id="start">*/}
                                                        {/*                    <i class="fa fa-download fs-4 text-theme mb-2" aria-hidden="true"></i>*/}
                                                        {/*                    <h6>Upload a Photo</h6>*/}
                                                        {/*                </div>*/}
                                                        {/*            </label>*/}
                                                        {/*            <span className='mt-2 d-block text-secondary'>File Format : png, jpg 200x200</span>*/}
                                                        {/*        </div>*/}
                                                        {/*    }*/}

                                                        {/*</div>*/}
                                                        <div className="col-md-8">
                                                            <div className="tab_body profile_body py-3">
                                                                <div className="d-flex gap-2">
                                                                    <div className="col">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="" className="form-label"> Name <span className="text-danger">*</span></label>
                                                                            <input type="text" name="" id="" className="form-control" placeholder=""
                                                                                value={formData.customerName}
                                                                                onChange={(e) => {
                                                                                    setFormData({ ...formData, customerName: e.target.value })
                                                                                }}
                                                                                disabled={!ToggleEdit} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                                                            <input type="text" name="" id="" className="form-control" placeholder=""
                                                                                value={formData.customerPhoneNo}
                                                                                onChange={(e) => {
                                                                                    setFormData({ ...formData, customerPhoneNo: e.target.value })
                                                                                }}
                                                                                disabled />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex gap-2">
                                                                    <div className="col">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="" className="form-label">Email Address <span className="text-danger">*</span></label>
                                                                            <input type="email" name="" id="" className="form-control" placeholder=""
                                                                                value={formData.customerEmailID}
                                                                                onChange={(e) => {
                                                                                    setFormData({ ...formData, customerEmailID: e.target.value })
                                                                                }}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="" className="form-label">Gender </label>
                                                                            <select className="form-control" value={formData.gender}
                                                                                disabled={!ToggleEdit}
                                                                                onChange={(e) => {
                                                                                    setFormData({ ...formData, gender: e.target.value })
                                                                                }}>
                                                                                <option>-please select-</option>
                                                                                <option value='Male'>Male</option>
                                                                                <option value='Female'>Female</option>
                                                                                <option value='Others'>Others</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {ToggleEdit &&
                                                                    <div className='alert alert-info' style={{ backgroundColor: "#e4f7f4" }}>
                                                                        <>
                                                                            <span style={{ color: "red" }}> Note.</span> Please contact support team to update email or mobile no.


                                                                            <div className='ms-4'>
                                                                                <a className='a-email mb-2 ms-4' href="mailto:support@indocraftworld.com">support@indocraftworld.com</a>
                                                                            </div>
                                                                        </>
                                                                    </div>

                                                                }
                                                                {ToggleEdit &&
                                                                    <button className='bg-primary text-white py-2 px-5 border-0 rounded mybtn' onClick={UpdateCustomerDetails}>Update</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                tab === 1 ?

                                                    <Address
                                                        tokenData={tokenData}
                                                        addressList={addressList}
                                                        setAddressList={setAddressList}
                                                        setSelectedAddress={setSelectedAddress}
                                                        selectedAddress={selectedAddress}
                                                    />
                                                    :
                                                    tab === 2 ?
                                                        <Orders filterData stateValue={false} /> :
                                                        tab === 3 ?
                                                            <div className="address_tab">
                                                                <div className="tab_head py-2 border-bottom">
                                                                    <span className='d-md-flex d-block align-items-center'>Update your password for :<h6 className='m-0 ms-md-2'>{formData.customerEmailID ? formData.customerEmailID : 'xyz@gmail.com'}</h6></span>
                                                                </div>
                                                                <div className="tab_body py-3">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="col">
                                                                                <label htmlFor="" className="form-label">Current Password <span className="text-danger">*</span></label>
                                                                                <div className="mb-3 position-relative">
                                                                                    <input type={showPassword ? 'text' : 'password'} name="" id="txtCurrentPassword" className="form-control"
                                                                                        value={ChangePasswordData.currentPassword}
                                                                                        onChange={(e) => setChangePasswordData({ ...ChangePasswordData, currentPassword: e.target.value })}
                                                                                        placeholder="" />
                                                                                    <div className="eye_pwrd">
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={() => setShowPassword((show) => !show)}
                                                                                            onMouseDown={(event) => { event.preventDefault() }}
                                                                                        >
                                                                                            {showPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <label htmlFor="" className="form-label">New Password <span className="text-danger">*</span></label>
                                                                                <div className="mb-3 position-relative">
                                                                                    <input type={showNewPassword ? 'text' : 'password'} name="" id="txtNewPassword"
                                                                                        value={ChangePasswordData.newPassword}
                                                                                        onChange={(e) => handlePasswordChange('newPassword', e.target.value)}

                                                                                        className="form-control" placeholder="" />
                                                                                    <div className="eye_pwrd">
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={() => setShowNewPassword((show) => !show)}
                                                                                            onMouseDown={(event) => { event.preventDefault() }}
                                                                                        >
                                                                                            {showNewPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <label htmlFor="" className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                                                <div className="mb-3 position-relative">
                                                                                    <input type={showConfirmPassword ? 'text' : 'password'} name="" id="txtConfirmPassword" className="form-control"
                                                                                        value={ChangePasswordData.confirmPassword}
                                                                                        onChange={(e) => handlePasswordChange('confirmPassword', e.target.value)}

                                                                                        placeholder="" />
                                                                                    <div className="eye_pwrd">
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={() => setShowConfirmPassword((show) => !show)}
                                                                                            onMouseDown={(event) => { event.preventDefault() }}
                                                                                        >
                                                                                            {showConfirmPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button className='bg-primary text-white py-2 px-5 border-0 rounded mybtn' onClick={() => changePassword()}>Update</button>

                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <div className="card overflow-hidden" style={{ border: '1px dashed var(--secondary)' }}>
                                                                                <h6 className='px-3 py-2 bg-secondary'>Password Policy</h6>
                                                                                <p className='changepara ms-2' >
                                                                                    <strong >1.</strong>
                                                                                    White spaces not allowed. {policyValidations.noWhitespace ? <i className="fa-solid fa-check" style={{ color: "green", marginBottom: "4px" }} /> : <i className="fa-solid fa-xmark" style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                                                </p>
                                                                                <p className='changepara ms-2'>
                                                                                    <strong >2.</strong>
                                                                                    Password must be 8 to 15 character(s). {policyValidations.length ? <i className="fa-solid fa-check" style={{ color: "green", marginBottom: "4px" }} /> : <i className="fa-solid fa-xmark" style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                                                </p>
                                                                                <p className='changepara ms-2'>
                                                                                    <strong >3.</strong>
                                                                                    New password and confirm password should be same. {policyValidations.matchConfirmPassword ? <i className="fa-solid fa-check" style={{ color: "green", marginBottom: "4px" }} /> : <i className="fa-solid fa-xmark" style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                                                </p>
                                                                                <p className='changepara ms-2'>
                                                                                    <strong >4.</strong>
                                                                                    Old password and new password should be different. {policyValidations.differentOldPassword ? <i className="fa-solid fa-check" style={{ color: "green", marginBottom: "4px" }} /> : <i className="fa-solid fa-xmark" style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                                                </p>
                                                                                <p className='changepara ms-2'>
                                                                                    <strong>5.</strong>
                                                                                    Password must be combination of at least one numeric and one uppercase letter and one lowercase and special character [$#!()]. {policyValidations.complexity ? <i className="fa-solid fa-check" style={{ color: "green", marginBottom: "4px" }} /> : <i className="fa-solid fa-xmark" style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </>
            }
        </>
    )
}