import React from 'react'
import { Link } from 'react-router-dom'

export const CancelPolicy = () => {
    return (
        <section className='pt-3 pb-3 pb-md-5' style={{ backgroundColor: '#f8f8f8' }}>
            <div className="container">
                <nav className="breadcrumb align-items-center">
                    <Link className="breadcrumb-item" to='/'>Home</Link>
                    <span>/</span>
                    <Link className="breadcrumb-item active" to='/cancellation-policy'>Cancellation Policy</Link>
                </nav>
                <h3 className='text-center mb-4'>Cancellation Policy</h3>
                <div className="ck-content">
                    <p>The cancellation policy is the scheme provided on <strong style={{ fontWeight: 'bolder' }}>Indocraftworld.com’s</strong> website available at &nbsp;www.indocraftworld.com or the mobile application under the brand name “Indocraftworld.com” (collectively
                        “Platform”) in relation to the products being sold on the Platform (“Products”). &nbsp;This cancellation policy (“Policy”) lays down the mechanism in which an order placed by the customer or the reseller (“User”) on the Platform can be cancelled
                        by the User or the supplier or by Indocraftworl.com. &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>Cancellation by the User &nbsp;</strong></p>
                    <p><strong style={{ fontWeight: 'bolder' }}>When can the User cancel an order? &nbsp;</strong></p>
                    <p>The User can cancel an order after the order has been placed through the Platform and before the order has been dispatched by the supplier. &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>How can the User cancel an order? &nbsp;</strong></p>
                    <p>In order to cancel, the User can go to ‘My Orders’ in your ‘Profile’. Once you are redirected to the My Orders page, click on the order of the product that you want to cancel. Click on cancel Order and provide the reason for the cancellation from
                        the options provided. &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>What are the reasons due to which the User may cancel the order? &nbsp;</strong></p>
                    <p><strong style={{ fontWeight: 'bolder' }}>The User may cancel the order for the following reasons:&nbsp;</strong>&nbsp;</p>

                    <ul className='ps-4 mb-3' style={{listStyle:'disc'}}>
                        <li>If the order was placed for testing.</li>
                        <li>If the User has placed multiple orders for the same Product.</li>
                        <li>If the expected delivery date is not acceptable for the User.</li>
                        <li>If the User wants to change the shipping or billing address.</li>
                        <li>If the User changes their mind about placing the order.</li>
                        <li>If the User wants to update or change the contract details or the payment mode</li>
                        <li>If the User wants to change the size or the colour of the Product or For any other reason.</li>
                    </ul>
                   
                    <p><strong style={{ fontWeight: 'bolder' }}>Cancellation by the supplier&nbsp;</strong>&nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>When can the supplier cancel an order which has been placed?</strong> &nbsp;</p>
                    <p>The supplier can only cancel an order within 1 day after the day on which the Supplier was supposed to dispatch the Product. &nbsp; &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>What are the reasons due to which the supplier may cancel the order? &nbsp;</strong></p>
                    <p>The supplier may cancel the order for the following reasons: &nbsp;</p>

                    <ul className='ps-4 mb-3' style={{listStyle:'disc'}}>
                        <li>If the ordered Product is not available with the supplier anymore.</li>
                        <li>If the ordered Product is out of stock with the supplier.</li>
                        <li>If the supplier is unable to dispatch the order in time.</li>
                    </ul>
                    <p>Cancellation by <strong style={{ fontWeight: 'bolder' }}>Indocraftworld.com &nbsp;</strong></p>
                    <p><strong style={{ fontWeight: 'bolder' }}>When can Indocraftworld.com cancel an order which has been placed? &nbsp;</strong></p>
                    <p>Indocraftworld.com can cancel the order anytime from the date on which the order has been placed till delivery of the Product to the User. &nbsp;</p>
                    <p><br /><strong style={{ fontWeight: 'bolder' }}>What are the reasons due to which Indocraftworld.com may cancel the order?</strong> &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>Indocraftworld.com</strong> may cancel the order for the following reasons: &nbsp;</p>
                    <p>If the address to which the Product is to be delivered is not serviceable; &nbsp;</p>
                    <ul className='ps-4 mb-3' style={{listStyle:'disc'}}>
                        <li>If the delivery of the Product has been unsuccessful and the Product has been returned to the seller.</li>
                        <li>If the Product has been lost during transit.</li>
                        <li>If the payment has made through online methods by the User and Indocraftworl.com did not get payment confirmation</li>
                        <li>If the shipping address or billing address provided by the User is incomplete or incorrect.</li>    
                        <li>If the supplier is unable to dispatch the order because of regulatory lockdown or other restrictions.</li>
                        <li>If the order has been auto canceled by the system due to high risk of product returning back to the supplier.</li>
                        <li>If the supplier does not meet <strong style={{ fontWeight: 'bolder' }}>Indocraftworl.com’s</strong> policies of fair
                        business practices.</li>
                        <li>If Indocraftworl.com does not get user confirmation during delivery.</li>
                        <li>If the User chose cash on delivery and cash on delivery is not available for the Product ordered.</li>
                        <li>If Indocraftworl.com is unable to fulfill the order due to other reasons</li>
                        <li>If Indocraftworl.com wants to cancel for any reason which <strong style={{ fontWeight: 'bolder' }}>Indocraftworl.com</strong> may deem appropriate.</li>
                    </ul>
                    <p>Refunds &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>How will the User get the refund</strong>? &nbsp;</p>
                    <p>If the User has made payment before cancellation of the order which has been placed, the money paid by the User shall be refunded.&nbsp;The refund will be credited to the same source of payment (bank accounts, UPI etc.) from which payment was received.
                        If the User has chosen any other method such as wallets, the mount will be credited to the source of origin as well. The User will receive a refund of the entire amount paid by the User. In case of any discrepancies regarding receipt of refund
                        amount, Indocraftworld.com may request for additional information such as bank statement or any other relevant document. &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>Will the discount vouchers or other such promotional offers be reinstated?&nbsp;</strong>&nbsp;</p>
                    <p>If the User has used any discount vouchers or promotional offers when placing the order which has been cancelled, the discount vouchers or promotional offers will be forfeited. &nbsp;</p>
                    <p><strong style={{ fontWeight: 'bolder' }}>When will the User get the refund? &nbsp;</strong></p>
                    <p>Following are the processing timelines after the product is received by us or when the seller notifies us of the receipt of the products. &nbsp;</p>
                    <p>Refund Method &nbsp;</p>
                    <p>Refund Time Frame &nbsp;</p>
                    <p>Credit Card, Debit Card, Net Banking, UPI Linked Bank Account &nbsp;</p>
                    <p>3-7 Business Days &nbsp;</p>
                    <p>&nbsp;Miscellaneous &nbsp;</p>
                    <p>Indocraftworld.com reserves the right, at its sole discretion, to amend, change, modify, add or remove any portion of this policy at any time without any prior written notice to you. It is your responsibility to review this policy periodically for
                        any updates/ changes. &nbsp;</p>
                    <p><br />For any further queries regarding return, replacement, exchange or refund, please reach out to customer support at <strong style={{ fontWeight: 'bolder' }}>legalsupport@indocraftworld.com.com. &nbsp;</strong></p>
                </div>
            </div>
        </section>
    )
}
