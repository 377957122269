import React from 'react'
import { Link } from 'react-router-dom'
import { ApiCall } from '../Common/service';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const FAQ = () => {
    const [faqData, setFAQData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const GetData = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetFAQData`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'web'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )

        if (data.status === 'SUCCESS') {
            setFAQData(data.getFAQRecords)
            setLoading(false)
        } else if (data.status === 'ERROR') {
            setFAQData([])
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
    }, []);
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center my-5">
                            <h2 className="main-heading"><b style={{ color: '#27225f' }}>Frequently Asked </b><b style={{ color: '#84ced1' }}>Questions</b></h2>
                        </div>
                        {
                            faqData.length > 0 ?
                                <div className="col-12 mb-5">
                                    <div className="FaqOuter" id="accordionFlushExample">
                                        <div className="faq">
                                            {faqData.map((item, index) =>
                                                <>

                                                    <h2
                                                        className={`mb-0 ${activeIndex === index ? "" : "collapse show"}`} id={`flush-collapse${index}`}
                                                        style={{ borderBottom: '1px solid #d1d6db', backgroundColor: activeIndex === index ? '#403f6e' : 'transparent', color: activeIndex === index ? '#fff' : '#000000' }}
                                                    >

                                                        <button
                                                            className="FaqOuter-button1 collapsed"
                                                            id={`flush-collapse${index}`}
                                                            data-bs-target={`#flush-collapse${index}`}
                                                            aria-controls={`flush-collapse${index}`}
                                                            style={{ fontWeight: 'bolder' }}
                                                            onClick={() => handleToggle(index)}
                                                        >
                                                            {item.question}
                                                        </button>

                                                    </h2>
                                                    <div
                                                        className={`mb-0 ${activeIndex === index ? "accordion-collapse collapse show" : "accordion-collapse collapse"}`}
                                                        id={`flush-collapse${index}`}
                                                        style={{ borderBottom: '1px solid #d1d6db' }}
                                                        //   className="accordion-collapse collapse"
                                                        aria-labelledby={`flush-heading${index}`}
                                                        data-bs-parent="#accordionFlushExample"
                                                    >
                                                        <div className="accordion-body">
                                                            {item.answer}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                : ''

                        }

                    </div>
                </div>
            </section>
        </>
    )
}
export default FAQ