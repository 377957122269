
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from "../components/Common/service";

const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];

export const AddtoWishListApi = createAsyncThunk(
    'AddtoWishListApi',
    async (productCode, { getState }) => {
        try {
            const authData = getState().authData.user;
            const response = await ApiCall(`/api/Web/AddWishList`, {
                ProductCode: productCode,
                LoginUserCode: authData.loginUserCode ? authData.loginUserCode : authLocalStorage.loginUserCode,
                Token: authData.token ? authData.token : authLocalStorage.token
            })
            return response.message;
        } catch (error) {
            throw new Error('Item is not added in WishList');
        }
    }
);

export const RemoveFromWishListApi = createAsyncThunk(
    'RemoveFromWishListApi',
    async (productCode, { getState }) => {
        try {
            const authData = getState().authData.user;
            const response = await ApiCall(`/api/Web/RemoveWishListProduct`, {    
                ProductCode: productCode,     
                LoginUserCode: authData.loginUserCode ? authData.loginUserCode : authLocalStorage.loginUserCode,
                Token: authData.token ? authData.token : authLocalStorage.token
            })
            return response.message;
        } catch (error) {
            throw new Error('Item is not Remove From WishList');
        }
    }
);

export const GetWishListDataApi = createAsyncThunk(
    'GetWishListDataApi',
    async (args, { getState }) => {
        try {
            const authData = getState().authData.user;
            let response = await ApiCall(`/api/Web/GetWishListProducts`, {
                LoginUserCode: authData.loginUserCode ? authData.loginUserCode : authLocalStorage.loginUserCode,
                Token: authData.token ? authData.token : authLocalStorage.token
            })
            return response;
        } catch (error) {
            throw new Error('Error to fetch all wishlist data');
        }
    }
);


export const wishListSlice = createSlice({
    name: 'wishListSlice',
    initialState: {
        wishListData: [],
        removefromwishlist: 0,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //For addToWishListApi
            .addCase(AddtoWishListApi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(AddtoWishListApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(AddtoWishListApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            //For RemoveToWishListAPI
            .addCase(RemoveFromWishListApi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(RemoveFromWishListApi.fulfilled, (state, action) => {
                state.loading = false;
                state.removefromwishlist += 1;

            })
            .addCase(RemoveFromWishListApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            //For GetWishListDataApi
            .addCase(GetWishListDataApi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetWishListDataApi.fulfilled, (state, action) => {
                state.loading = false;
                state.wishListData = action.payload.products;
            })
                .addCase(GetWishListDataApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default wishListSlice.reducer;
