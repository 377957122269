import { Pagination, PaginationItem, Tooltip, Zoom } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiCall } from '../Common/service';

const StorePage = () => {
    const [storesData, setStoresData] = useState([])
    const [PageIndex, setPageIndex] = useState(1);
    const [PageSize, setPageSize] = useState(1);
    const [totalStoresCount, settotalStoresCount] = useState(1);
    const [loading, setLoading] = useState(false)

    const countNo = Math.ceil(parseInt(totalStoresCount ? totalStoresCount : 1) / parseInt(PageSize ? PageSize : 1));

    const handleChange = (event, value) => {
        setPageIndex(value);
    };

    const GetTopBrandData = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetStoresData`, {
            "Source": "Web",
            "PageIndex": PageIndex ? PageIndex.toString() : "1"
        })
        if (data.status === 'SUCCESS') {
            await setStoresData(data.stores)
            await setPageSize(data.pageSize)
            await settotalStoresCount(data.totalStores)
            await setLoading(false)
        } else if (data.status === 'ERROR') {
            await setLoading(true)
            await setStoresData([])
            await setPageSize(0)
            await settotalStoresCount(0)
            await setLoading(false)
        }
    }
    useEffect(() => {
        GetTopBrandData()
        document.title = 'Store' ;
    }, [])

    return (
        loading ?
            <section className='pt-0'>
                <div className='container-fluid p-0 mt-0'>
                    <div className='m-0 my-3 p-0  storeTextWithImage' style={{ height: "15rem" }}>
                        <Skeleton variant="rectangular" style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div className="row">
                        {(() => {
                            const slides = [];
                            for (let i = 0; i < 10; i++) {
                                slides.push(
                                    <div className="col-2 " style={{ height: '20%', width: "20%" }} key={i} >
                                        <div className='h-100 w-100 mb-4 overflow-hidden rounded'>
                                            <Link to={`/`} className='position-relative'>
                                                <div className="stores_list">
                                                    <Skeleton variant="rectangular" style={{ height: "170px", width: "287px" }} />
                                                </div>
                                                <Box sx={{ pt: 0.5 }}>
                                                    <Skeleton />
                                                </Box>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                            return slides;
                        })()}
                    </div>
                </div>
            </section>
            :
            <section className='pt-0'>
                <div className='container-fluid p-0 mt-0'>
                    <div className='m-0 my-3 p-0  storeTextWithImage' style={{ height: "15rem" }}>
                        <img src='./images/store-banner.jpg' className='w-100 h-100' alt='' />
                        <span className='storesNameFooter'>Stores</span>
                    </div >
                    <div className="row">
                        {storesData?.map((item, index) =>
                            <div className="col-2 " style={{ height: '20%', width: "20%" }} key={`stores_List_${index}`} >
                                <div className='h-100 w-100 mb-4 overflow-hidden rounded'>
                                    <Link to={`/products?store=${item.vendorCode}`} className='position-relative' >
                                        <div className="stores_list">
                                            <img src={item.shopImageURL} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} className="figure-img m-0 rounded h-100 w-100" alt="" />
                                        </div>
                                        <Tooltip arrow title={item.shopName} TransitionComponent={Zoom} placement="bottom">
                                            <p className='bg-primary bottom-0  m-0 position-absolute productName py-2 text-center w-100'>{item.shopName}</p>
                                        </Tooltip>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {countNo > 1 && <Pagination
                            className='pt-2 mypagination'
                            count={countNo}
                            variant="outlined"
                            page={Number(PageIndex)}
                            onChange={handleChange}
                            shape="rounded"
                            size="large"
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: () => <><i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Previous </>,
                                        next: () => <>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"> </i></>
                                    }}
                                    {...item}
                                />
                            )}
                        />}
                    </div>
                </div>
            </section>
    )
};
export default StorePage;
