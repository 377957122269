import React from 'react'
import './Signup.css'

export const SignupSvg = () => {
  return (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="250.1px"
            height="376.9px"
            className='mover'
            viewBox="0 0 250.1 376.9"
            style={{
            overflow: "visible",
            enableBackground: "new 0 0 250.1 376.9",
            }}
            xmlSpace="preserve"
        >
            <defs />
            <g>
            <path
                className="st0"
                d="M181.7,218.7c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7c-0.2-1.3-1.3-2.2-2.7-2.1 C182.5,216.2,181.6,217.4,181.7,218.7z"
            />
            <path
                className="st0"
                d="M72.5,363.4c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7s-1.3-2.2-2.7-2.1 C73.3,360.9,72.3,362.1,72.5,363.4z"
            />
            <path
                className="st0"
                d="M179.4,2.7c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7c-0.2-1.3-1.3-2.2-2.7-2.1 C180.2,0.2,179.3,1.4,179.4,2.7z"
            />
            <path
                className="st0"
                d="M41.6,292.2c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7s-1.3-2.2-2.7-2.1 C42.4,289.7,41.4,290.9,41.6,292.2z"
            />
            <path
                className="st0"
                d="M62.4,116.8c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7c-0.2-1.3-1.3-2.2-2.7-2.1 C63.2,114.3,62.2,115.5,62.4,116.8z"
            />
            <path
                className="st0"
                d="M14.6,59.7c0.2,1.3,1.3,2.2,2.7,2.1c1.3-0.2,2.2-1.3,2.1-2.7c-0.2-1.3-1.3-2.2-2.7-2.1 C15.4,57.2,14.5,58.4,14.6,59.7z"
            />
            <path
                className="st0"
                d="M215.3,245.9c0.2,1.3,1.3,2.2,2.7,2.1s2.2-1.3,2.1-2.7s-1.3-2.2-2.7-2.1C216.1,243.4,215.2,244.6,215.3,245.9z "
            />
            <path
                className="st0"
                d="M222.1,206.9c0,0.3,0.3,0.5,0.5,0.4s0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C222.2,206.3,222.1,206.6,222.1,206.9z"
            />
            <path
                className="st0"
                d="M203.5,88.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C203.7,87.5,203.5,87.8,203.5,88.1z"
            />
            <path
                className="st0"
                d="M95.6,15c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4C95.8,14.5,95.6,14.8,95.6,15z"
            />
            <path
                className="st0"
                d="M80.1,119.8c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C80.2,119.3,80,119.6,80.1,119.8z"
            />
            <path
                className="st0"
                d="M109.8,370.3c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C110,369.8,109.8,370.1,109.8,370.3z"
            />
            <circle className="st0" cx={136.6} cy={266} r={0.5} />
            <path
                className="st0"
                d="M199.8,178.2c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C200,177.7,199.8,178,199.8,178.2z"
            />
            <path
                className="st0"
                d="M22.4,108.8c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C22.6,108.3,22.4,108.5,22.4,108.8z"
            />
            <path
                className="st0"
                d="M30.4,19.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4C30.6,18.6,30.4,18.8,30.4,19.1 z"
            />
            <path
                className="st0"
                d="M192.4,40.6c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C192.6,40.1,192.4,40.3,192.4,40.6z"
            />
            <path
                className="st0"
                d="M210.3,109.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C210.4,108.6,210.2,108.9,210.3,109.1z"
            />
            <path
                className="st0"
                d="M158.4,9.7c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4C158.6,9.2,158.4,9.4,158.4,9.7 z"
            />
            <path
                className="st0"
                d="M67.8,133.9c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C67.9,133.4,67.7,133.7,67.8,133.9z"
            />
            <path
                className="st0"
                d="M227.2,253.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C227.3,252.6,227.1,252.9,227.2,253.1z"
            />
            <path
                className="st0"
                d="M107.2,357.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C107.4,356.6,107.2,356.9,107.2,357.1z"
            />
            <path
                className="st0"
                d="M75.5,128.5c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C75.7,128,75.5,128.2,75.5,128.5z"
            />
            <path
                className="st0"
                d="M34.1,281.9c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C34.2,281.3,34,281.6,34.1,281.9z"
            />
            <path
                className="st0"
                d="M127.9,266.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C128.1,265.6,127.9,265.8,127.9,266.1z"
            />
            <path
                className="st0"
                d="M8.9,185.8c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5s-0.3-0.5-0.5-0.4C9.1,185.3,8.9,185.6,8.9,185.8z"
            />
            <path
                className="st0"
                d="M62.1,331.1c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C62.3,330.6,62.1,330.8,62.1,331.1z"
            />
            <path
                className="st0"
                d="M36.2,361.4c0,0.3,0.3,0.5,0.5,0.4c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4 C36.4,360.9,36.2,361.1,36.2,361.4z"
            />
            <path
                className="st0"
                d="M225.6,187.9c0,0.3,0.1,0.5,0.4,0.6c0.3,0,0.5-0.1,0.6-0.4c0-0.3-0.1-0.5-0.4-0.6 C225.9,187.4,225.6,187.6,225.6,187.9z"
            />
            <path
                className="st0"
                d="M7,133c0,0.3,0.1,0.5,0.4,0.6c0.3,0,0.5-0.1,0.6-0.4c0-0.3-0.1-0.5-0.4-0.6C7.3,132.6,7.1,132.8,7,133z"
            />
            <path
                className="st0"
                d="M23.6,32.3c0,0.3,0.1,0.5,0.4,0.6c0.3,0,0.5-0.1,0.6-0.4c0-0.3-0.1-0.5-0.4-0.6C23.9,31.9,23.6,32.1,23.6,32.3 z"
            />
            <path
                className="st0"
                d="M201.8,186.6c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1 C202.2,185.4,201.8,186,201.8,186.6z"
            />
            <path
                className="st0"
                d="M0,70.9c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C0.4,69.7-0.1,70.3,0,70.9z"
            />
            <path
                className="st0"
                d="M120.6,360.4c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1 C120.9,359.3,120.5,359.8,120.6,360.4z"
            />
            <path
                className="st0"
                d="M77.2,143.3c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C77.5,142.1,77.1,142.7,77.2,143.3z "
            />
            <path
                className="st0"
                d="M47.2,328.5c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C47.6,327.3,47.2,327.9,47.2,328.5z "
            />
            <path
                className="st0"
                d="M66.1,309.2c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C66.5,308.1,66.1,308.6,66.1,309.2z "
            />
            <path
                className="st0"
                d="M14.6,161.5c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C14.9,160.3,14.5,160.9,14.6,161.5z "
            />
            <path
                className="st0"
                d="M127.3,194.4c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1 C127.7,193.3,127.2,193.8,127.3,194.4z"
            />
            <path
                className="st0"
                d="M116.7,116.1c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1 C117.1,115,116.7,115.5,116.7,116.1z"
            />
            <path
                className="st0"
                d="M77.6,104.9c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C78,103.7,77.6,104.3,77.6,104.9z"
            />
            <path
                className="st0"
                d="M137,125.6c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C137.4,124.4,136.9,125,137,125.6z"
            />
            <path
                className="st0"
                d="M130.5,239.3c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1 C130.9,238.1,130.4,238.7,130.5,239.3z"
            />
            <path
                className="st0"
                d="M219,177c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C219.3,175.9,218.9,176.4,219,177z"
            />
            <path
                className="st0"
                d="M2.2,27.4c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C2.6,26.3,2.1,26.8,2.2,27.4z"
            />
            <path
                className="st0"
                d="M155.5,20c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C155.9,18.9,155.5,19.4,155.5,20z"
            />
            <path
                className="st0"
                d="M202.1,28.1c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C202.5,27,202,27.5,202.1,28.1z"
            />
            <path
                className="st0"
                d="M194.1,113c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C194.4,111.9,194,112.4,194.1,113z"
            />
            <path
                className="st0"
                d="M125,35c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C125.3,33.8,124.9,34.4,125,35z"
            />
            <path
                className="st0"
                d="M86.3,127.7c0.1,0.6,0.6,1,1.2,1c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1C86.7,126.5,86.2,127.1,86.3,127.7z "
            />
            <path
                className="st0"
                d="M183.5,174.2L183.5,174.2c-0.3-2.6,1.5-5,4.1-5.3l0,0c-2.6,0.3-5-1.5-5.3-4.1l0,0c0.3,2.6-1.5,5-4.1,5.3l0,0 C180.9,169.7,183.2,171.6,183.5,174.2z"
            />
            <path
                className="st0"
                d="M87.6,90L87.6,90c-0.3-2.6,1.5-5,4.1-5.3l0,0c-2.6,0.3-5-1.5-5.3-4.1l0,0c0.3,2.6-1.5,5-4.1,5.3l0,0 C85,85.5,87.3,87.4,87.6,90z"
            />
            <path
                className="st0"
                d="M137.4,317.6L137.4,317.6c-0.3-2.6,1.5-5,4.1-5.3l0,0c-2.6,0.3-5-1.5-5.3-4.1l0,0c0.3,2.6-1.5,5-4.1,5.3l0,0 C134.8,313.1,137.1,315,137.4,317.6z"
            />
            <path
                className="st0"
                d="M13.7,50.9L13.7,50.9c-0.3-2.6,1.5-5,4.1-5.3l0,0c-2.6,0.3-5-1.5-5.3-4.1l0,0c0.3,2.6-1.5,5-4.1,5.3l0,0 C11,46.4,13.4,48.3,13.7,50.9z"
            />
            </g>
            <g>
            <path
                className="st1"
                d="M80.8,228.9c0,0,3.3,5.3,12.8,3.7c9.5-1.6,16.4-2.3,25-10.4c8.6-8.1,8.7-14.9,8.6-18.3 c-0.1-3.4-21.4-5.2-31.3,3.2c-9.9,8.4-13.5,13.9-14.5,16.8C80.3,226.8,80.8,228.9,80.8,228.9z"
            />
            <path
                className="st2"
                d="M114,345.1c0,0-0.3,5.1,1.3,13.3c1.6,8.2,6.1,15.3,10,17.6c3.8,2.3,6.7,0.3,7.7-2.9c0.9-3.2-1.5-7.5-1.5-7.5 s-4.8-11.9-5.2-12.6c-0.4-0.7-5.4-10.4-5.4-10.4L114,345.1z"
            />
            <path
                className="st2"
                d="M164.1,300.7l-1.4,1.7c0,0,1.6,14.6,5.6,21.7c4,7,8.5,13.1,10.9,13.2c2.4,0.2,2-2.6,2-2.6s-4.3-5.7-8.6-14.9 c-4.3-9.2-5.7-16.8-5.7-16.8L164.1,300.7z"
            />
            <path
                className="st3"
                d="M167.2,298.4c1.1-0.7,2.3-1.5,3.7-2.2c3.6-1.9,4.2-7.1,4.2-7.1l3.7-0.7l4.4,2.5c0,0-2,7.9-2.9,21 c-0.3,4.9-0.1,9,0.3,12.3c-3.9-0.2-5.8-3.6-8.3-11.1L167.2,298.4z"
            />
            <path
                className="st4"
                d="M167.2,298.4l5,14.7c2.6,7.5,4.4,11,8.3,11.1c0.6,5.5,1.5,8.8,0.7,10.4c-1.3,2.6-6.4-1.5-10.9-11.1 c-4.5-9.6-6.2-22.9-6.2-22.9S165.4,299.6,167.2,298.4z"
            />
            <path
                className="st5"
                d="M175.4,280.9c0,0-4.2,8.2-3,10.4c1.2,2.2,5.1,5.6,9.2,5c4.1-0.7,5.1-1.4,5.1-1.4l2.9-10.2c0,0-2.4-4-6.4-4.7 C179.3,279.3,175.4,280.9,175.4,280.9z"
            />
            <path
                className="st6"
                d="M167.2,298.4l4.7,13.9c-0.5,3.2-1.2,7-2,10.4c-4.2-9.5-5.8-22-5.8-22S165.4,299.6,167.2,298.4z"
            />
            <path
                className="st7"
                d="M167.2,298.4l4.7,13.9c0.6-3.7,1-6.6,1-6.6s-1.1-1.5-1.6-3.2c-0.6-1.7,2.5-4.2,2.5-4.2s0.8,0.2,3.1,0.8 c1.5,0.4,3.4,0.5,4.6,0.6c0.2-1.4,0.4-2.6,0.6-3.7c-1.2,0-3.5-0.2-4-1.2c-0.8-1.4-0.3-6.1-0.3-6.1l-2.7,0.5c0,0-0.5,5.2-4.2,7.1 C169.6,296.9,168.3,297.7,167.2,298.4L167.2,298.4z M178.8,288.4l2.6,1.5c-2.7-0.7-3.6-1.3-3.6-1.3L178.8,288.4z"
            />
            <path
                className="st1"
                d="M175.4,280.9c0,0-4.2,8.2-3,10.4c1.2,2.2,5.1,5.6,9.2,5c0.5-0.1,1-0.2,1.5-0.3c0,0-4.1,0.2-4.9-1.2 c-0.8-1.4-0.3-6.1-0.3-6.1s1,0.6,3.6,1.3c1.8,0.5,4.6,0.4,6.5,0.4l1.6-5.7c0,0-2.4-4-6.4-4.7C179.3,279.3,175.4,280.9,175.4,280.9z "
            />
            <path
                className="st3"
                d="M114.8,340.1c0.3-1,0.7-2,1.3-3.2c3-5.8,2.8-9.9,2.8-9.9l7.6,2.4l-1.2,10c0,0,1.4,3,3,7.1 c1.1,5.4,2.2,12.2,1.4,13.8c-1.5,2.7-6.7,3.4-9.6-2.3C118.4,354.4,116.2,345.9,114.8,340.1z"
            />
            <path
                className="st4"
                d="M114.8,340.1c1.4,5.8,3.6,14.3,5.4,17.9c2.9,5.7,8.1,4.9,9.6,2.3c0.9-1.5-0.3-8.4-1.4-13.8 c1.3,3.2,2.7,7.1,3.8,10.9c2.4,8.6,2.1,10.6-0.7,15.2c-2.8,4.6-8.6-1.2-11.9-6.6c-3.3-5.4-4.7-19.5-4.7-19.5 S113.7,344.1,114.8,340.1z"
            />
            <path
                className="st5"
                d="M119.1,315.6c0,0-2.4,7-2.5,10.4c-0.1,3.5,2.6,5.8,6.8,6.4c4.2,0.6,5.6,0.8,6.8-3c1.2-3.7,2.8-11.3,2.8-11.3 s-2-2.8-6.1-3.6C122.7,313.7,119.1,315.6,119.1,315.6z"
            />
            <path
                className="st6"
                d="M114.8,340.1c0.4,1.9,1,4,1.6,6.2l-0.1,0.7c-0.3,1.7-0.5,3.4-0.7,5c-0.5-3.1-0.7-5.4-0.7-5.4 S113.7,344.1,114.8,340.1z"
            />
            <path
                className="st7"
                d="M114.8,340.1c0.3-1,0.7-2,1.3-3.2c3-5.8,2.8-9.9,2.8-9.9l2.3,0.7l-0.4,2.2c-0.2,1.2,0.2,1.9,1,2.1 c0.7,0.1,4.3,0.4,4.3,0.4s0,0.3,0,0.6l-0.3,2.4c-0.7,0-3.1,0.2-4.9-0.4c-1.9-0.7-2.4-0.9-2.4-0.9s-1.1,5.7-2.2,12.1 C115.8,344.1,115.3,342,114.8,340.1z"
            />
            <path
                className="st1"
                d="M118.6,317c-0.7,2.2-2,6.5-2.1,9c-0.1,3.5,2.6,5.8,6.8,6.4c1.1,0.2,2,0.3,2.8,0.3l0-0.2c0,0-3.5-0.3-4.3-0.4 c-0.7-0.1-1.2-0.9-1-2.1c0.2-1.2,0.7-4.5,0.7-4.5s0.5,0.4,2.9,0.8c1.8,0.3,4.5,0.6,6.3,0.6c1-3.9,2.1-8.9,2.1-8.9s-0.6-0.8-1.7-1.7 c-1.6,0.1-3.5,0.2-5.8,0.2C121.5,316.5,120,316.8,118.6,317z"
            />
            <path
                className="st8"
                d="M88.6,218.9c0,0,0.6,9.7,7.1,21.9c6.6,12.2,19.8,35.5,20.6,43c0.8,7.5-4.4,17-3.5,25.3 c0.9,8.3,2.5,11.7,10.9,14.5c8.4,2.9,11.9,1,16.1-4.4c4.2-5.5,7.2-17.5,6.9-28.4c-0.4-10.9-12.7-53.6-12.7-53.6s20.6,1.7,31.3,6.8 c10.7,5.1,15,8.3,15,8.3s-2.9,3.5-5.9,14.2c-3,10.7-4,12.4,2,17.9c6.1,5.4,12.9,4.4,17.4,0c4.5-4.4,10.9-19.5,14.1-32.8 c3.2-13.2,1.4-18-4.4-23.5c-5.8-5.5-40.6-15.9-40.6-15.9s-18.6-7.6-20.9-9.7c-2.3-2.1-9.5-4.2-22-2.7c-12.4,1.5-25.4,6.2-28.4,10 C88.8,213.8,88.6,218.9,88.6,218.9z"
            />
            <path
                className="st9"
                d="M88.6,218.9c0,0,0.2-5.1,3.2-8.9c3-3.8,16-8.5,28.4-10c2.4-0.3,4.6-0.5,6.6-0.5c4.9,0.3,8.4,0.5,8.4,0.5l0,0 c0,0,1.6,8.1-3.5,12c-5.2,3.9-21.1,5.2-21.1,5.2s-0.7,3.5-0.7,8.8c-0.1,5.4-0.8,9.5,4.9,20.6c5.7,11.1,9.4,25.2,10.1,29.8 c0.7,4.6-0.4,10.4-3.1,24.3c-2.8,14,2.1,22.8,2.1,22.8c-8.4-2.9-10-6.2-10.9-14.5c-0.9-8.3,4.4-17.8,3.5-25.3 c-0.8-7.5-14.1-30.8-20.6-43C89.2,228.6,88.6,218.9,88.6,218.9L88.6,218.9z M123.7,323.6c2.7,0.9,4.9,1.4,6.8,1.3 C126.8,324.3,123.7,323.6,123.7,323.6L123.7,323.6z M165.3,244c10.7,5.1,15,8.3,15,8.3s-2.9,3.5-5.9,14.2c-3,10.7-4,12.4,2,17.9 c6.1,5.4,12.9,4.4,17.4,0c1.9-1.9,4.2-5.7,6.4-10.6c-2.5,1.9-4.9,3.5-6.8,4c-5.6,1.5-11.3-7.3-9-15.1c2.3-7.9,11-17.1,11-17.1 s-8.2-3.3-21.8-9.8c-13.6-6.4-40.8-11.7-40.8-11.7l1.3,13.1C134,237.2,154.6,238.9,165.3,244z"
            />
            <path
                className="st1"
                d="M145.7,199.8c0.5,1.3,0.7,2.5,0.6,3.8c-0.3,4.2-13.8,9.4-23.7,9.7c-9.9,0.3-16.3,0.2-26.7,4 c-10.4,3.8-15.2,11.6-15.2,11.6s-1.3,0.5-1.8-4c2.2-3,7.4-8.8,16.8-11.3c13.1-3.5,27.3-3.7,35-5.1c7.7-1.4,10.4-3.2,12.9-6.1 C144.6,201.5,145.2,200.6,145.7,199.8z"
            />
            <path
                className="st8"
                d="M109.8,267.3c0.7,1.3,1.3,2.7,1.9,3.9c0.6-1,1.4-2.1,2.4-3.5c3.9-5.4,6.3-6.9,6.3-6.9l-1-4.7 c0,0-0.5,0.6-4.9,5C112.5,263.1,110.9,265.5,109.8,267.3L109.8,267.3z M101,250.5c2-2,4.7-5.7,5.7-11.2c1.7-9.1,1-12.1,0.2-16.8 c-0.9-5.3-2.5-6.1-2.5-6.1l-3.4,1.3c0,0,3,6.3,2.3,15c-0.4,5.7-3,10.8-4.7,13.5C99.4,247.5,100.2,249,101,250.5z"
            />
            <path
                className="st3"
                d="M145.9,160.7c0,0,3.4,13.1,8.2,21.6c4.8,8.5,10.5,14.2,12.2,11c1.7-3.1,2.3-8.8-0.9-17.7 c-3.1-8.9-9.6-19.9-9.6-19.9s-2.7-1.5-5.9,0.2C146.6,157.6,145.9,160.7,145.9,160.7z"
            />
            <path
                className="st10"
                d="M145.9,160.6c0,0,3.4,13.1,8.2,21.6c4.8,8.5,10.5,14.2,12.2,11c0.6-1.1,1.1-2.5,1.3-4.2 c-1.2-4.1-4.2-8.8-7-12.2c-4.2-5-8.1-10.7-8.1-10.7s0.2,0,3.3-0.9c1.2-0.4,2.7-1.3,4-2.3c-1.7-3.4-3.3-6.1-3.8-6.9 c-2.5,0.9-5,1.8-9.2,2.7C146.1,159.8,145.9,160.6,145.9,160.6z"
            />
            <path
                className="st11"
                d="M138.9,118.2c0,0,6.5,4.5,10.4,13.3c4,8.9,8.9,24.5,8.9,24.5s-1.7,5.8-8.5,6.7c-6.8,0.8-9.9-0.9-9.9-0.9 s-1.7-8.9-4.1-24.2c-2.4-15.3-3.3-19-1.2-19.9C136.6,117,138.9,118.2,138.9,118.2z"
            />
            <path
                className="st12"
                d="M153.6,161.6c-1,0.6-2.3,1-3.8,1.2c-6.8,0.8-9.9-0.9-9.9-0.9s-1.7-8.9-4.1-24.2c-0.9-5.6-1.6-9.6-2-12.5 l6.1-0.4c0,0,3.9,18.6,7.3,26.6C149.4,156.9,151.5,159.9,153.6,161.6z"
            />
            <path
                className="st1"
                d="M154.7,145.6c0.6,1.6,1.1,3.2,1.6,4.6c-1.6,1.8-4.4,4.1-8.7,5c-3.8,0.8-6.7,1-8.7,1c-0.2-1.4-0.5-3-0.8-4.8 c1.9,0.6,5.1,1,9.3-0.7C150.9,149.2,153.3,147.1,154.7,145.6z"
            />
            <path
                className="st13"
                d="M148.7,154.9c-0.4,0.1-0.8,0.2-1.2,0.3c-3.8,0.8-6.7,1-8.7,1c-0.2-1.4-0.5-3-0.8-4.8c1.8,0.5,4.9,1,8.9-0.6 c0.1,0.2,0.1,0.4,0.2,0.5C147.7,152.7,148.2,153.8,148.7,154.9z"
            />
            <path
                className="st11"
                d="M61.9,126.7c4-4.2,13.5-12.1,30.1-16.6c23.4-6.3,43.4,3.2,45.6,6.1c2.2,2.9,6.8,10.6,5.1,25.6 c-1.8,15-1.1,29.3-0.2,42.3c0.6,8.9,2.3,12.8,3.3,15.7c-0.5,0.8-1.2,1.7-2,2.6c-2.6,2.9-5.2,4.7-12.9,6.1c-7.7,1.4-21.9,1.6-35,5.1 c-9.4,2.5-14.7,8.3-16.8,11.3c-0.3-2.3-0.4-5.9,0-11.4c1-16.6-0.5-31-0.5-31l-0.2,0c0.2-0.3,0.3-0.4,0.3-0.4s0.7-3.9,0-13.3 c-0.6-9.3-2.4-17.9-7.7-30.2C67.7,131,64.2,127.9,61.9,126.7z"
            />
            <path
                className="st12"
                d="M61.9,126.7c4-4.2,13.5-12.1,30.1-16.6c3.6-1,7.1-1.6,10.4-1.9l1,4c0,0-30.2,6.2-37.8,17.5 C64.3,128.2,63,127.3,61.9,126.7z"
            />
            <path
                className="st12"
                d="M141.8,173.5l0,0.1l0,0l0,0.2l0,0.1l0,0.1l0,0.1l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.2l0,0.1l0,0.1l0,0.2 l0,0l0,0.1l0,0.1l0,0.1l0,0.2l0,0l0,0.1l0,0.1l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.2l0,0.1l0,0.1 l0,0.2l0,0l0,0.1l0,0.1l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.2l0,0.1l0,0.1l0,0.2l0,0l0,0.1l0,0.1 c0.1,1.5,0.2,3,0.3,4.5c0.2,2.7,0.5,5,0.8,6.9c-3.3-2.3-8.5-5.7-8.5-5.7s1.3,4.6-4.3,7.6c-5.6,3-15.4,0.9-15.4,0.9 s-9.8,4.9-14.2,12.2c-1.1,1.9-1.9,4.3-2.5,6.9c-0.9,0.2-1.7,0.4-2.6,0.6c-9.4,2.5-14.7,8.3-16.8,11.3c-0.3-2.3-0.4-5.9,0-11.4 c0.1-2,0.2-4,0.3-5.9l0-0.2l0-0.2l0-0.4l0-0.2l0-0.2l0-0.4l0-0.4l0,0l0-0.4l0-0.1l0-0.3l0-0.1l0-0.7l0-0.3l0,0l0-0.4l0-0.3l0-0.1 l0-0.4l0-0.2l0-0.2l0-0.4l0-0.2l0-0.2l0-0.4l0-0.4l0-0.4l0-0.1l0-0.3l0-0.4l0,0l0-0.4l0-0.3l0,0l0-0.4l0-0.3l0-0.1l0-0.3l0-0.3 l0-0.3l0-0.3l0-0.3l0-0.1l0-0.2l0-0.3l0-0.3l0-0.3l0,0l0-0.3c0-1-0.1-1.9-0.1-2.8l0-0.3l0,0l0-0.2l0-0.8l0,0l0-0.3l0-0.2l0-0.1 l0-0.3l0-0.1l0-0.1l0-0.3l0-0.3l0-0.2l0-0.1l0-0.2l0-0.2l0,0l0-0.2l0-0.2l0,0l0-0.2l0-0.2l0,0l0-0.2l0-0.2l0-0.1 c-0.2-2.9-0.4-4.6-0.4-4.6l-0.2,0c0.2-0.3,0.3-0.4,0.3-0.4s0.7-3.9,0-13.3c-0.6-9.3-2.4-17.9-7.7-30.2c-0.2-0.6-0.5-1.1-0.7-1.7 c0,0,10,11.4,12.6,25.2c1.6,8.1,2.8,15.3,4.5,20.5c4.1-2.3,8.3-4.8,8.6-5.7c0.6-1.8-4.5-12.8-5.5-22.3c-1-9.6,4.5-16.5,11.7-17.6 c7.2-1.1,10.7-4.8,16.3-5.5c5.6-0.7,11.7,5.5,12.6,10.6c0.9,5.1,1.5,9.7,1.5,9.7s4.1,1.8,6.5,6.3l2.4,4.5 C141.6,166.2,141.7,169.9,141.8,173.5z"
            />
            <path
                className="st14"
                d="M109.2,146.4c-0.8,1.1-1.2,2.4-1,3.8c1.3,8,5.9,36.4,5.9,36.4s4.9,0.5,10-1.1c1.7-0.5,3-0.9,4-1.2 c1.6-0.4,2.7-1.9,2.5-3.5c-1.1-8.9-4.4-35.3-4.4-35.3s-0.7-3.2-2-3.4c-1.1-0.1-9.1,1.9-12.5,2.7C110.8,145,109.9,145.6,109.2,146.4 C109.3,146.3,109.3,146.4,109.2,146.4z"
            />
            <path
                className="st15"
                d="M116.2,186.5c1.9,0,4.9-0.2,8-1.2c1.7-0.5,3-0.9,4-1.2c1.6-0.4,2.7-1.9,2.5-3.5c-1.1-8.9-4.4-35.3-4.4-35.3 s-0.3-1.2-0.7-2.1c-2.5,0.4-8.7,1.9-11.6,2.7c-1,0.2-1.8,0.8-2.5,1.6c0,0,0,0,0,0c-0.8,1.1-1.2,2.4-1,3.8L116.2,186.5z"
            />
            <path
                className="st6"
                d="M116.2,186.6c1.9,0,4.9-0.3,8-1.2c1.7-0.5,3-0.9,4-1.1c1.6-0.4,2.7-1.8,2.5-3.4c-0.7-5.7-2.4-18.9-3.4-27 l-3-1.8c0,0-1.8,0.2-1.5,2.2c0.3,2,3.3,2.9,3.3,2.9s-1.1-0.2-2,0.9c-0.8,1.1-2.9,5.9-1.9,7.6c1,1.6,5-1.3,5-1.3s-2.9,5.1-2.7,8.3 c0.2,3.3,2.5-0.7,2.5-0.7s-0.4,1-0.7,3.3c-0.3,2.3,2.6,2.1,2.6,2.1s0.7,2.1,0.6,4.1c-0.1,2-2.6,2-6.3,3.1c-3.6,1.2-4.6-0.8-4.1-2.7 c0.5-1.8,2.7-1.5,2.7-1.5s-1.9-3.3-2.3-3.5c-0.4-0.1-1.1-3.7-0.9-5c0.2-1.3,2.6-2.4,2.4-2.7c-0.2-0.4-2.9-2.6-3.8-3.7 c-0.9-1.1,2.8-4.1,2.8-4.1s-1.3-2.8-4.4-2.6c-1.8,0.1-2.9,1.2-3.6,2.1L116.2,186.6z"
            />
            <path
                className="st1"
                d="M83.7,110.5c0,0-3.8,7.8,4.5,13c8.3,5.2,15.2,2.4,20.5-0.1c5.3-2.4,5.2-6.3,4.6-10.1c-0.6-3.8-6.4-8-6.4-8 l-17.1,0.4L83.7,110.5z"
            />
            <path
                className="st3"
                d="M85.3,97.9c0,0,2.9,6.3,2.1,12.2c-0.8,5.9,1.4,11.9,9.2,12.2c7.8,0.2,12.8-1.5,12.8-8.8c0-7.4-0.3-12.6,0-16.1 c0.3-3.5-3.8-8.1-11.7-7.5C89.9,90.6,85.3,97.9,85.3,97.9z"
            />
            <path
                className="st14"
                d="M102.6,122c4.3-0.8,6.8-3.2,6.8-8.5c0-4.3-0.1-7.9-0.1-10.8l-0.7,2c0,0,0,8.4-0.2,10.1 c-0.2,1.7-1.2,4-3.2,5.5C104.2,121,103.3,121.6,102.6,122z"
            />
            <path
                className="st7"
                d="M85.3,97.9c0,0,2.9,6.3,2.1,12.2c-0.6,4,0.3,8.1,3.3,10.3c0-0.2-0.1-0.5-0.1-0.7c-0.4-4-0.5-10.4-0.5-10.4 l2.6,1.6c0,0,4,2.8,8.2,2.2c4.2-0.6,6-5.2,6-6.5l-1.2-15.1c-1.9-1.2-4.6-1.9-8-1.6C89.9,90.6,85.3,97.9,85.3,97.9z"
            />
            <path
                className="st8"
                d="M75.2,63c0,0-2,3.8-1.7,9.9c0.3,6.1,4.4,11.2,6.4,10.2c2.1-1,1.6-10.4,1.4-13.8c-0.2-3.4,7.8-8.3,15.2-9.1 c7.3-0.8,13,0.8,13,0.8S113,74.5,114,76c1,1.6,3.8-0.6,3.4-6.7c-0.3-6.1-4.1-12.6-7.4-15.6c-3.3-3-13.3-3.5-21.1-0.6 C81.1,56,75.2,63,75.2,63z"
            />
            <path
                className="st3"
                d="M79.2,81.6c0,0-5.1-1.4-4.8,3.8c0.3,5.2,4.5,9.5,7,9.1c2.4-0.4,2-1.1,2-1.1s1.7,7.1,8.8,12.1 c7.1,5,13.6,5.9,17.7-3.2c4.2-9,4.8-14.8,4.8-14.8s1.9,3.8,3.1-0.3c1.1-4,2.3-10.9-0.4-10.5c-2.7,0.3-3,2.5-3,2.5s1-5.2-0.8-12.2 c-1.9-7.1-5.3-11.5-14.8-9.8c-9.6,1.7-17.8,4.3-19.4,9.6C77.5,72.1,79.2,81.6,79.2,81.6z"
            />
            <path
                className="st10"
                d="M93.9,96.8c0,0,2,4.5,5.3,4.2c3.2-0.3,5.3-3.6,5.3-3.6L93.9,96.8z"
            />
            <path
                className="st7"
                d="M81.4,94.4c1.9-0.3,1.9-0.9,1.9-1.1c0,0,0.3-1.7-0.4-2.2c-0.7-0.5-2.4,2.1-4.6-1c-2.1-3-2.3-5.9-1.1-5.9 c1.3,0,1.8,1.6,2.7,2c0.9,0.4,1.1-1.6,0.4-3.1c-0.8-1.6-1.3-1.8-1.3-1.8c-0.2-1.3-1.5-9.7,0-14.6c1.7-5.3,9.9-8,19.4-9.6 c9.6-1.7,13,2.7,14.8,9.8c0.2,0.7,0.3,1.3,0.5,2c0,0-2.6,3.9-8.2,4.2c-5.6,0.3-13.2-2.8-16.9-3.7c-3.8-0.9-6.4,1.5-6.3,3.3 c0,1.8,0.8,15,3.6,21.4c2.8,6.3,10.1,11.3,13.2,12.7c3.1,1.4,4.9,0.7,7,0.5c-3.9,3.2-8.8,1.7-14.1-2c-6.7-4.7-8.7-11.4-8.8-12 C83.4,93.4,82.5,93.8,81.4,94.4z"
            />
            <path
                className="st7"
                d="M114.3,79.1c0,0,0.2,1.7,0.8,1.3c0.6-0.3,1.1-2.1,1.7-1.9c0.7,0.2,1.3,1.3,0.7,4c-0.5,2.6-0.5,5-1.3,4.5 c-0.7-0.5-1.2-0.9-1.5-0.7c-0.3,0.2-0.8,0.4-0.8,0.2c0.1-0.2,0-3.4,0-4.6C114.1,80.7,114.3,79.1,114.3,79.1z"
            />
            <path
                className="st8"
                d="M86.3,79.6c0,0,0.6-1.9,3.3-1.9c2.6,0,3.2,0.4,3.2,0.4L86.3,79.6z"
            />
            <path
                className="st8"
                d="M104.3,76.6c0,0,0.6-1.6,3-1.1c2.4,0.5,2.8,1.2,2.8,1.2L104.3,76.6z"
            />
            <path
                className="st8"
                d="M102.3,86.5c0,0,0.8,2.3,1.5,4c0.6,1.3,1.3,2.3,0.7,2.8l0,0c0,0-0.1,0.3-1.1,0.9c-0.9,0.5-2.2,1.1-3,0.7 c-0.9-0.4-2.3-1.6-2.4-1.7c-0.1-0.1,0.3-0.6,0.3-0.6s1.2,0.8,2.6,1.1c0.9,0.2,2.1-0.3,2.7-0.6L102.3,86.5z"
            />
            <path
                className="st14"
                d="M93.9,96.8c0,0,2.1,3,5.3,2.6c3.2-0.4,5.2-2,5.2-2L93.9,96.8z"
            />
            <path
                className="st8"
                d="M92.1,83c0.6-0.1,1.3,0.7,1.5,1.9c0.3,1.2,0.1,2.2-0.5,2.4c-0.6,0.1-1.3-0.7-1.5-1.9 C91.3,84.2,91.6,83.1,92.1,83z"
            />
            <path
                className="st8"
                d="M105.5,81.1c0.5-0.1,1.1,0.6,1.3,1.7c0.3,1.1,0.1,2-0.4,2.1c-0.5,0.1-1.1-0.6-1.3-1.7 C104.8,82.1,105,81.2,105.5,81.1z"
            />
            <path
                className="st9"
                d="M97.8,45.7c0,0,0.7-5.7-7.5-2.6c-8.2,3.2-16.4,8.6-18.4,16.1c-1.9,7.5,2.2,11.3,5.9,12.2 c3.7,0.9,5-5.6,11.1-4.8c6.1,0.8,12.7,4.3,18.7,2.7c6-1.6,8.4-1.1,11.4-7.8c2.9-6.8,3.7-10.6,0.8-11c-2.9-0.4-5.8,2.6-5.8,2.6 s2-7.2-4-10.2C104.1,39.8,97.8,45.7,97.8,45.7z"
            />
            <path
                className="st8"
                d="M75,65.7l7.1-13.3c0,0-0.6,0.2-5.1,4.2C72.5,60.6,75,65.7,75,65.7L75,65.7z M82.4,60.2 c3.6-2.1,7.9-1.9,7.9-1.9l-10.9,7.7C79.4,65.9,78.7,62.3,82.4,60.2z"
            />
            <path
                className="st3"
                d="M59.7,180.6c0,0,0.8,9.3,6.9,21.6c6.1,12.3,11.2,13.7,20.8,7.5c9.7-6.1,23.6-19.3,23.6-19.3s3.4-0.3,4.5-5.7 c1.2-5.4,3-7.3,3-7.3s3.4,5.4,4,2.8c0.5-2.6-0.4-4.8-2.6-6.3c-2.2-1.4-4.9,0.5-4.9,0.5s1.3-4.7,3.6-4.8c2.3-0.1,4.5,1.8,4.6,0.2 c0-1.6-3-4.4-5.1-3.8c-2.1,0.6-4.4,2.6-4.4,2.6s0.9-4.4,3-5.7c2.1-1.3,3.6-0.9,3.6-0.9s1.3-1.9-1-2.9c-2.4-1.1-4.1-0.7-5.9,1.3 c-1.8,2-4,5-4,5s-2.7-0.8-0.9-5.3c1.8-4.4,2.9-6,2.2-7.1c-0.7-1.1-2.3,0.1-4.4,3.3c-2.2,3.2-3.6,5.1-3.5,8 c0.1,2.9,1.8,10.1,1.8,10.1l-1.3,1.8c0,0-11.7,8.8-15.8,11c-4.1,2.2-6.6,2.5-6.6,2.5l-2.3-7.3L59.7,180.6z"
            />
            <path
                className="st7"
                d="M59.7,180.6c0,0,0.8,9.3,6.9,21.6c6.1,12.3,11.2,13.7,20.8,7.5c8.7-5.5,20.8-16.7,23.2-18.9 c0.5-2.1,0.6-3.8,1.4-6.2c1.5-4.2,4.2-7.1,5.4-8c1.2-0.9,3.2-2.1,1.7-3c-0.5-0.1-0.9-0.2-1.4-0.1c-1.6,0.3-3.6,1.8-3.6,1.8 s-0.4,0.6-0.4-1.6c0-2.2,3.8-4.7,4.6-5.2c0.8-0.5,0.8-2-1.1-1.5c-1.9,0.5-4.1,3.7-5.7,3.6c-1.6-0.1,0.8-2.6,1.9-5.2 c1-2.6,2.2-4.6,0.5-4.5c-1.6,0.1-5.2,5.9-5.9,6.4c-0.7,0.4-1.8-3-1.8-3s-0.8,0.2-2.2-0.9c-0.7-0.5-0.4-1.9-0.3-3.1 c-0.7,1.3-1,2.5-0.9,4c0.1,2.9,1.8,10.1,1.8,10.1l-0.8,1.1l4.3,6.4c0,0-2.1,2.6-7.6,7.1c-5.5,4.5-21.1,16-25.6,15.8 c-4.4-0.2-7.5-15.2-7.5-15.2s0.5-0.1,5.9-1.3c3.2-0.7,5.2-1.8,6.4-2.7l-1-3.3L59.7,180.6z"
            />
            <path
                className="st3"
                d="M123.6,152c0,0,7.9,2.4,10.5,4.6c2.7,2.2,7,12.1,7,12.1s9.8,3.9,13,6.5c3.2,2.5,5.8,3.3,9,7 c3.2,3.7,6.5,8.3,2.3,12c-4.1,3.7-8.9,0-14.1-4.2c-5.2-4.2-10.8-10.1-10.8-10.1s-1.2,1.9-3.8-0.8c-2.6-2.7-4.2-3.8-4.2-3.8 s-0.8,1.2-3.2,2.6c-2.4,1.4-2.1-0.7-0.3-3.4c1.8-2.6,4.5-2.8,4.5-2.8s-1.7-2.1-3.2-1.7c-1.5,0.4-4,4.8-4.5,3.9 c-0.5-0.9-1.2-1.4,0.8-4.4c1.9-2.9,3-3.6,4.1-3.3c1.1,0.2,2.3,0.9,2.3,0.9s-3.5-3.7-4.9-3.7c-1.4,0-2.6,3.1-4.2,3.5 c-1.6,0.3-1.9-1.2-1.9-1.2s2.3-4.3,4.1-5.4c1.9-1.1,5.6,0.1,5.6,0.1s-0.2-1.8-1.4-2.5c-1.2-0.7-5.5-1.9-6.7-2.9 c-1.2-1-1.1-2.2-1.1-2.2L123.6,152z"
            />
            <path
                className="st7"
                d="M123.6,152l-1.1,0.8c0,0,0,1.2,1.1,2.2c1.2,1,5.5,2.2,6.7,2.9c1.2,0.7,1.4,2.5,1.4,2.5s-3.7-1.2-5.6-0.1 c-1.9,1.1-4.1,5.4-4.1,5.4s0.3,1.6,1.9,1.2c1.6-0.3,2.8-3.4,4.2-3.5c1.3,0,4.4,3.2,4.8,3.6c0.4,0.2,0.8,0.3,0.9,0.3 c0.4,0-0.4-1.7-2.3-3.3c-1.9-1.6-3.8-3.4-2.6-3.5c1.1-0.2,4.8,0.9,4.8,0.9s-0.1-0.8-0.8-2.2c-0.7-1.4-2.1-2.2-3.8-3.7 c-0.7-0.6-0.8-1.3-0.7-1.8C125.8,152.7,123.6,152,123.6,152L123.6,152z M151.3,189.9c-5.2-4.2-10.8-10.1-10.8-10.1 s-1.2,1.9-3.8-0.8c-2.6-2.7-4.2-3.8-4.2-3.8s-0.8,1.2-3.2,2.6c-2.4,1.4-2.1-0.7-0.3-3.4c1.8-2.6,4.5-2.8,4.5-2.8s-1.7-2.1-3.2-1.7 c-1.5,0.4-4,4.8-4.5,3.9c-0.5-0.9-1.2-1.4,0.7-4.4c1.9-2.9,3-3.6,4.1-3.3c0.7,0.2,1.5,0.5,2,0.7c-0.9-0.3-2-0.7-2.5-0.2 c-0.8,0.8-0.7,1.7,0.7,2.3c1.3,0.6,3.4,1.4,3.5,2c0.2,0.7-1.4,0.7-2.6,1.5c-1.1,0.8-1.9,2.4-0.5,2.5c1.3,0.1,3.2-2.2,3.9-1.7 c0.8,0.5,3.7,2.6,4.4,3c0.8,0.4,4-1.5,4-1.5s12.5,9.7,16.3,13.8c1.8,2,2.8,4.5,3.3,6.6C159.5,196.5,155.6,193.4,151.3,189.9z"
            />
            <path
                className="st11"
                d="M61.9,126.7c2.3,1.2,5.8,4.3,9,11.8c5.3,12.3,7,20.9,7.7,30.2c0.6,9.3,0,13.3,0,13.3s-0.1,0.2-0.3,0.4 c-1.1,0.1-6,3.6-13.6,2.4c-8.5-1.4-11.3-4.1-11.3-4.1s-2.6-7.2-1.8-22.8c0.8-15.6,8-28.3,8-28.3S60.3,128.4,61.9,126.7z"
            />
            <path
                className="st12"
                d="M61.9,126.7c1,0.6,2.3,1.5,3.7,3.1c-0.4,0.6-0.8,1.2-1,1.9c-5.2,12.5-7,41.5-6,45.2c0.7,2.4,3.3,5.8,5,7.8 c-7.7-1.4-10.3-4-10.3-4s-2.6-7.2-1.8-22.8c0.8-15.6,8-28.3,8-28.3S60.3,128.4,61.9,126.7z"
            />
            <path
                className="st1"
                d="M51.4,162.6c2.5,1.6,7.6,4.1,13.1,4c5.7-0.1,11.2-1,13.8-1.5c0.1,1.2,0.2,2.3,0.3,3.5c0.1,0.9,0.1,1.7,0.1,2.5 c-3.2,0.9-10.5,2.5-17.4,0.8c-4.7-1.2-7.9-2.2-9.9-2.9C51.4,167.2,51.4,165,51.4,162.6z"
            />
            <path
                className="st14"
                d="M107.1,106.6c0.3-1.1,0.9-2.9,2.2-5.3c2-3.7,3.9-12.6,3.8-18.7c-0.1-6.1,0.9-13.7,0.9-13.7 c1.2,6,0.4,10.3,0.4,10.3l-0.2,7.3l0.6,0.9c0,0-0.6,5.7-4.8,14.8C109.1,104.1,108.1,105.5,107.1,106.6L107.1,106.6z M114.3,79.2 c0,0,0.1-0.2,0.1-0.5C114.3,79,114.3,79.2,114.3,79.2z"
            />
            <path
                className="st13"
                d="M97.7,216.7c-0.6,0.2-1.1,0.4-1.7,0.6c-10.4,3.8-15.2,11.6-15.2,11.6s-1.3,0.5-1.8-4c2.2-3,7.4-8.8,16.8-11.3 c0.9-0.2,1.7-0.4,2.6-0.6C98.1,214.2,97.9,215.4,97.7,216.7z"
            />
            <path
                className="st13"
                d="M51.4,162.6c1.6,1,4.2,2.4,7.2,3.2c-0.1,2-0.2,3.8-0.2,5.4c-3.1-0.8-5.3-1.6-6.8-2.2 C51.4,167.2,51.4,165.1,51.4,162.6L51.4,162.6z"
            />
            <path
                className="st16"
                d="M120,146.5c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.2-0.8,1.3c-0.6,0.1-1.2-0.2-1.3-0.8 C119.1,147.2,119.4,146.6,120,146.5L120,146.5z M123.9,149.1c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.2-0.8,1.3 c-0.6,0.1-1.2-0.2-1.3-0.8C122.9,149.9,123.3,149.3,123.9,149.1L123.9,149.1z M124.5,146.7c0.1,0.6-0.2,1.2-0.8,1.3 c-0.6,0.1-1.2-0.2-1.3-0.8c-0.1-0.6,0.2-1.2,0.8-1.3C123.7,145.7,124.3,146.1,124.5,146.7z"
            />
            <path
                className="st1"
                d="M78.2,156.3c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7c0,0,1,5.3,1,5.3c0.1,0.3,0.4,0.5,0.7,0.4 c0.3-0.1,0.5-0.4,0.4-0.7C79.3,161.5,78.2,156.3,78.2,156.3L78.2,156.3z M63.3,126.7c-0.3-0.1-0.6,0-0.8,0.2 c-0.1,0.3,0,0.6,0.2,0.8c1.4,0.9,2,1.5,3.1,2.6c0.2,0.2,0.6,0.2,0.8,0c0.2-0.2,0.2-0.6,0-0.8C65.5,128.3,64.8,127.7,63.3,126.7 L63.3,126.7z M67.9,131c-0.2-0.2-0.6-0.3-0.8-0.1c-0.2,0.2-0.3,0.6-0.1,0.8c0.8,1,1.4,2,2,3c0.2,0.3,0.5,0.4,0.8,0.2 c0.3-0.2,0.4-0.5,0.2-0.8C69.3,133,68.7,132,67.9,131L67.9,131z M70.9,135.8c-0.1-0.3-0.5-0.4-0.8-0.2c-0.3,0.1-0.4,0.5-0.2,0.8 c0.7,1.4,1.3,2.8,1.9,4.3c0.1,0.3,0.5,0.4,0.7,0.3c0.3-0.1,0.4-0.5,0.3-0.7C72.3,138.7,71.7,137.3,70.9,135.8L70.9,135.8z  M74,142.8c-0.1-0.3-0.4-0.5-0.7-0.4c-0.3,0.1-0.5,0.4-0.4,0.7l1.3,3.8c0.1,0.3,0.4,0.5,0.7,0.4c0.3-0.1,0.5-0.4,0.4-0.7 C75.2,146.7,74,142.8,74,142.8L74,142.8z M75.7,149.1c-0.3,0.1-0.5,0.4-0.4,0.7l1.3,4.4c0.1,0.3,0.4,0.5,0.7,0.4 c0.3-0.1,0.5-0.4,0.4-0.7l-1.3-4.4C76.3,149.2,76,149,75.7,149.1z"
            />
            <path
                className="st1"
                d="M144,146c0-0.3-0.2-0.5-0.5-0.5c-0.3,0.1-0.6,0.4-0.6,0.7c0,0-0.3,5.5-0.3,5.5c0,0.3,0.2,0.5,0.5,0.5 s0.6-0.4,0.6-0.7C143.7,151.5,144,146,144,146L144,146z M136.7,115.1c-0.2-0.2-0.6-0.1-0.8,0.2c-0.2,0.3-0.2,0.6,0,0.8 c1.1,0.9,1.5,1.6,2.3,2.8c0.2,0.2,0.5,0.2,0.8,0c0.3-0.2,0.4-0.6,0.2-0.8C138.4,116.7,137.9,116.1,136.7,115.1L136.7,115.1z  M140.1,119.5c-0.1-0.2-0.5-0.3-0.7-0.1c-0.3,0.2-0.4,0.6-0.2,0.8c0.5,1,0.8,2,1.2,3.2c0.1,0.3,0.4,0.4,0.7,0.2 c0.3-0.2,0.5-0.5,0.4-0.8C141,121.7,140.6,120.6,140.1,119.5L140.1,119.5z M141.8,124.6c-0.1-0.3-0.4-0.4-0.7-0.3 c-0.3,0.1-0.5,0.5-0.4,0.8c0.3,1.5,0.6,2.9,0.8,4.5c0,0.3,0.3,0.4,0.6,0.3c0.3-0.1,0.5-0.5,0.5-0.8 C142.4,127.6,142.2,126.2,141.8,124.6L141.8,124.6z M143.1,131.9c0-0.3-0.3-0.5-0.6-0.4c-0.3,0.1-0.5,0.4-0.5,0.7l0.3,4 c0,0.3,0.3,0.5,0.6,0.4c0.3-0.1,0.5-0.4,0.5-0.7C143.4,136,143.1,131.9,143.1,131.9L143.1,131.9z M143.2,138.5 c-0.3,0.1-0.5,0.4-0.5,0.7l0.2,4.6c0,0.3,0.3,0.5,0.6,0.4c0.3-0.1,0.5-0.4,0.5-0.7l-0.2-4.6C143.8,138.6,143.5,138.4,143.2,138.5z"
            />
            <path
                className="st9"
                d="M201.8,270.3c1.9-8.5,4.9-20.9,4.9-20.9s-2.2,2.3-4.7,0.9c-2.5-1.5-2.6-5.1-2.6-5.1s3.5,2.4,5.4,1.3 c1.9-1.1,3.1-3,2-7.8c-1-4.8-4.9-11-21.8-15.7c-16.9-4.7-39-18.1-39.1-18.2c6.1,3,17,7.5,17,7.5s34.8,10.4,40.6,15.9 c5.8,5.5,7.6,10.3,4.4,23.5C206.4,257.9,204.2,264.6,201.8,270.3L201.8,270.3z M140.3,313.8c3.9-7.7,3.2-21.1,3.4-21.6 c0.2-0.5-0.2,1.2-2.1,3.5c-1.8,2.3-5.6,3.3-8.9,0.5c-3.4-2.8-3.9-11.1-3.9-11.1s0.6,1.8,3,4.8c2.4,3.1,5.4,4,8.5,1.2 c3-2.7,4.1-3.6,3.5-9c-0.6-5.5-10-36.9-11.7-43.1c-1.7-6.2,0.8-15,0.8-15l1.3,13.1c0,0,12.4,42.7,12.7,53.6 c0.4,10.9-2.6,22.9-6.9,28.4c-1.9,2.5-3.7,4.2-5.8,5.1C135.5,322.2,137.9,318.6,140.3,313.8z"
            />
            <g>
                <path
                className="st17"
                d="M154.9,91.8l25.4,12.4l10.6-26.3l-5.8-2.8l1.8-3.6c1.4-2.8,0.2-6.3-2.6-7.6c-2.8-1.4-6.3-0.2-7.6,2.6 l-1.8,3.6l-5.8-2.8L154.9,91.8z M184.2,74.6l-8.5-4.1l1.8-3.6c1.1-2.3,4-3.3,6.3-2.2c2.3,1.1,3.3,4,2.2,6.3L184.2,74.6z"
                />
                <g>
                <path
                    className="st18"
                    d="M182,77.3c-0.2,0.5,0,1.1,0.5,1.4c0.5,0.2,1.1,0,1.4-0.5c0.2-0.5,0-1.1-0.5-1.4 C182.8,76.6,182.2,76.8,182,77.3z"
                />
                </g>
                <g>
                <path
                    className="st18"
                    d="M173,72.9c-0.2,0.5,0,1.1,0.5,1.4c0.5,0.2,1.1,0,1.4-0.5c0.2-0.5,0-1.1-0.5-1.4 C173.8,72.2,173.2,72.4,173,72.9z"
                />
                </g>
            </g>
            <g>
                <g>
                <g>
                    <path
                    className="st19"
                    d="M51.4,240.7L26,225.8c-0.9-0.5-1.2-1.7-0.7-2.5l8.6-14.7c0.5-0.9,1.7-1.2,2.5-0.7l25.4,14.9 c0.9,0.5,1.2,1.7,0.7,2.5L54,240.1C53.4,240.9,52.3,241.2,51.4,240.7z"
                    />
                </g>
                <g>
                    <g>
                    <path
                        className="st20"
                        d="M31.1,220.8c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3s-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3s-0.2,0.1-0.3,0.1C31.3,220.9,31.2,220.8,31.1,220.8z M31.3,220.5c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C31.2,220.5,31.2,220.5,31.3,220.5z"
                    />
                    <path
                        className="st20"
                        d="M32.3,221.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 s0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4s-0.2,0.2-0.3,0.3 s-0.2,0.1-0.3,0.1C32.5,221.6,32.4,221.5,32.3,221.5z M32.4,221.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2 s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2 s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C32.4,221.2,32.4,221.2,32.4,221.2z"
                    />
                    <path
                        className="st20"
                        d="M33.5,222.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C33.7,222.3,33.6,222.2,33.5,222.2z M33.6,221.9c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C33.6,221.9,33.6,221.9,33.6,221.9z"
                    />
                    <path
                        className="st20"
                        d="M34.7,222.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 s0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4s-0.2,0.2-0.3,0.3 S35.1,223,35,223C34.9,223,34.8,222.9,34.7,222.9z M34.8,222.6c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2 s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2 s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C34.8,222.6,34.8,222.6,34.8,222.6z"
                    />
                    <path
                        className="st20"
                        d="M36.4,223.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3s-0.2,0.1-0.3,0.1C36.6,224,36.5,223.9,36.4,223.9z M36.5,223.6c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1s-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C36.5,223.6,36.5,223.6,36.5,223.6z"
                    />
                    <path
                        className="st20"
                        d="M37.6,224.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C37.8,224.7,37.7,224.6,37.6,224.6z M37.7,224.3c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C37.7,224.3,37.7,224.3,37.7,224.3z"
                    />
                    <path
                        className="st20"
                        d="M38.8,225.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 s0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4s-0.2,0.2-0.3,0.3 s-0.2,0.1-0.3,0.1C39,225.4,38.9,225.3,38.8,225.3z M38.9,225c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2 s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2 s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C38.9,225,38.9,225,38.9,225z"
                    />
                    <path
                        className="st20"
                        d="M40,226c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1s0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C40.2,226.1,40.1,226,40,226z M40.1,225.7c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C40.1,225.7,40.1,225.7,40.1,225.7z"
                    />
                    <path
                        className="st20"
                        d="M41.7,227c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 s0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4s-0.2,0.2-0.3,0.3 c-0.1,0.1-0.2,0.1-0.3,0.1C41.9,227.1,41.8,227,41.7,227z M41.8,226.7c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C41.8,226.7,41.8,226.7,41.8,226.7z"
                    />
                    <path
                        className="st20"
                        d="M42.9,227.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.3,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C43.1,227.8,43,227.7,42.9,227.7z M43,227.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C43,227.4,43,227.4,43,227.4z"
                    />
                    <path
                        className="st20"
                        d="M44.1,228.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C44.3,228.5,44.2,228.4,44.1,228.4z M44.2,228.1c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C44.2,228.1,44.2,228.1,44.2,228.1z"
                    />
                    <path
                        className="st20"
                        d="M45.3,229.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2s0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.4S46,229,45.9,229 c-0.1,0.1-0.2,0.1-0.3,0.1C45.5,229.2,45.4,229.1,45.3,229.1z M45.4,228.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C45.3,228.8,45.4,228.8,45.4,228.8z"
                    />
                    <path
                        className="st20"
                        d="M47,230.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1s0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4s-0.2,0.2-0.3,0.3 c-0.1,0.1-0.2,0.1-0.3,0.1C47.2,230.1,47.1,230.1,47,230.1z M47.1,229.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C47,229.8,47.1,229.8,47.1,229.8z"
                    />
                    <path
                        className="st20"
                        d="M48.2,230.8c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C48.4,230.8,48.3,230.8,48.2,230.8z M48.3,230.5c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C48.2,230.5,48.3,230.5,48.3,230.5z"
                    />
                    <path
                        className="st20"
                        d="M49.4,231.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C49.6,231.5,49.5,231.5,49.4,231.5z M49.5,231.2c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C49.4,231.2,49.5,231.2,49.5,231.2z"
                    />
                    <path
                        className="st20"
                        d="M51.1,232.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3s-0.1,0.3-0.1,0.4 s-0.2,0.2-0.3,0.3s-0.2,0.1-0.3,0.1C51.3,232.5,51.2,232.5,51.1,232.5z M51.2,232.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1 c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1 c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C51.1,232.1,51.2,232.2,51.2,232.2z"
                    />
                    <path
                        className="st20"
                        d="M52.3,233.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.4 s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C52.5,233.2,52.4,233.2,52.3,233.2z M52.4,232.9c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2s-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C52.3,232.8,52.4,232.9,52.4,232.9z"
                    />
                    <path
                        className="st20"
                        d="M53.5,233.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.2,0.1-0.4s0.2-0.2,0.3-0.3 c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3s-0.1,0.3-0.1,0.4 c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.1-0.3,0.1C53.7,233.9,53.6,233.9,53.5,233.9z M53.6,233.6c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0 c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2C53.5,233.5,53.6,233.6,53.6,233.6z"
                    />
                    </g>
                    <g>
                    <path
                        className="st20"
                        d="M30.2,222.7c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.3,0.1-0.4l0.3-0.6l0.2,0.1l-0.3,0.6 c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0c0.1,0,0.1-0.1,0.2-0.2l0.3-0.6l0.2,0.1l-0.3,0.6 c-0.1,0.2-0.2,0.2-0.3,0.3C30.5,222.8,30.4,222.8,30.2,222.7z"
                    />
                    <path
                        className="st20"
                        d="M31.2,223.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.1l0.2-0.1c0,0,0.1,0.1,0.1,0.1 c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1 c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1 c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1l-0.2,0.1c0,0-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0 c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1 c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0S31.3,223.3,31.2,223.2z"
                    />
                    <path
                        className="st20"
                        d="M32.1,223.8c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2 c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0 c0,0,0,0,0,0l-0.6-0.4l0.1-0.1l0.5,0.3l-0.1,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1s0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0.2 c-0.1,0-0.1,0-0.2,0C32.2,223.8,32.2,223.8,32.1,223.8z"
                    />
                    <path
                        className="st20"
                        d="M32.6,224.1l0.5-0.8l0.2,0.1l-0.1,0.2l0-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1l-0.1,0.2 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1l-0.2,0.4L32.6,224.1z"
                    />
                    <path
                        className="st20"
                        d="M33.7,224.7l0.6-1l0.2,0.1l0.2,1.1l-0.1-0.1l0.4-0.7l0.2,0.1l-0.6,1l-0.2-0.1l-0.2-1.1l0.1,0.1l-0.4,0.7 L33.7,224.7z"
                    />
                    <path
                        className="st20"
                        d="M35.1,225.5c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1 c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1l0.2,0.1l-0.1,0.1l-0.2-0.1c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1 c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0l0,0.1c0,0-0.1,0.1-0.2,0.1C35.3,225.6,35.2,225.6,35.1,225.5z M35.4,225.7 l0.1-0.2l0,0l0.2-0.3c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0l0-0.2c0.1,0,0.1,0,0.2,0 c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1,0,0.2-0.1,0.3l-0.3,0.5L35.4,225.7z"
                    />
                    <path
                        className="st20"
                        d="M37.3,225.7c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2l-0.3,0.5l-0.2-0.1l0.2-0.4 c0-0.1,0.1-0.1,0-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-0.2,0.4l-0.2-0.1l0.2-0.4 c0-0.1,0.1-0.1,0-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1L36,226l-0.2-0.1l0.5-0.8l0.2,0.1 l-0.1,0.2l0-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0,0.1,0,0.2l-0.1-0.1 c0.1,0,0.1-0.1,0.2-0.1C37.1,225.6,37.2,225.6,37.3,225.7z"
                    />
                    <path
                        className="st20"
                        d="M37.7,227.1c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2 c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0 c0,0,0,0,0,0l-0.6-0.4l0.1-0.1l0.5,0.3l-0.1,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0 s-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0 l0,0.2c-0.1,0-0.1,0-0.2,0C37.9,227.1,37.8,227.1,37.7,227.1z"
                    />
                    </g>
                    <g>
                    <defs>
                        <path
                        id="SVGID_1_"
                        d="M38.6,219.4l-4-2.3c-0.4-0.2-0.5-0.7-0.3-1.1l1.4-2.4c0.2-0.4,0.7-0.5,1.1-0.3l4,2.3 c0.4,0.2,0.5,0.7,0.3,1.1l-1.4,2.4C39.5,219.5,39,219.6,38.6,219.4z"
                        />
                    </defs>
                    <use
                        xlinkHref="#SVGID_1_"
                        style={{
                        overflow: "visible",
                        fill: "#F0C85D",
                        }}
                    />
                    <clipPath id="SVGID_2_">
                        <use
                        xlinkHref="#SVGID_1_"
                        style={{
                            overflow: "visible",
                        }}
                        />
                    </clipPath>
                    <g className="st21">
                        <path
                        className="st22"
                        d="M38.6,219.4l-4-2.3c-0.4-0.2-0.5-0.7-0.3-1.1l1.4-2.4c0.2-0.4,0.7-0.5,1.1-0.3l4,2.3 c0.4,0.2,0.5,0.7,0.3,1.1l-1.4,2.4C39.5,219.5,39,219.6,38.6,219.4z"
                        />
                    </g>
                    <g className="st21">
                        <g>
                        <g className="st23">
                            <rect
                            x={36.7}
                            y={213.1}
                            transform="matrix(0.8633 0.5048 -0.5048 0.8633 113.9639 10.9246)"
                            className="st18"
                            width={0.2}
                            height={5.5}
                            />
                        </g>
                        <g className="st23">
                            <rect
                            x={38.6}
                            y={214.2}
                            transform="matrix(0.8633 0.5048 -0.5048 0.8633 114.7685 10.1333)"
                            className="st18"
                            width={0.2}
                            height={5.5}
                            />
                        </g>
                        </g>
                        <g>
                        <g className="st23">
                            <rect
                            x={38.1}
                            y={211.8}
                            transform="matrix(-0.5048 0.8633 -0.8633 -0.5048 243.5793 291.5854)"
                            className="st18"
                            width={0.2}
                            height={7.7}
                            />
                        </g>
                        <g>
                            <g className="st23">
                            <rect
                                x={37.3}
                                y={213.2}
                                transform="matrix(-0.5048 0.8633 -0.8633 -0.5048 243.5565 294.3404)"
                                className="st18"
                                width={0.2}
                                height={7.7}
                            />
                            </g>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                </g>
                <g>
                <g>
                    <rect
                    x={48.9}
                    y={233.1}
                    transform="matrix(0.5048 -0.8633 0.8633 0.5048 -178.5909 159.9494)"
                    className="st19"
                    width={2.3}
                    height={5}
                    />
                </g>
                </g>
            </g>
            <g>
                <rect
                x={208.4}
                y={282.3}
                transform="matrix(0.9664 0.2572 -0.2572 0.9664 84.9127 -48.1737)"
                className="st24"
                width={36.4}
                height={36.4}
                />
                <g>
                <rect
                    x={211.2}
                    y={282.8}
                    transform="matrix(0.9664 0.2572 -0.2572 0.9664 81.1501 -49.7213)"
                    className="st25"
                    width={38.9}
                    height={5.1}
                />
                <polygon
                    className="st26"
                    points="232.7,286.6 233.3,284.3 232.7,284.9 232.5,284.1 232,284.7 231.8,283.9 231.2,284.5 231.1,283.7  230.5,284.3 230.3,283.5 229.7,284.1 229.6,283.3 229,283.9 228.8,283.1 228.2,285.4 228,286.1 220.7,313.8 220.6,313.9  219.9,316.8 220.4,316.1 220.6,317 221.2,316.3 221.3,317.2 221.9,316.5 222.1,317.4 222.6,316.7 222.8,317.6 223.4,316.9  223.6,317.8 224.1,317.1 224.3,318 225.1,315.1 225.1,315.1 232.7,286.6  "
                />
                <rect
                    x={211.5}
                    y={287.7}
                    transform="matrix(0.9664 0.2572 -0.2572 0.9664 81.9819 -49.3792)"
                    className="st27"
                    width={36.4}
                    height={1.9}
                />
                </g>
            </g>
            <g>
                <rect
                x={187.2}
                y={176.7}
                transform="matrix(0.9518 -0.3067 0.3067 0.9518 -47.522 69.1484)"
                className="st19"
                width={18.2}
                height={18.2}
                />
                <g>
                <rect
                    x={184.2}
                    y={177.1}
                    transform="matrix(0.9518 -0.3067 0.3067 0.9518 -45.3524 68.0528)"
                    className="st28"
                    width={19.4}
                    height={2.5}
                />
                <polygon
                    className="st26"
                    points="195.1,178.3 194.7,177.2 194.6,177.6 194.3,177.3 194.3,177.7 194,177.4 193.9,177.8 193.6,177.5  193.5,177.9 193.2,177.6 193.2,178.1 192.9,177.8 192.8,178.2 192.5,177.9 192.9,179 193,179.3 197.4,193 197.4,193 197.8,194.4  197.9,194 198.2,194.3 198.3,193.9 198.6,194.2 198.6,193.8 198.9,194.1 199,193.7 199.3,194 199.4,193.6 199.7,193.9  199.7,193.4 200,193.7 199.6,192.3 199.6,192.3 195.1,178.3  "
                />
                <rect
                    x={185.3}
                    y={179.5}
                    transform="matrix(0.9518 -0.3067 0.3067 0.9518 -45.832 68.295)"
                    className="st27"
                    width={18.2}
                    height={0.9}
                />
                </g>
            </g>
            </g>
        </svg>
    </>
  )
}
