import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Autoplay, Pagination } from 'swiper/modules';
import { ApiCall } from '../Common/service';
import { AllSection } from './AllSection';

export const Home = () => {
    const [SliderData, setSliderData] = useState([])
    const [SliderLaoading, setSliderLaoading] = useState(false)

    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];

   
    const GetSliderData = async () => {
        setSliderLaoading(true)
        let data = await ApiCall(`/api/Web/GetSlider`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'Home'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )
        if (data.status === 'SUCCESS') {
            await setSliderData(data.sliderList)
            await setSliderLaoading(false)
        } else if (data.status === 'ERROR') {
            await setSliderData([])
            await setSliderLaoading(false)
        }
    };



 
    useEffect(() => {
        document.title = 'Shop Indian Handicrafts Online | Traditional Indian Crafts'
        GetSliderData();
    }, [])


    return (
        <>
            <div className='d-none'>
                {SliderLaoading ? < div className='m-0 my-3 p-0 storeTextWithImage' style={{ height: "15rem" }}>
                    <Skeleton className='w-100 h-100' />
                </div> :
                    <div>
                        <Swiper pagination={true} modules={[Pagination, Autoplay]} loop={true} grabCursor={true} data-swiper-autoplay="3000" autoplay={true} className="mySwiper">
                            {SliderData.map((slider, sliderIndex) =>
                                <SwiperSlide key={`SwiperSlide_${sliderIndex}`}>
                                    <div className="swiper_image" onClick={() => window.location.assign(`${slider.redirectUrl}`)} style={{ cursor: 'pointer' }}>
                                        <picture>
                                            <source media="(min-width:550px)" srcSet={slider.sliderImageUrl ? slider.sliderImageUrl : './images/Image_not_available.png'} /* onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} */ />
                                            <img src={slider.sliderImageUrl ? slider.sliderImageUrl : './images/Image_not_available.png'} className='w-100' alt=''/* onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} */ />
                                        </picture>
                                    </div>
                                </SwiperSlide>
                            )}

                        </Swiper>

                    </div>
                }
            </div>

            <div style={{ backgroundColor: '#f8f8f8', paddingBottom: '50px' }}>
                <div className="container" >
                    <AllSection />
                </div>
            </div>
        </>
    );
}
