import { ApiCall } from "../components/Common/service"

const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];

export const GetSingleProductDetails = async (ProductCode) => {
    let data = await ApiCall(`/api/Web/GetProductDetail`, { ProductCode: ProductCode ? ProductCode : '' })
    if (data.status === 'SUCCESS') {
       return data
    } else if (data.status === 'ERROR') {
        return []
    }
}

export const GetWishListAllProducts = async (auth) => {
    let data = await ApiCall(`/api/Web/GetWishListProducts`, {
        LoginUserCode: auth.loginUserCode ? auth.loginUserCode : authLocalStorage.userCode, Token: auth.token ? auth.token : authLocalStorage.authToken

    })
    if (data.status === 'SUCCESS') {
        return data
    } else if (data.status === 'ERROR') {
        return []
    }
}