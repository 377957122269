import { Autocomplete, TextField } from '@mui/material';
import { Children } from 'react';

export const convertToNumber = (Value) => {
    return Value.replace(/[^0-9]/g, '');
}

export const InputBox = (props) => {


    let { divclassname = "col-md-3 col-sm-6 fields",/* divclassname = "col-lg-3 col-md-4 col-sm-6 fields",*/ key = '', customclass, onFocus, onBlur, onKeyPress, pattern, mendatory, onKeyDown = null, inputClassName = '', type = 'text', inputtype, divstyle = null,
        inputStyle = null, id, label = '', value, onChange, placeholder, required, error, confirm_error, ignore_common } = props

    return (

        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={divstyle}
            key={key}
        >
            {label !== '' ? <label className="form-label" htmlFor={id}>{label}
                {required || mendatory ? <span style={{ color: "red" }}> *</span> : <></>}
            </label> : <></>}
            <input
                id={id}
                style={inputStyle}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}

                onKeyPress={onKeyPress}
                pattern={pattern}
                type={inputtype ? inputtype : type}
                className={`form-control ${inputClassName + ' ' + customclass}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="new-password"

                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
            {confirm_error && <div className="error-msg">{confirm_error}</div>}
        </div>

    )
}


export const AutoCompleteBox = (props) => {
    const { setOptions, ...restProps } = props;

    const { divclassname = "col-md-3 col-sm-6 fields", renderOption, autoHighlight=true, /*divclassname = 'col-lg-3 col-md-4 col-sm-6 fields',*/ onKeyPress, onKeyDown, style, inputstyle, maxLength, id, label = '', options, inputValue, onInputChange, placeholder, required, ignore_common } = restProps

    return (
        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={style}
        >
            {label === '' ? '' :
                <label className="form-label" htmlFor={id}>{label || ''}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>
            }
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                freeSolo
                filterOptions={(x) => x}
                filterSelectedOptions
                options={options ? options : []}
                onClose={(event, reason) => {
                    if (setOptions) {
                        setOptions([]);
                    }
                }}
                autoHighlight={autoHighlight}
                selectOnFocus={true}
                title={required ? 'Please fill this field.' : ''}
                renderOption={renderOption }
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;

                        if (value.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    //inputProps={{ ref: ref => (inputrefs.current[id] = ref) }}
                    //inputRef={ref => (inputrefs.current[id] = ref)}
                    sx={inputstyle}
                    placeholder={placeholder}
                    //ref={ref => (inputrefs.current[id] = ref)}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                  
                />}
                {...restProps}
            />
        </div>
    )
}


//export const SelectInputBox = (props) => {
//    const { divclassname = "col-md-3 col-sm-6 fields", inputclassname, onKeyDown, divstyle, inputstyle, id, label = '', value, onChange, children, required, ignore_common } = props;
//    return (
//        <div
//            className={` ${divclassname}`
//           // style={divstyle}
//        >
//            {label === '' ? '' :
//                <label className="form-label" htmlFor={id}>{label}
//                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
//                </label>
//            }
//            <select
//                id={id}
//                className={`form-select ${inputclassname}`}
//                style={inputstyle}
//                onKeyDown={onKeyDown}
//                value={value}
//                onChange={onChange}
//                title={required ? 'Please select an item in the list.' : ''}
//                autoComplete="off"
//                {...props}
//            >
//                {children}
//            </select>
//        </div>
//    )
//}




export const CustomButton = (props) => {
    const { id,  onClick, ButtonIcon, ButtonText, ButtonClass = 'btn-primary', ButtonIconClass } = props;
    return <button
        type="button"
        key={`key${id}`}
        name={id}
        id={id}
        onClick={onClick}
        className={`btn btn-rounded ${ButtonClass}`}>
        <span className={`text-white me-2 ${ButtonIconClass}`}>
            <i className={`fa ${ButtonIcon}`}></i>
        </span>{ButtonText}</button>
}
